import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '@utils/session.service';
import { AuthService, IAccessAuth } from '@utils/auth.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'form-lock-screen',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './lock-screen.component.html',
  styleUrl: './lock-screen.component.scss'
})
export class FormLockScreenComponent {
  constructor(
    private formBuilder: FormBuilder,
    private session: SessionService,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) { }

  public formLogin = this.formBuilder.group({
    password: ['', Validators.required],
  });
  public isLoading: boolean = false

  get password() { return this.formLogin.get('password') }

  ngOnInit() {
    if (!this.session.isLoggedIn()) {
      this.router.navigate(['login'])
    }
  }

  login = (): void => {
    this.isLoading = true

    let body: IAccessAuth = {
      email: String(this.session.getUser()?.email),
      password: `${this.password?.value}`
    }

    this.auth.login(body)
      .then((response) => {
        this.auth.setAuthSession(response.data)
        this.toast.success('Desbloqueado.')
        this.router.navigate(['/'])
      })
      .catch((error) => {
        this.password?.setValue('')

        error.error.data.labels_errors.forEach((element: any) => this.toast.error(element.message));

        this.isLoading = false
      })
  }
}
