<backbone currentPage="Historial">
  <div class="p-3">
    <div class="card page-section rounded-2 shadow-sm">
      <div class="card-body">
        <div class="d-flex gap-2">
          <ng-select [(ngModel)]="periodSelected" [clearable]="false" (change)="init()" class="w-100 h-50px">
            <ng-option value="current">Periodo: {{periodoActual | date: 'longDate'}}</ng-option>
            <ng-option value="1">Periodo: {{periodo1 | date: 'longDate'}}</ng-option>
            <ng-option value="2">Periodo: {{periodo2 | date: 'longDate'}}</ng-option>
            <ng-option value="3">Periodo: {{periodo3 | date: 'longDate'}}</ng-option>
            <ng-option value="4">Periodo: {{periodo4 | date: 'longDate'}}</ng-option>
            <ng-option value="5">Periodo: {{periodo5 | date: 'longDate'}}</ng-option>
            <ng-option value="6">Periodo: {{periodo6 | date: 'longDate'}}</ng-option>
            <ng-option value="7">Periodo: {{periodo7 | date: 'longDate'}}</ng-option>
            <ng-option value="8">Periodo: {{periodo8 | date: 'longDate'}}</ng-option>
          </ng-select>
          <!-- <p-button *showForPermissions="['historial_report']" styleClass="h-100 text-nowrap" icon="pi pi-file-excel" label="Exportar reporte general" (click)="exportToExcel('exportToExcel')"></p-button> -->
        </div>
      </div>
    </div>
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <p-accordion [activeIndex]="0">
        @for (item of this.list; track $index) {
        <p-accordionTab [header]="item.name">
          <div class="d-flex justify-content-end gap-1">
            <p-button *showForPermissions="['historial_report']" styleClass="h-100 text-nowrap" icon="pi pi-file-excel" label="Exportar reporte" (click)="exportToExcel(item.name)"></p-button>
          </div>
          <table class="table table-responsive-md table-borderless rounded-2 mb-0">
            <thead class="border-bottom">
              <tr>
                <th scope="col">Dato</th>
                <th scope="col">Documento</th>
                <th scope="col">Fecha</th>
                <th scope="col">Usuario</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (subitem of item.items; track $index) {
              <tr>
                <td class="align-middle fw-medium text-muted">
                  {{subitem.rfc}}
                  {{subitem.curp}}
                </td>
                <td class="align-middle fw-medium text-muted">
                  Constancia de situación fiscal
                </td>
                <td class="align-middle fw-medium text-muted">
                  {{subitem.dateCreated.ISO8601 | date: 'longDate' }}
                </td>
                <td class="align-middle fw-medium text-muted">
                  {{item.name}}
                </td>
                <td class="align-middle fw-medium text-nowrap text-end">
                  <div class="d-flex justify-content-end gap-1">
                    <p-button *showForPermissions="['historial_update']" icon="pi pi-user" severity="success"
                      (click)="updateRequest(subitem)" label="Reasignar"></p-button>
                  </div>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </p-accordionTab>
        }
      </p-accordion>

      @for (item of this.list; track $index) {
      <table id="{{item.name}}" class="d-none">
        <thead>
          <tr>
            <th>Dato</th>
            <th>Documento</th>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>
          @for (subitem of item.items; track $index) {
          <tr>
            <td>
              {{subitem.rfc}}
              {{subitem.curp}}
            </td>
            <td>
              Constancia de situación fiscal
            </td>
            <td>
              {{subitem.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td>
              {{item.name}}
            </td>
            <td>
              {{subitem.price}}
            </td>
          </tr>
          }
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              Total:
            </td>
            <td>
              {{item.total}}
            </td>
          </tr>
        </tbody>
      </table>
      }

      <table id="exportToExcel" class="d-none">
        <thead>
          <tr>
            <th>Dato</th>
            <th>Documento</th>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>
          @for (item of this.list; track $index) {
            @for (subitem of item.items; track $index) {
            <tr>
              <td>
                {{subitem.rfc}}
                {{subitem.curp}}
              </td>
              <td>
                Constancia de situación fiscal
              </td>
              <td>
                {{subitem.dateCreated.ISO8601 | date: 'longDate' }}
              </td>
              <td>
                {{item.name}}
              </td>
              <td>
                {{subitem.price}}
              </td>
            </tr>
            }
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                Total:
              </td>
              <td>
                {{item.total}}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>

<p-dialog header="Reasignar" [(visible)]="visibleDialogUpdateRequest" [modal]="true" [style]="{ width: '50vw'}"
  [draggable]="false" [resizable]="false" position="top">
  <form [formGroup]="formUpdateRequest" (ngSubmit)="saveUpdateRequest()">
    <div class="py-4">
      <div class="row">
        <div class="col-12">
          <p-dropdown formControlName="userId" appendTo="body" [options]="userList" optionLabel="name" [filter]="true"
            filterBy="firstName,lastName,email" styleClass="w-100 h-50px" optionValue="userId">
            <ng-template pTemplate="selectedItem" let-item>
              <div>{{item.firstName}} {{item.lastName}}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>{{item.firstName}} {{item.lastName}} ({{item.email}})</div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-3 d-none">
          <p-inputNumber formControlName="price" mode="currency" currency="MXN"
            styleClass="w-100 h-50px"></p-inputNumber>
        </div>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" type="submit" (click)="saveUpdateRequest()" [loading]="this.isSavingUpdateRequest"
      [disabled]="this.isSavingUpdateRequest"></p-button>
  </ng-template>
</p-dialog>