import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-user-create',
  templateUrl: './user-create.component.html',
  styleUrl: './user-create.component.scss'
})

export class UserCreatePage {

  constructor(
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private userService: UserService,
    private toast: ToastService,
    private router: Router
  ) { }

  public isLoading = false;
  public isSaving = false;
  public permissions: any = [];
  public formUser = this.formBuilder.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phone: [null],
    sessionsAllowed: [null, Validators.required],
    requestsAllowed: [null, Validators.required],
    priceAllowed: [null, Validators.required],
    levelAccess: [null, Validators.required],
    permissionAccess: [null, Validators.required],
    password: [null, [Validators.required, Validators.minLength(8)]],
    rpassword: [null, [Validators.required, Validators.minLength(8)]],
  });

  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.formUser.get('firstName')?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.formUser.get('lastName')?.setValue(value) }
  get email() { return this.formUser.get('email') }
  set email(value: any) { this.formUser.get('email')?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.formUser.get('phone')?.setValue(value) }
  get sessionsAllowed() { return this.formUser.get('sessionsAllowed') }
  set sessionsAllowed(value: any) { this.formUser.get('sessionsAllowed')?.setValue(value) }
  get requestsAllowed() { return this.formUser.get('requestsAllowed') }
  set requestsAllowed(value: any) { this.formUser.get('requestsAllowed')?.setValue(value) }
  get priceAllowed() { return this.formUser.get('priceAllowed') }
  set priceAllowed(value: any) { this.formUser.get('priceAllowed')?.setValue(value) }
  get levelAccess() { return this.formUser.get('levelAccess') }
  set levelAccess(value: any) { this.formUser.get('levelAccess')?.setValue(value) }
  get permissionAccess() { return this.formUser.get('permissionAccess') }
  set permissionAccess(value: any) { this.formUser.get('permissionAccess')?.setValue(value) }
  get password() { return this.formUser.get('password') }
  set password(value: any) { this.formUser.get('password')?.setValue(value) }
  get rpassword() { return this.formUser.get('rpassword') }
  set rpassword(value: any) { this.formUser.get('rpassword')?.setValue(value) }

  async ngOnInit() {
    this.isLoading = true;
    await this.catalogsService.permissions()
      .then((response: any) => this.permissions = response)
      .catch(() => this.toast.error('Error al obtener los permisos.'))
    this.isLoading = false;
  }

  save = async () => {
    this.isSaving = true;
    await this.userService.create(this.formUser.value)
      .then(() => {
        this.toast.success('Usuario creado correctamente.')
        this.router.navigate(['/usuarios'])
      })
      .catch(() => this.toast.error('Error al crear el usuario.'))
    this.isSaving = false;
  }

  setPermissionAccess(event: Event) {
    /* TOFIX */
    let levelAccess = String(event);
    let permissionAccess: any = [];
    if (levelAccess == 'Super Administrador') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_update',
        'usuarios_reset_sessions',
        'usuarios_block',
        'usuarios_unblock',
        'usuarios_delete'
      ];
    } else if (levelAccess == 'Administrador') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_reset_sessions'
      ];
    } else if (levelAccess == 'Cliente') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_busqueda_masiva',
        'use_historial'
      ];
    } else if (levelAccess == 'Oficina') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_reset_sessions'
      ];
    }
    this.formUser.get('permissionAccess')?.setValue(permissionAccess);
    /* END TOFIX */
  }
}
