<backbone currentPage="BusquedaMasiva">
  <div class="p-3">
    <section class="page-section bg-white rounded-2 shadow-sm mb-3">
      <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isUpload === true}"
        class="p-3 d-flex flex-column justify-content-center align-items-center">
        <div class="upload w-100">
          <input formControlName="fileTmp" (change)="onFileChanged($event)" type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />

          @if (this.file.value === null) {
          <p>Subir archivo</p>
          }
          @else {
          <p>Buscando, por favor espere...</p>
          }
        </div>

        <!-- <p-button id="buttonRequest" label="Búsqueda" styleClass="h-100 m-auto" (click)="request()" [loading]="this.isUpload" icon="pi pi-search"
          [disabled]="!this.form.valid"></p-button> -->
      </form>
    </section>

    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <p-accordion [activeIndex]="0">
        @for (item of this.list; track $index) {
        <p-accordionTab [header]="'Código: ' + item.massive">
          <table class="table table-responsive-md table-borderless rounded-2 mb-0">
            <thead class="border-bottom">
              <tr>
                <th scope="col">Dato</th>
                <th scope="col">Documento</th>
                <th scope="col">Fecha</th>
                <th scope="col">Usuario</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (subitem of item.items; track $index) {
              <tr>
                <td class="align-middle fw-medium text-muted">
                  {{subitem.rfc}}
                  {{subitem.curp}}
                </td>
                <td class="align-middle fw-medium text-muted">
                  Constancia de situación fiscal
                </td>
                <td class="align-middle fw-medium text-muted">
                  {{subitem.dateCreated.ISO8601 | date: 'longDate' }}
                </td>
                <td class="align-middle fw-medium text-muted">
                  {{subitem.name}}
                </td>
                <td class="align-middle fw-medium text-nowrap text-end">
                  @if (subitem.statusId == 1) {
                  <small class="p-button p-button-warning"><i class="pi pi-clock" style="margin-right: 10px;"></i>En espera</small>
                  }
                  @if (subitem.statusId == 2) {
                  <div class="d-flex justify-content-end gap-1">
                    <a href="{{apiUrl}}/api/v1/rfc/download/{{subitem.rfcRequestId}}" download target="_blank"
                      rel="noopener noreferrer" class="p-button text-decoration-none"
                      [ngClass]="{'p-button-success': item.download == 0, 'p-button-secondary' : item.download == 1 }">
                      <i class="pi pi-download" style="margin-right: 10px;"></i>Descargar
                    </a>
                  </div>
                  }
                  @if (subitem.statusId == 3) {
                  <small class="p-button p-button-danger"><i class="pi pi-times-circle" style="margin-right: 10px;"></i>No disponible</small>
                  }
                </td>
              </tr>
              }
            </tbody>
          </table>
        </p-accordionTab>
        }
      </p-accordion>
    </section>
    }
  </div>
</backbone>