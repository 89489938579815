import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'alert',
  standalone: true,
  imports: [
    NgbAlertModule,
    CommonModule,
    RouterModule
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input() message = '';
  @Input() id = '';
  @Input() url = '';
  public staticAlertClosed = false;
  @ViewChild('staticAlert', { static: false }) staticAlert!: NgbAlert;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.staticAlert.close(), 10000);
  }
}
