import { Component } from '@angular/core';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})

export class UserListPage {

  constructor(
    private userService: UserService,
    private catalogService: CatalogsService,
    private toast: ToastService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  public isLoading = true;
  public roles: any = [];
  public users: any = [];

  async ngOnInit() {
    await this.catalogService.permissions()
      .then((response) => this.roles = response)
      .catch(() => this.toast.error('Error al obtener los permisos.'))
    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getUsers();
  }

  getUsers = async () => {
    await this.userService.getList()
      .then((response) => this.users = response)
      .catch(() => this.toast.error('Error al obtener los usuarios.'))
    this.isLoading = false
  }

  findRole = (id: number) => this.roles.find((role: any) => role.permissionId === id)

  public isBlocking = false;
  block = (event: Event, username: string) => {
    this.isBlocking = true;
    this.confirmDialogService.dialog('¿Está seguro de bloquear este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.block(username)
          .then(() => {
            this.toast.success('Usuario bloqueado correctamente.');
            this.isBlocking = false;
            this.refresh();
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isBlocking = false
          })
      })
      .catch(() => this.isBlocking = false)
  }

  public isUnblocking = false;
  unblock = (event: Event, username: string) => {
    this.isUnblocking = true;
    this.confirmDialogService.dialog('¿Está seguro de desbloquear este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.unblock(username)
          .then(() => {
            this.toast.success('Usuario desbloqueado correctamente.');
            this.isUnblocking = false;
            this.refresh();
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message);
            this.isUnblocking = false;
          })
      })
      .catch(() => this.isUnblocking = false)
    this.isUnblocking = false;
  }

  public isDeleting = false;
  delete = (event: Event, username: string) => {
    this.isDeleting = true;
    this.confirmDialogService.dialog('¿Está seguro de eliminar este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.delete(username)
          .then(() => {
            this.isDeleting = false;
            this.toast.success('Usuario eliminado correctamente.')
            this.refresh();
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false;
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
