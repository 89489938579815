import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomePage {
  public router = inject(Router);

  ngOnInit() {
    this.router.navigate(['/rfc'])
  }
}
