import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReversosPageRoutingModule } from './reversos-routing.module';
import { ReversosPage } from './reversos.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';

@NgModule({
  declarations: [
    ReversosPage
  ],
  imports: [
    CommonModule,
    ReversosPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    ReactiveFormsModule,
    NgSelectModule,
    InputTextModule,
    ButtonModule,
    PlaceholderTableComponent
  ]
})
export class ReversosPageModule { }
