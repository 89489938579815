<backbone currentPage="Rfc">
  <!-- <header-page-title-box title="Constancias de situación fiscal."
    [links]="[{title: 'RFC',  link: '/rfc'}, {title: 'Lista'}]" /> -->
  <div class="p-3">
    <form *showForPermissions="['rfc_request']" [formGroup]="form"
      [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}" class="mb-3">
      <div class="card page-section rounded-2 shadow-sm">
        <!-- <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Solicitar una constacia de situación fiscial.</h6>
        </div> -->
        <div class="card-body">
          <div class="d-flex gap-2">
            <input maxlength="18" formControlName="data" type="text" pInputText placeholder="Ingresar dato a buscar" class="w-100 h-50px" />

            <p-button icon="pi pi-search" label="Búsqueda" styleClass="h-100" (click)="request()" [loading]="isSaving"
              [disabled]="!form.valid"></p-button>
          </div>
        </div>
      </div>
    </form>

    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Dato</th>
            <th scope="col">Documento</th>
            <th scope="col">Fecha</th>
            <th scope="col">Usuario</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of this.list; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.rfc}}
              {{item.curp}}
            </td>
            <td class="align-middle fw-medium text-muted">
              Constancia de situación fiscal
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.name}}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              @if (item.statusId == 1) {
              <small class="p-button p-button-warning"><i class="pi pi-clock" style="margin-right: 10px;"></i>En espera</small>
              }
              @if (item.statusId == 2) {
              <div class="d-flex justify-content-end gap-1">
                <a href="{{apiUrl}}/api/v1/rfc/download/{{item.rfcRequestId}}" download target="_blank"
                  rel="noopener noreferrer" class="p-button text-decoration-none"
                  [ngClass]="{'p-button-success': item.download == 0, 'p-button-secondary' : item.download == 1 }">
                  <i class="pi pi-download" style="margin-right: 10px;"></i>Descargar
                </a>
              </div>
              }
              @if (item.statusId == 3) {
              <small class="p-button p-button-danger"><i class="pi pi-times-circle" style="margin-right: 10px;"></i>No disponible</small>
              }
            </td>
          </tr>
          }
          @empty {
          <tr>
            <td scope="row" colspan="6" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">No se encontraron resultados</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>