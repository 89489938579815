import { Component } from '@angular/core';

@Component({
  selector: 'lock-screen',
  standalone: true,
  imports: [],
  templateUrl: './lock-screen.component.html',
  styleUrl: './lock-screen.component.scss'
})
export class LockScreenComponent {
  private images: string[] = [
    "david-kovalenko-G85VuTpw6jg-unsplash.jpg", // Domingo
    "emma-jean-cliff-wOeoWbhM814-unsplash.jpg", // Lunes
    "ian-schneider-PAykYb-8Er8-unsplash.jpg", // Martes
    "jack-anstey-XVoyX7l9ocY-unsplash.jpg", // Miercoles
    "adam-kool-ndN00KmbJ1c-unsplash.jpg", // Jueves
    "cristina-gottardi-CSpjU6hYo_0-unsplash.jpg", // Viernes
    "luca-bravo-zAjdgNXsMeg-unsplash.jpg" // Sabado
  ]
  public pictureOfTheDay: string | null = null
  public date: any = new Date()

  ngOnInit() {
    this.pictureOfTheDay = this.images[this.date.getDay()]
    this.date = this.date.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }
}
