<nav
  style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"
  aria-label="breadcrumb">
  <ol class="breadcrumb m-0">
    @for (item of this.links; track $index) {
    @if ($index + 1 === this.links.length) {
    <li class="breadcrumb-item text-uppercase fw-medium active" aria-current="page">
      <small>{{item.title}}</small>
    </li>
    }
    @else {
    <li class="breadcrumb-item text-uppercase fw-medium">
      <a class="text-decoration-none" href="{{item.link ? item.link : '#'}}">
        <small>{{item.title}}</small>
      </a>
    </li>
    }
    }
  </ol>
</nav>