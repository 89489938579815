import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RfcService } from '@services/rfc.service';
import { ToastService } from '@utils/toast.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'page-rfc',
  templateUrl: './rfc.component.html',
  styleUrl: './rfc.component.scss'
})

export class RfcPage {
  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private rfcService: RfcService
  ) { }

  public apiUrl = environment.app.apiUrl;
  public isLoading = false;
  public isSaving = false;
  public list: any = []
  public form = this.formBuilder.group({
    data: [null, [Validators.required]],
  });

  get data() { return this.form.get('data') }
  set data(value: any) { this.form.get('data')?.setValue(value) }

  async ngOnInit() {
    this.init()
  }

  init = async () => {
    this.isLoading = true;
    await this.rfcService.list()
      .then((response: any) => this.list = response.content)
      .catch(() => this.toast.error('Error al obtener la búsqueda.'))
    this.isLoading = false;
  }

  request = async () => {
    this.isSaving = true;
    await this.rfcService.create(this.form.value)
      .then((response: any) => {
        this.init()
        this.form.reset();
        this.toast.success('Se solícito la información.');
      })
      .catch((error: any) => this.toast.error(error.error.data.message))
    setTimeout(() => this.isSaving = false, 500);
  }
}
