import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusquedaMasivaPage } from './busqueda-masiva.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'busqueda-masiva',
    component: BusquedaMasivaPage,
    canActivate: [authGuard, hasRoleGuard(null, ['rfc_busqueda_masiva'])],
    data: {
      title: "Búsqueda masiva"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BusquedaMasivaPageRoutingModule { }
