<backbone currentPage="CambiarEmision">
  <div class="p-3">
    <section class="page-section bg-white rounded-2 shadow-sm mb-3">
      <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isLoading === true}"
        class="p-3 d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex w-100 gap-3 mb-3">
          <ng-select formControlName="estado" [clearable]="false" class="w-100 h-50px" placeholder="Seleccionar estado" (change)="setMunicipios($event)">
            @for (item of this.estados; track $index) {
            <ng-option [value]="item.CODE">Estado: {{item.LABEL}}</ng-option>
            }
          </ng-select>
          <ng-select formControlName="municipio" [clearable]="false" class="w-100 h-50px" placeholder="Seleccionar municipio">
            @for (item of this.municipios; track $index) {
            <ng-option [value]="item.ID">Municipio: {{item.LABEL}}</ng-option>
            }
          </ng-select>
        </div>
        <div class="upload w-100">
          <input (change)="onFileChanged($event)" type="file">
          @if (this.documento.value === null) {
          <p>Subir archivo</p>
          }
          @else {
          <p>Cambiando emisión, por favor espere...</p>
          }
        </div>
        <!-- @if (!result.pdf) {
          <p-button icon="pi pi-file-pdf" label="Cambiar emisión" styleClass="h-100 text-nowrap" (click)="request()" [loading]="isLoading" [disabled]="!form.valid"></p-button>
        }
        @if (result.pdf) {
          <p-button icon="pi pi-download" severity="success" label="Descargar" styleClass="h-100 text-nowrap" (click)="download()"></p-button>
        } -->
      </form>
    </section>
    @if (this.isLoading === true) {
      <section class="page-section bg-white rounded-2 shadow-sm p-3">
        <placeholder-table />
      </section>
      }
      @else {
      <section class="page-section bg-white rounded-2 shadow-sm">
        <table class="table table-responsive-md table-borderless rounded-2 mb-0">
          <thead class="border-bottom">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Fecha</th>
              <th scope="col">Descargar Original</th>
              <th scope="col">Descargar Nuevo</th>
            </tr>
          </thead>
  
          <tbody>
            @for (item of this.list; track $index) {
            <tr>
              <td class="align-middle fw-medium text-muted">
                00000000{{item.rfcChangeOriginLogId}}
              </td>
              <td class="align-middle fw-medium text-muted">
                {{item.actionDate.ISO8601 | date: 'longDate' }}
              </td>
              <td class="align-middle fw-medium text-nowrap text-end">
                <div class="d-flex justify-content-start gap-1">
                  <a href="{{apiUrl}}/api/v1/changeorigin/download/origin/{{item.rfcChangeOriginLogId}}" download target="_blank"
                    rel="noopener noreferrer" class="p-button text-decoration-none"
                    [ngClass]="{'p-button-success': item.download == 0, 'p-button-secondary' : item.download == 1 }">
                    <i class="pi pi-download" style="margin-right: 10px;"></i>Descargar Original
                  </a>
                </div>
              </td>
              <td class="align-middle fw-medium text-nowrap text-end">
                <div class="d-flex justify-content-start gap-1">
                  <a href="{{apiUrl}}/api/v1/changeorigin/download/new/{{item.rfcChangeOriginLogId}}" download target="_blank"
                    rel="noopener noreferrer" class="p-button text-decoration-none"
                    [ngClass]="{'p-button-success': item.download == 0, 'p-button-secondary' : item.download == 1 }">
                    <i class="pi pi-download" style="margin-right: 10px;"></i>Descargar Nuevo
                  </a>
                </div>
              </td>
            </tr>
            }
            @empty {
            <tr>
              <td scope="row" colspan="6" class="align-middle fw-medium text-center">
                <p class="m-0 p-4 opacity-50">No se encontraron resultados</p>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </section>
      }
  </div>
</backbone>