import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReversosService } from '@services/reversos.service';
import { ToastService } from '@utils/toast.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'page-reversos',
  templateUrl: './reversos.component.html',
  styleUrl: './reversos.component.scss'
})
export class ReversosPage {
  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private reversosService: ReversosService
  ) { }

  public apiUrl = environment.app.apiUrl;

  public isLoading = false;
  public isSaving = false;

  public form = this.formBuilder.group({
    fileTmp: [null, [Validators.required]],
    file: [null, [Validators.required]],
    state: [null, [Validators.required]],
    foil: [null, [Validators.required]],
  });

  get file() { return this.form.get('file') }
  set file(value: any) { this.file?.setValue(value) }
  get state() { return this.form.get('state') }
  set state(value: any) { this.state?.setValue(value) }
  get foil() { return this.form.get('foil') }
  set foil(value: any) { this.foil?.setValue(value) }

  public states: any = [
    'AGUASCALIENTES',
    'BAJA CALIFORNIA',
    'BAJA CALIFORNIA SUR',
    'CAMPECHE',
    'COAHUILA',
    'COAHUILA DE ZARAGOZA',
    'COLIMA',
    'CHIAPAS',
    'CHIHUAHUA',
    'CIUDAD DE MEXICO',
    'DURANGO',
    'GUANAJUATO',
    'GUERRERO',
    'HIDALGO',
    'JALISCO',
    'MEXICO',
    'DISTRITO FEDERAL',
    'MICHOACAN',
    'MICHOACAN DE OCAMPO',
    'MORELOS',
    'NAYARIT',
    'NUEVO LEON',
    'OAXACA',
    'PUEBLA',
    'QUERETARO',
    'QUINTANA ROO',
    'SAN LUIS POTOSI',
    'SINALOA',
    'SONORA',
    'TABASCO',
    'TAMAULIPAS',
    'TLAXCALA',
    'VERACRUZ',
    'YUCATAN',
    'ZACATECAS',
  ]
  public list: any = []

  async ngOnInit() {
    this.init()
  }

  init = async () => {
    this.isLoading = true;
    await this.reversosService.list()
      .then((response: any) => this.list = response.content)
      .catch(() => this.toast.error('Error al obtener la búsqueda.'))
    this.isLoading = false;
    this.state = 'AGUASCALIENTES';
    this.foil = 'false';
  }

  onFileChanged = async (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      await this.request();
    }
  }

  request = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('file', this.file.value)
    myFormData.append('state', this.state.value)
    myFormData.append('foil', this.foil.value)

    await this.reversosService.create(myFormData)
      .then((response: any) => {
        this.init()
        this.form.reset();
        this.toast.success('Reverso agregado correctamente.');
      })
      .catch((error: any) => this.toast.error(error.error.data.message))

    setTimeout(() => this.isSaving = false, 500);
  }
}
