import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  private nameClass: string = 'open-sidebar-mainmenu'
  constructor() { }

  openMainMenu = () => document.body.classList.add(this.nameClass)
  closeMainMenu = () => document.body.classList.remove(this.nameClass)
  toggleMainMenu = () => document.body.classList.toggle(this.nameClass)
}
