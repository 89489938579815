import { Component, HostListener, isDevMode } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { StorageService } from '@utils/storage.service';
import { WebsocketService } from '@services/websocket.service';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';
import { MainShortcutsService } from '@components/sidebars/main-shortcuts/main-shortcuts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private storage: StorageService,
    private websocket: WebsocketService,
    private mainMenuService: MainMenuService,
    private mainShortcutsService: MainShortcutsService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart === true) {
        this.websocket.registerOnlineUser()
      }

      if (val instanceof NavigationEnd === true) {
        this.websocket.disconnect()
        // if (isDevMode() === true) {
        //   this.websocket.disconnect()
        // }
      }
    });
  }

  ngOnInit() {
    // Set the page title based on the route data
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data: any) => {
          if (data.title !== undefined) {
            this.title.setTitle(`${data.title} - ${environment.app.title}`)
          } else {
            this.title.setTitle(environment.app.title)
          }
        });

        this.mainMenuService.closeMainMenu()
      });

    // Reload the page if the '__reload' storage key is set to 'true'
    this.storage.watch('__reload')
      .subscribe((response) => {
        if (response === 'true') {
          this.storage.removeItem('__reload')
          window.location.reload()
        }
      })
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'b') {
      this.mainShortcutsService.lockscreen()
    }
  }
}
