<div class="card page-section rounded-2 shadow-sm">
  <div class="card-header align-items-center d-flex rounded-top-2">
    <h6 class="card-title flex-grow-1 m-0 placeholder-glow">
      <span class="placeholder col-3"></span>
    </h6>
  </div>
  <div class="card-body">
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-6"></span>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-8"></span>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-6"></span>
      </div>
    </div>
    <div class="row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-8"></span>
      </div>
    </div>
  </div>
</div>

<div class="card page-section rounded-2 shadow-sm opacity-50">
  <div class="card-header align-items-center d-flex rounded-top-2">
    <h6 class="card-title flex-grow-1 m-0 placeholder-glow">
      <span class="placeholder col-3"></span>
    </h6>
  </div>
  <div class="card-body">
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-6"></span>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-8"></span>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-6"></span>
      </div>
    </div>
    <div class="row">
      <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center placeholder-glow">
        <span class="placeholder col-12"></span>
      </label>
      <div class="col-sm-10 d-flex align-items-center placeholder-glow">
        <span class="placeholder col-8"></span>
      </div>
    </div>
  </div>
</div>