<backbone [currentPage]="'user-view'">
  <!-- <header-page-title-box [title]="'Cuenta de usuario: ' + this.username"
    [links]="[{title: 'Usuario',  link: '/users'}, {title: this.username}]"> -->
  <header-page-title-box>
    <div class="d-flex gap-2" buttons>
      <p-button *showForPermissions="['usuarios_reset_sessions']" size="small" icon="pi pi-refresh"
        label="Resetear sesiones" [loading]="isResetingSessions" (onClick)="resetSessions($event)"></p-button>
      @if (this.user !== null) {
      @if (this.user?.status == 'active') {
      <p-button *showForPermissions="['usuarios_block']" size="small" icon="pi pi-ban" severity="danger" label="Bloquear usuario"
        [loading]="isBlocking" (onClick)="block($event)"></p-button>
      }
      @if (this.user?.status == 'block') {
      <p-button *showForPermissions="['usuarios_unblock']" size="small" icon="pi pi-check-circle" severity="success" label="Desbloquear usuario"
        [loading]="isUnblocking" (onClick)="unblock($event)"></p-button>
      }
      <p-button *showForPermissions="['usuarios_delete']" size="small" icon="pi pi-trash" severity="danger" label="Eliminar usuario"
        [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      <p-button *showForPermissions="['usuarios_update']" size="small" icon="pi pi-save" label="Guardar usuario"
        [loading]="isSaving" (onClick)="save()" [disabled]="!formUser.valid"></p-button>
      <p-button size="small" icon="pi pi-arrow-left" label="Regresar" [routerLink]="['/usuarios']"></p-button>
      }
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="formUser"
      [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true || this.isDeleting === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de perfil</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Fecha de
              registro</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.dateCreated.ISO8601 | date: 'longDate' }}</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Id de
              registro</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.userId }}</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Estado de
              registro</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">@if (this.user?.status == 'active') { Activo } @else { Bloqueado }</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Nombre(s)</label>
            <div class="col-sm-10">
              <input formControlName="firstName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Apellidos</label>
            <div class="col-sm-10">
              <input formControlName="lastName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center">Correo electronico</label>
            <div class="col-sm-10">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px"
                placeholder="example@domain.com" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPhone" class="col-sm-2 col-form-label d-flex align-items-center">Número de télefono
              (Opcional)</label>
            <div class="col-sm-10">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" placeholder="(999) 999-9999"
                styleClass="w-100 h-50px"></p-inputMask>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputSessionsAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              sesiones permitidas</label>
            <div class="col-sm-10">
              <input formControlName="sessionsAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputRequestsAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              solicitudes permitidas</label>
            <div class="col-sm-10">
              <input formControlName="requestsAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPriceAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Precio
              permitido</label>
            <div class="col-sm-10">
              <input formControlName="priceAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputLevelAccess" class="col-sm-2 col-form-label d-flex align-items-center">Nivel de
              acceso</label>
            <div class="col-sm-10">
              <div class="input-group has-validation">
                <ng-select formControlName="levelAccess" id="inputLevelAccess" [clearable]="false" class="w-100 h-50px" (change)="setPermissionAccess($event)">
                  <!-- TOFIX: Esta lista debe de llegar desde la api/base de datos -->
                  <ng-option [value]="'Super Administrador'">Super Administrador</ng-option>
                  <ng-option [value]="'Administrador'">Administrador</ng-option>
                  <ng-option [value]="'Cliente'">Cliente</ng-option>
                  <ng-option [value]="'Oficina'">Oficina</ng-option>
                  <!-- ENDFIX -->
                </ng-select>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPermissionAccess" class="col-sm-2 col-form-label d-flex align-items-center">Permiso de
              acceso</label>
            <div class="col-sm-10">
              <div class="input-group has-validation">
                <ng-select formControlName="permissionAccess" id="inputPermissionAccess" [clearable]="false"
                  [multiple]="true" [closeOnSelect]="false" class="w-100 h-50px">
                  <!-- TOFIX: Esta lista debe de llegar desde la api/base de datos -->
                  <ng-option [value]="'use_rfc'">Acceso a RFC</ng-option>
                  <ng-option [value]="'rfc_request'">Realizar búsqueda de RFC</ng-option>
                  <ng-option [value]="'rfc_all'">Ver todas las búsquedas de RFC</ng-option>
                  <ng-option [value]="'rfc_busqueda_masiva'">Realizar búsqueda masiva de RFC</ng-option>
                  <ng-option [value]="'rfc_cambiar_emision'">Cambiar emisión de RFC</ng-option>
                  <ng-option [value]="'use_historial'">Acceso a Historial</ng-option>
                  <ng-option [value]="'historial_update'">Asignar Cliente/Precio a Historial</ng-option>
                  <ng-option [value]="'historial_report'">Descargar reporte de Historial</ng-option>
                  <ng-option [value]="'use_usuarios'">Acceso a Usuarios</ng-option>
                  <ng-option [value]="'usuarios_create'">Crear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_update'">Actualizar Usuarios</ng-option>
                  <ng-option [value]="'usuarios_reset_sessions'">Resetear sesiones</ng-option>
                  <ng-option [value]="'usuarios_block'">Bloquear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_unblock'">Desbloquear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_delete'">Eliminar Usuarios</ng-option>
                  <ng-option [value]="'use_reversos'">Acceso a los reversos</ng-option>
                  <!-- ENDFIX -->
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de acceso</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Nombre de
              usuario</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.username }} ({{ this.user?.email }})</p>
            </div>
          </div>

          <div *showForPermissions="['usuarios_update']" class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label d-flex align-items-center">Contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="password" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>

          <div *showForPermissions="['usuarios_update']" class="mb-3 row">
            <label for="inputRPassword" class="col-sm-2 col-form-label d-flex align-items-center">Repetir
              contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="rpassword" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm mb-4">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de conexión</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Número de sesiones
              activas</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.currentSessions }} de {{this.user?.sessionsAllowed}}</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Estado de conexión</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">@if (this.user?.currentSessions > 0) { Conectado } @else { Desconectado }</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              solicitudes (Hoy)</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.todayRequests }} de {{ this.user?.requestsAllowed }}</p>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticDateRegister" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              solicitudes (Periodo)</label>
            <div class="col-sm-10 d-flex align-items-center">
              <p class="m-0">{{ this.user?.weekRequests }} en total</p>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
    <div *showForPermissions="['usuarios_delete']" class="card page-section rounded-2 shadow-sm">
      <div class="card-header align-items-center d-flex rounded-top-2">
        <h6 class="card-title flex-grow-1 m-0">Cambiar token PDF.co</h6>
      </div>
      <div class="card-body">
        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label d-flex align-items-center">Token PDF.co</label>
          <div class="col-sm-9">
            <input [(ngModel)]="token" type="text" pInputText class="w-100 h-50px" />
          </div>
          <div class="col-sm-1">
            <p-button icon="pi pi-save" label="Guardar Token" (onClick)="addToken()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</backbone>