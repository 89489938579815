import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})

export class BusquedaMasivaService {
  
  constructor(private http: HttpService) { }

  public getList = async () => await this.http.get(`/api/v1/rfc/masiva`)
    .then((response: any) => response.data)
}
