import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class HistorialService {
  
  constructor(private http: HttpService) { }

  public list = async (period: string) => await this.http.get(`/api/v1/rfc/historial/${period}`)
    .then((response: any) => response.data)
}
