import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusquedaMasivaPageRoutingModule } from './busqueda-masiva-routing.module';
import { BusquedaMasivaPage } from './busqueda-masiva.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BusquedaMasivaPage
  ],
  imports: [
    CommonModule,
    BusquedaMasivaPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    PlaceholderTableComponent,
    ButtonModule,
    FileUploadModule,
    AccordionModule,
    ReactiveFormsModule
  ]
})

export class BusquedaMasivaPageModule { }
