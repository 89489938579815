import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionService } from '@utils/session.service';

/**
 * Function that determines whether a user is authorized to access a route.
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A boolean indicating whether the user is authorized to access the route.
 */
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const jwtHelper = inject(JwtHelperService);
  const session = inject(SessionService);
  const token = session.getToken()

  if (session.isLoggedIn()) { // Exists user
    if (session.isTokenExist()) { // Exists token
      if (!jwtHelper.isTokenExpired(token!.token)) { // Token is not expired
        return true // Allow access
      } else { // Token is expired
        router.navigate(['login']);
        return false // Deny access
      }
    } else { // Not exists token
      router.navigate(['lockscreen']);
      return false // Deny access
    }
  } else { // Not exists user
    router.navigate(['login']);
    return false // Deny access
  }
};
