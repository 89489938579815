import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropdownUserAccountComponent } from '@components/dropdowns/user-account/user-account.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { SessionService } from '@utils/session.service';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';

@Component({
  selector: 'sidebar-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  standalone: true,
  imports: [
    DropdownUserAccountComponent,
    RouterModule,
    ShowForPermissionsDirective
  ],
  providers: [
    MainMenuService
  ]
})
export class SidebarMainMenuComponent {
  constructor(
    private mainMenuService: MainMenuService
  ) { }
  public session = inject(SessionService);
  public leaveHoverToMainMenu = () => this.mainMenuService.closeMainMenu()
}
