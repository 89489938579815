import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListPageModule } from './user-list/user-list.module';
import { UserViewPageModule } from './user-view/user-view.module';
import { UserCreatePageModule } from './user-create/user-create.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    UserListPageModule,
    UserViewPageModule,
    UserCreatePageModule
  ],
  exports: [RouterModule]
})
export class UserRoutingModule { }
