<backbone currentPage="Reversos">
  <div class="p-3">
    <section class="page-section bg-white rounded-2 shadow-sm mb-3">
      <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}"
        class="p-3 d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex w-100 gap-3 mb-3">
          <ng-select formControlName="state" placeholder="Seleccionar estado" [clearable]="false"
            class="w-100 h-50px">
            @for (item of this.states; track $index) {
            <ng-option [value]="item">Estado: {{ item }}</ng-option>
            }
          </ng-select>

          <ng-select formControlName="foil" placeholder="¿Foliar reverso?" [clearable]="false" class="w-100 h-50px">
            <ng-option value="true">Foliar reverso</ng-option>
            <ng-option value="false">No foliar reverso</ng-option>
          </ng-select>
        </div>

        <div class="upload mb-3 w-100">
          <input formControlName="fileTmp" (change)="onFileChanged($event)" type="file" accept="application/pdf" />

          @if (this.file.value === null) {
          <p>Subir archivo</p>
          }
          @else {
          <p>Agregando reverso, por favor espere...</p>
          }
        </div>

        <!-- <p-button label="Agregar reverso" styleClass="h-100 m-auto" (click)="request()" [loading]="this.isSaving"
          [disabled]="!this.form.valid" icon="pi pi-file-pdf"></p-button> -->
      </form>
    </section>

    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Folio</th>
            <th scope="col">Documento</th>
            <th scope="col">Fecha</th>
            <th scope="col">Usuario</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of this.list; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              00000000{{item.sku}}
            </td>
            <td class="align-middle fw-medium text-muted">
              Acta de nacimiento
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.name}}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                <a href="{{apiUrl}}/api/v1/reverso/download/{{item.reversoId}}" download target="_blank"
                  rel="noopener noreferrer" class="p-button text-decoration-none"
                  [ngClass]="{'p-button-success': item.download == 0, 'p-button-secondary' : item.download == 1 }">
                  <i class="pi pi-download" style="margin-right: 10px;"></i>Descargar
                </a>
              </div>
            </td>
          </tr>
          }
          @empty {
          <tr>
            <td scope="row" colspan="6" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">No se encontraron resultados</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>