import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MainShortcutsService } from './main-shortcuts.service';
import { SessionService } from '@utils/session.service';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';

@Component({
  selector: 'sidebar-main-shortcuts',
  templateUrl: './main-shortcuts.component.html',
  styleUrl: './main-shortcuts.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    NgbTooltipModule,
    ShowForPermissionsDirective
  ],
  providers: [
    MainMenuService
  ]
})
export class SidebarMainShortcutsComponent {
  constructor(
    private mainShortcutsService: MainShortcutsService,
    public session: SessionService,
    private mainMenuService: MainMenuService
  ) { }

  public lockscreen = () => {
    this.mainShortcutsService.lockscreen()
  }

  public enterHoverToMainMenu = () => this.mainMenuService.openMainMenu()
}
