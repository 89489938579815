import { Component, Input } from '@angular/core';
import { DropdownLanguageComponent } from '@components/dropdowns/language/language.component';
import { DropdownUserAccountComponent } from '@components/dropdowns/user-account/user-account.component';
import { BellButtonComponent } from '@components/notifications/bell-button/bell-button.component';
import { SearchComponent } from '@components/searchs/search/search.component';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'header-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
  standalone: true,
  imports: [
    NgbDropdownModule,
    BellButtonComponent,
    DropdownLanguageComponent,
    DropdownUserAccountComponent,
    SearchComponent
  ],
  providers: [
    MainMenuService
  ]
})
export class TopbarComponent {
  @Input() currentPage: string = '';

  constructor(
    private mainMenuService: MainMenuService
  ) { }

  toggleMainMenu = () => this.mainMenuService.toggleMainMenu()
}
