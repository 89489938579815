<backbone [currentPage]="'user-create'">
  <!-- <header-page-title-box [title]="'Crear nueva cuenta de usuario'"
    [links]="[{title: 'Usuarios',  link: '/users'}, {title: 'Crear cuenta'}]"> -->
  <header-page-title-box>
    <div class="d-flex gap-2" buttons>
      <p-button size="small" icon="pi pi-arrow-left" label="Regresar" [routerLink]="['/usuarios']"></p-button>
      <p-button size="small" icon="pi pi-save" label="Guardar usuario" [loading]="isSaving" (onClick)="save()"
        [disabled]="!formUser.valid"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    <form [formGroup]="formUser" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de perfil</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Nombre(s)</label>
            <div class="col-sm-10">
              <input formControlName="firstName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputName" class="col-sm-2 col-form-label d-flex align-items-center">Apellidos</label>
            <div class="col-sm-10">
              <input formControlName="lastName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputEmail" class="col-sm-2 col-form-label d-flex align-items-center">Correo electronico</label>
            <div class="col-sm-10">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px"
                placeholder="example@domain.com" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPhone" class="col-sm-2 col-form-label d-flex align-items-center">Número de télefono
              (Opcional)</label>
            <div class="col-sm-10">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" placeholder="(999) 999-9999"
                styleClass="w-100 h-50px"></p-inputMask>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputSessionsAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              sesiones permitidas</label>
            <div class="col-sm-10">
              <input formControlName="sessionsAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputRequestsAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Número de
              solicitudes permitidas</label>
            <div class="col-sm-10">
              <input formControlName="requestsAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPriceAllowed" class="col-sm-2 col-form-label d-flex align-items-center">Precio
              permitido</label>
            <div class="col-sm-10">
              <input formControlName="priceAllowed" type="number" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputLevelAccess" class="col-sm-2 col-form-label d-flex align-items-center">Nivel de
              acceso</label>
            <div class="col-sm-10">
              <div class="input-group has-validation">
                <ng-select formControlName="levelAccess" id="inputLevelAccess" [clearable]="false" class="w-100 h-50px" (change)="setPermissionAccess($event)">
                  <!-- TOFIX: Esta lista debe de llegar desde la api/base de datos -->
                  <ng-option [value]="'Super Administrador'">Super Administrador</ng-option>
                  <ng-option [value]="'Administrador'">Administrador</ng-option>
                  <ng-option [value]="'Cliente'">Cliente</ng-option>
                  <ng-option [value]="'Oficina'">Oficina</ng-option>
                  <!-- ENDFIX -->
                </ng-select>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputPermissionAccess" class="col-sm-2 col-form-label d-flex align-items-center">Permiso de
              acceso</label>
            <div class="col-sm-10">
              <div class="input-group has-validation">
                <ng-select formControlName="permissionAccess" id="inputPermissionAccess" [clearable]="false"
                  [multiple]="true" [closeOnSelect]="false" class="w-100 h-50px">
                  <!-- TOFIX: Esta lista debe de llegar desde la api/base de datos -->
                  <ng-option [value]="'use_rfc'">Acceso a RFC</ng-option>
                  <ng-option [value]="'rfc_request'">Realizar búsqueda de RFC</ng-option>
                  <ng-option [value]="'rfc_all'">Ver todas las búsquedas de RFC</ng-option>
                  <ng-option [value]="'rfc_busqueda_masiva'">Realizar búsqueda masiva de RFC</ng-option>
                  <ng-option [value]="'rfc_cambiar_emision'">Cambiar emisión de RFC</ng-option>
                  <ng-option [value]="'use_historial'">Acceso a Historial</ng-option>
                  <ng-option [value]="'historial_update'">Asignar Cliente/Precio a Historial</ng-option>
                  <ng-option [value]="'historial_report'">Descargar reporte de Historial</ng-option>
                  <ng-option [value]="'use_usuarios'">Acceso a Usuarios</ng-option>
                  <ng-option [value]="'usuarios_create'">Crear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_update'">Actualizar Usuarios</ng-option>
                  <ng-option [value]="'usuarios_reset_sessions'">Resetear sesiones</ng-option>
                  <ng-option [value]="'usuarios_block'">Bloquear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_unblock'">Desbloquear Usuarios</ng-option>
                  <ng-option [value]="'usuarios_delete'">Eliminar Usuarios</ng-option>
                  <ng-option [value]="'use_reversos'">Acceso a los reversos</ng-option>
                  <!-- ENDFIX -->
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de acceso</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-2 col-form-label d-flex align-items-center">Contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="password" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputRPassword" class="col-sm-2 col-form-label d-flex align-items-center">Repetir
              contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="rpassword" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>
        </div>
      </div>
    </form>
    }
  </div>
</backbone>