import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from '@utils/session.service';
import { ToastService } from '@utils/toast.service';

export function hasRoleGuard(allowedRoles: string[] | null, allowedPermissions: string[] | null): CanActivateFn {
  return (route, state) => {
    const router = inject(Router);
    const session = inject(SessionService);
    const toastService = inject(ToastService);
    const msgError = 'No tienes permisos para acceder.';

    if (allowedRoles === null || allowedRoles.includes(session.getRole())) {
      if (allowedPermissions === null || session.existsPermissions(allowedPermissions)) {
        return true;
      } else {
        router.navigate(['/']);
        toastService.error(msgError);
        return false;
      }
    } else {
      router.navigate(['/']);
      toastService.error(msgError);
      return false;
    }
  };
};
