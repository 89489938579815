import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistorialPageRoutingModule } from './historial-routing.module';
import { HistorialPage } from './historial.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { ButtonModule } from 'primeng/button';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  declarations: [
    HistorialPage
  ],
  imports: [
    CommonModule,
    HistorialPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    PlaceholderTableComponent,
    ButtonModule,
    ShowForPermissionsDirective,
    NgSelectModule,
    FormsModule,
    DialogModule,
    InputNumberModule,
    DropdownModule,
    ReactiveFormsModule,
    AccordionModule
  ]
})

export class HistorialPageModule { }
