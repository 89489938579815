import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CambiarEmisionService } from '@services/cambiar-emision.service';
import { ToastService } from '@utils/toast.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'page-cambiar-emision',
  templateUrl: './cambiar-emision.component.html',
  styleUrl: './cambiar-emision.component.scss'
})

export class CambiarEmisionPage {

  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private cambiarEmisionService: CambiarEmisionService
  ) { }

  public apiUrl = environment.app.apiUrl;
  public isLoading = false;
  public form = this.formBuilder.group({
    estado: [null, Validators.required],
    municipio: [null, Validators.required],
    documento: [null, Validators.required]
  });
  public test: any = [];
  public list: any = [];
  public estados: any = [
    {"ID":"0","CODE":"AUTO","LABEL":"Automático"},
    {"ID":"1","CODE":"AGS","LABEL":"AGUASCALIENTES"},
    {"ID":"2","CODE":"BC","LABEL":"BAJA CALIFORNIA"},
    {"ID":"3","CODE":"BCS","LABEL":"BAJA CALIFORNIA SUR"},
    {"ID":"4","CODE":"CMP","LABEL":"CAMPECHE"},
    {"ID":"5","CODE":"COA","LABEL":"COAHUILA"},
    {"ID":"6","CODE":"COL","LABEL":"COLIMA"},
    {"ID":"7","CODE":"CHS","LABEL":"CHIAPAS"},
    {"ID":"8","CODE":"CHI","LABEL":"CHIHUAHUA"},
    {"ID":"9","CODE":"CDMX","LABEL":"CIUDAD DE MEXICO"},
    {"ID":"10","CODE":"DGO","LABEL":"DURANGO"},
    {"ID":"11","CODE":"GTO","LABEL":"GUANAJUATO"},
    {"ID":"12","CODE":"GRO","LABEL":"GUERRERO"},
    {"ID":"13","CODE":"HGO","LABEL":"HIDALGO"},
    {"ID":"14","CODE":"JAL","LABEL":"JALISCO"},
    {"ID":"15","CODE":"MEX","LABEL":"MEXICO"},
    {"ID":"16","CODE":"MCH","LABEL":"MICHOACAN DE OCAMPO"},
    {"ID":"17","CODE":"MOR","LABEL":"MORELOS"},
    {"ID":"18","CODE":"NAY","LABEL":"NAYARIT"},
    {"ID":"19","CODE":"NL","LABEL":"NUEVO LEON"},
    {"ID":"20","CODE":"OAX","LABEL":"OAXACA"},
    {"ID":"21","CODE":"PUE","LABEL":"PUEBLA"},
    {"ID":"22","CODE":"QRO","LABEL":"QUERETARO"},
    {"ID":"23","CODE":"QR","LABEL":"QUINTANA ROO"},
    {"ID":"24","CODE":"SLP","LABEL":"SAN LUIS POTOSI"},
    {"ID":"25","CODE":"SIN","LABEL":"SINALOA"},
    {"ID":"26","CODE":"SON","LABEL":"SONORA"},
    {"ID":"27","CODE":"TAB","LABEL":"TABASCO"},
    {"ID":"28","CODE":"TMS","LABEL":"TAMAULIPAS"},
    {"ID":"29","CODE":"TLX","LABEL":"TLAXCALA"},
    {"ID":"30","CODE":"VER","LABEL":"VERACRUZ IGNACIO DE LA LLAVE"},
    {"ID":"31","CODE":"YUC","LABEL":"YUCATAN"},
    {"ID":"32","CODE":"ZAC","LABEL":"ZACATECAS"}
  ];
  public municipios: any = [
    {"ID":"0","CODE":"AUTO","LABEL":"Automático"}
  ];
  public baseDeMunicipios: any = [
    {"ID":"0","CODE":"AUTO","LABEL":"Automático"},
    {"ID":"1","CODE":"AGS","LABEL":"Aguascalientes"},
    {"ID":"2","CODE":"AGS","LABEL":"Asientos"},
    {"ID":"3","CODE":"AGS","LABEL":"Calvillo"},
    {"ID":"4","CODE":"AGS","LABEL":"Cosio"},
    {"ID":"5","CODE":"AGS","LABEL":"El Llano"},
    {"ID":"6","CODE":"AGS","LABEL":"Jesus Maria"},
    {"ID":"7","CODE":"AGS","LABEL":"Pabellon de Arteaga"},
    {"ID":"8","CODE":"AGS","LABEL":"Rincon de Romos"},
    {"ID":"9","CODE":"AGS","LABEL":"San Francisco de los Romo"},
    {"ID":"10","CODE":"AGS","LABEL":"San Jose de Gracia"},
    {"ID":"11","CODE":"AGS","LABEL":"Tepezala"},
    {"ID":"12","CODE":"BC","LABEL":"Ensenada"},
    {"ID":"13","CODE":"BC","LABEL":"Mexicali"},
    {"ID":"14","CODE":"BC","LABEL":"Playas de Rosarito"},
    {"ID":"15","CODE":"BC","LABEL":"Tecate"},
    {"ID":"16","CODE":"BC","LABEL":"Tijuana"},
    {"ID":"17","CODE":"BCS","LABEL":"Comondu"},
    {"ID":"18","CODE":"BCS","LABEL":"La Paz"},
    {"ID":"19","CODE":"BCS","LABEL":"Loreto"},
    {"ID":"20","CODE":"BCS","LABEL":"Los Cabos"},
    {"ID":"21","CODE":"BCS","LABEL":"Mulege"},
    {"ID":"22","CODE":"CMP","LABEL":"Calakmul"},
    {"ID":"23","CODE":"CMP","LABEL":"Calkini"},
    {"ID":"24","CODE":"CMP","LABEL":"Campeche"},
    {"ID":"25","CODE":"CMP","LABEL":"Candelaria"},
    {"ID":"26","CODE":"CMP","LABEL":"Carmen"},
    {"ID":"27","CODE":"CMP","LABEL":"Champoton"},
    {"ID":"28","CODE":"CMP","LABEL":"Escarcega"},
    {"ID":"29","CODE":"CMP","LABEL":"Hecelchakan"},
    {"ID":"30","CODE":"CMP","LABEL":"Hopelchen"},
    {"ID":"31","CODE":"CMP","LABEL":"Palizada"},
    {"ID":"32","CODE":"CMP","LABEL":"Tenabo"},
    {"ID":"33","CODE":"COA","LABEL":"Abasolo"},
    {"ID":"34","CODE":"COA","LABEL":"Acuna"},
    {"ID":"35","CODE":"COA","LABEL":"Allende"},
    {"ID":"36","CODE":"COA","LABEL":"Arteaga"},
    {"ID":"37","CODE":"COA","LABEL":"Candela"},
    {"ID":"38","CODE":"COA","LABEL":"Castanos"},
    {"ID":"39","CODE":"COA","LABEL":"Cuatro Cienegas"},
    {"ID":"40","CODE":"COA","LABEL":"Escobedo"},
    {"ID":"41","CODE":"COA","LABEL":"Francisco I. Madero"},
    {"ID":"42","CODE":"COA","LABEL":"Frontera"},
    {"ID":"43","CODE":"COA","LABEL":"General Cepeda"},
    {"ID":"44","CODE":"COA","LABEL":"Guerrero"},
    {"ID":"45","CODE":"COA","LABEL":"Hidalgo"},
    {"ID":"46","CODE":"COA","LABEL":"Jimenez"},
    {"ID":"47","CODE":"COA","LABEL":"Juarez"},
    {"ID":"48","CODE":"COA","LABEL":"Lamadrid"},
    {"ID":"49","CODE":"COA","LABEL":"Matamoros"},
    {"ID":"50","CODE":"COA","LABEL":"Monclova"},
    {"ID":"51","CODE":"COA","LABEL":"Morelos"},
    {"ID":"52","CODE":"COA","LABEL":"Muzquiz"},
    {"ID":"53","CODE":"COA","LABEL":"Nadadores"},
    {"ID":"54","CODE":"COA","LABEL":"Nava"},
    {"ID":"55","CODE":"COA","LABEL":"Ocampo"},
    {"ID":"56","CODE":"COA","LABEL":"Parras"},
    {"ID":"57","CODE":"COA","LABEL":"Piedras Negras"},
    {"ID":"58","CODE":"COA","LABEL":"Progreso"},
    {"ID":"59","CODE":"COA","LABEL":"Ramos Arizpe"},
    {"ID":"60","CODE":"COA","LABEL":"Sabinas"},
    {"ID":"61","CODE":"COA","LABEL":"Sacramento"},
    {"ID":"62","CODE":"COA","LABEL":"Saltillo"},
    {"ID":"63","CODE":"COA","LABEL":"San Buenaventura"},
    {"ID":"64","CODE":"COA","LABEL":"San Juan de Sabinas"},
    {"ID":"65","CODE":"COA","LABEL":"San Pedro"},
    {"ID":"66","CODE":"COA","LABEL":"Sierra Mojada"},
    {"ID":"67","CODE":"COA","LABEL":"Torreon"},
    {"ID":"68","CODE":"COA","LABEL":"Viesca"},
    {"ID":"69","CODE":"COA","LABEL":"Villa Union"},
    {"ID":"70","CODE":"COA","LABEL":"Zaragoza"},
    {"ID":"71","CODE":"COL","LABEL":"Armeria"},
    {"ID":"72","CODE":"COL","LABEL":"Colima"},
    {"ID":"73","CODE":"COL","LABEL":"Comala"},
    {"ID":"74","CODE":"COL","LABEL":"Coquimatlan"},
    {"ID":"75","CODE":"COL","LABEL":"Cuauhtemoc"},
    {"ID":"76","CODE":"COL","LABEL":"Ixtlahuacan"},
    {"ID":"77","CODE":"COL","LABEL":"Manzanillo"},
    {"ID":"78","CODE":"COL","LABEL":"Minatitlan"},
    {"ID":"79","CODE":"COL","LABEL":"Tecoman"},
    {"ID":"80","CODE":"COL","LABEL":"Villa de Alvarez"},
    {"ID":"81","CODE":"CHS","LABEL":"Acacoyagua"},
    {"ID":"82","CODE":"CHS","LABEL":"Acala"},
    {"ID":"83","CODE":"CHS","LABEL":"Acapetahua"},
    {"ID":"84","CODE":"CHS","LABEL":"Aldama"},
    {"ID":"85","CODE":"CHS","LABEL":"Altamirano"},
    {"ID":"86","CODE":"CHS","LABEL":"Amatenango de la Frontera"},
    {"ID":"87","CODE":"CHS","LABEL":"Amatenango del Valle"},
    {"ID":"88","CODE":"CHS","LABEL":"Amatan"},
    {"ID":"89","CODE":"CHS","LABEL":"Angel Albino Corzo"},
    {"ID":"90","CODE":"CHS","LABEL":"Arriaga"},
    {"ID":"91","CODE":"CHS","LABEL":"Bejucal de Ocampo"},
    {"ID":"92","CODE":"CHS","LABEL":"Bella Vista"},
    {"ID":"93","CODE":"CHS","LABEL":"Benemerito de las Americas"},
    {"ID":"94","CODE":"CHS","LABEL":"Berriozabal"},
    {"ID":"95","CODE":"CHS","LABEL":"Bochil"},
    {"ID":"96","CODE":"CHS","LABEL":"Cacahoatan"},
    {"ID":"97","CODE":"CHS","LABEL":"Capitan Luis Angel Vidal"},
    {"ID":"98","CODE":"CHS","LABEL":"Catazaja"},
    {"ID":"99","CODE":"CHS","LABEL":"Chalchihuitan"},
    {"ID":"100","CODE":"CHS","LABEL":"Chamula"},
    {"ID":"101","CODE":"CHS","LABEL":"Chanal"},
    {"ID":"102","CODE":"CHS","LABEL":"Chapultenango"},
    {"ID":"103","CODE":"CHS","LABEL":"Chenalho"},
    {"ID":"104","CODE":"CHS","LABEL":"Chiapa de Corzo"},
    {"ID":"105","CODE":"CHS","LABEL":"Chiapilla"},
    {"ID":"106","CODE":"CHS","LABEL":"Chicoasen"},
    {"ID":"107","CODE":"CHS","LABEL":"Chicomuselo"},
    {"ID":"108","CODE":"CHS","LABEL":"Chilon"},
    {"ID":"109","CODE":"CHS","LABEL":"Cintalapa"},
    {"ID":"110","CODE":"CHS","LABEL":"Coapilla"},
    {"ID":"111","CODE":"CHS","LABEL":"Comitan de Dominguez"},
    {"ID":"112","CODE":"CHS","LABEL":"Copainala"},
    {"ID":"113","CODE":"CHS","LABEL":"El Bosque"},
    {"ID":"114","CODE":"CHS","LABEL":"El Parral"},
    {"ID":"115","CODE":"CHS","LABEL":"El Porvenir"},
    {"ID":"116","CODE":"CHS","LABEL":"Emiliano Zapata"},
    {"ID":"117","CODE":"CHS","LABEL":"Escuintla"},
    {"ID":"118","CODE":"CHS","LABEL":"Francisco Leon"},
    {"ID":"119","CODE":"CHS","LABEL":"Frontera Comalapa"},
    {"ID":"120","CODE":"CHS","LABEL":"Frontera Hidalgo"},
    {"ID":"121","CODE":"CHS","LABEL":"Huehuetan"},
    {"ID":"122","CODE":"CHS","LABEL":"Huitiupan"},
    {"ID":"123","CODE":"CHS","LABEL":"Huixtla"},
    {"ID":"124","CODE":"CHS","LABEL":"Huixtan"},
    {"ID":"125","CODE":"CHS","LABEL":"Ixhuatan"},
    {"ID":"126","CODE":"CHS","LABEL":"Ixtacomitan"},
    {"ID":"127","CODE":"CHS","LABEL":"Ixtapa"},
    {"ID":"128","CODE":"CHS","LABEL":"Ixtapangajoya"},
    {"ID":"129","CODE":"CHS","LABEL":"Jiquipilas"},
    {"ID":"130","CODE":"CHS","LABEL":"Jitotol"},
    {"ID":"131","CODE":"CHS","LABEL":"Juarez"},
    {"ID":"132","CODE":"CHS","LABEL":"La Concordia"},
    {"ID":"133","CODE":"CHS","LABEL":"La Grandeza"},
    {"ID":"134","CODE":"CHS","LABEL":"La Independencia"},
    {"ID":"135","CODE":"CHS","LABEL":"La Libertad"},
    {"ID":"136","CODE":"CHS","LABEL":"La Trinitaria"},
    {"ID":"137","CODE":"CHS","LABEL":"Larrainzar"},
    {"ID":"138","CODE":"CHS","LABEL":"Las Margaritas"},
    {"ID":"139","CODE":"CHS","LABEL":"Las Rosas"},
    {"ID":"140","CODE":"CHS","LABEL":"Mapastepec"},
    {"ID":"141","CODE":"CHS","LABEL":"Maravilla Tenejapa"},
    {"ID":"142","CODE":"CHS","LABEL":"Marques de Comillas"},
    {"ID":"143","CODE":"CHS","LABEL":"Mazapa de Madero"},
    {"ID":"144","CODE":"CHS","LABEL":"Mazatan"},
    {"ID":"145","CODE":"CHS","LABEL":"Metapa"},
    {"ID":"146","CODE":"CHS","LABEL":"Mezcalapa"},
    {"ID":"147","CODE":"CHS","LABEL":"Mitontic"},
    {"ID":"148","CODE":"CHS","LABEL":"Montecristo de Guerrero"},
    {"ID":"149","CODE":"CHS","LABEL":"Motozintla"},
    {"ID":"150","CODE":"CHS","LABEL":"Nicolas Ruiz"},
    {"ID":"151","CODE":"CHS","LABEL":"Ocosingo"},
    {"ID":"152","CODE":"CHS","LABEL":"Ocotepec"},
    {"ID":"153","CODE":"CHS","LABEL":"Ocozocoautla de Espinosa"},
    {"ID":"154","CODE":"CHS","LABEL":"Ostuacan"},
    {"ID":"155","CODE":"CHS","LABEL":"Osumacinta"},
    {"ID":"156","CODE":"CHS","LABEL":"Oxchuc"},
    {"ID":"157","CODE":"CHS","LABEL":"Palenque"},
    {"ID":"158","CODE":"CHS","LABEL":"Pantelho"},
    {"ID":"159","CODE":"CHS","LABEL":"Pantepec"},
    {"ID":"160","CODE":"CHS","LABEL":"Pichucalco"},
    {"ID":"161","CODE":"CHS","LABEL":"Pijijiapan"},
    {"ID":"162","CODE":"CHS","LABEL":"Pueblo Nuevo Solistahuacan"},
    {"ID":"163","CODE":"CHS","LABEL":"Rayon"},
    {"ID":"164","CODE":"CHS","LABEL":"Reforma"},
    {"ID":"165","CODE":"CHS","LABEL":"Rincon Chamula San Pedro"},
    {"ID":"166","CODE":"CHS","LABEL":"Sabanilla"},
    {"ID":"167","CODE":"CHS","LABEL":"Salto de Agua"},
    {"ID":"168","CODE":"CHS","LABEL":"San Andres Duraznal"},
    {"ID":"169","CODE":"CHS","LABEL":"San Cristobal de las Casas"},
    {"ID":"170","CODE":"CHS","LABEL":"San Fernando"},
    {"ID":"171","CODE":"CHS","LABEL":"San Juan Cancuc"},
    {"ID":"172","CODE":"CHS","LABEL":"San Lucas"},
    {"ID":"173","CODE":"CHS","LABEL":"Santiago el Pinar"},
    {"ID":"174","CODE":"CHS","LABEL":"Siltepec"},
    {"ID":"175","CODE":"CHS","LABEL":"Simojovel"},
    {"ID":"176","CODE":"CHS","LABEL":"Sitala"},
    {"ID":"177","CODE":"CHS","LABEL":"Socoltenango"},
    {"ID":"178","CODE":"CHS","LABEL":"Solosuchiapa"},
    {"ID":"179","CODE":"CHS","LABEL":"Soyalo"},
    {"ID":"180","CODE":"CHS","LABEL":"Suchiapa"},
    {"ID":"181","CODE":"CHS","LABEL":"Suchiate"},
    {"ID":"182","CODE":"CHS","LABEL":"Sunuapa"},
    {"ID":"183","CODE":"CHS","LABEL":"Tapachula"},
    {"ID":"184","CODE":"CHS","LABEL":"Tapalapa"},
    {"ID":"185","CODE":"CHS","LABEL":"Tapilula"},
    {"ID":"186","CODE":"CHS","LABEL":"Tecpatan"},
    {"ID":"187","CODE":"CHS","LABEL":"Tenejapa"},
    {"ID":"188","CODE":"CHS","LABEL":"Teopisca"},
    {"ID":"189","CODE":"CHS","LABEL":"Tila"},
    {"ID":"190","CODE":"CHS","LABEL":"Tonala"},
    {"ID":"191","CODE":"CHS","LABEL":"Totolapa"},
    {"ID":"192","CODE":"CHS","LABEL":"Tumbala"},
    {"ID":"193","CODE":"CHS","LABEL":"Tuxtla Chico"},
    {"ID":"194","CODE":"CHS","LABEL":"Tuxtla Gutierrez"},
    {"ID":"195","CODE":"CHS","LABEL":"Tuzantan"},
    {"ID":"196","CODE":"CHS","LABEL":"Tzimol"},
    {"ID":"197","CODE":"CHS","LABEL":"Union Juarez"},
    {"ID":"198","CODE":"CHS","LABEL":"Venustiano Carranza"},
    {"ID":"199","CODE":"CHS","LABEL":"Villa Comaltitlan"},
    {"ID":"200","CODE":"CHS","LABEL":"Villa Corzo"},
    {"ID":"201","CODE":"CHS","LABEL":"Villaflores"},
    {"ID":"202","CODE":"CHS","LABEL":"Yajalon"},
    {"ID":"203","CODE":"CHS","LABEL":"Zinacantan"},
    {"ID":"204","CODE":"CHI","LABEL":"Ahumada"},
    {"ID":"205","CODE":"CHI","LABEL":"Aldama"},
    {"ID":"206","CODE":"CHI","LABEL":"Allende"},
    {"ID":"207","CODE":"CHI","LABEL":"Aquiles Serdan"},
    {"ID":"208","CODE":"CHI","LABEL":"Ascension"},
    {"ID":"209","CODE":"CHI","LABEL":"Bachiniva"},
    {"ID":"210","CODE":"CHI","LABEL":"Balleza"},
    {"ID":"211","CODE":"CHI","LABEL":"Batopilas de Manuel Gomez Morin"},
    {"ID":"212","CODE":"CHI","LABEL":"Bocoyna"},
    {"ID":"213","CODE":"CHI","LABEL":"Buenaventura"},
    {"ID":"214","CODE":"CHI","LABEL":"Camargo"},
    {"ID":"215","CODE":"CHI","LABEL":"Carichi"},
    {"ID":"216","CODE":"CHI","LABEL":"Casas Grandes"},
    {"ID":"217","CODE":"CHI","LABEL":"Chihuahua"},
    {"ID":"218","CODE":"CHI","LABEL":"Chinipas"},
    {"ID":"219","CODE":"CHI","LABEL":"Coronado"},
    {"ID":"220","CODE":"CHI","LABEL":"Coyame del Sotol"},
    {"ID":"221","CODE":"CHI","LABEL":"Cuauhtemoc"},
    {"ID":"222","CODE":"CHI","LABEL":"Cusihuiriachi"},
    {"ID":"223","CODE":"CHI","LABEL":"Delicias"},
    {"ID":"224","CODE":"CHI","LABEL":"Dr. Belisario Dominguez"},
    {"ID":"225","CODE":"CHI","LABEL":"El Tule"},
    {"ID":"226","CODE":"CHI","LABEL":"Galeana"},
    {"ID":"227","CODE":"CHI","LABEL":"Gran Morelos"},
    {"ID":"228","CODE":"CHI","LABEL":"Guachochi"},
    {"ID":"229","CODE":"CHI","LABEL":"Guadalupe y Calvo"},
    {"ID":"230","CODE":"CHI","LABEL":"Guadalupe"},
    {"ID":"231","CODE":"CHI","LABEL":"Guazapares"},
    {"ID":"232","CODE":"CHI","LABEL":"Guerrero"},
    {"ID":"233","CODE":"CHI","LABEL":"Gomez Farias"},
    {"ID":"234","CODE":"CHI","LABEL":"Hidalgo del Parral"},
    {"ID":"235","CODE":"CHI","LABEL":"Huejotitan"},
    {"ID":"236","CODE":"CHI","LABEL":"Ignacio Zaragoza"},
    {"ID":"237","CODE":"CHI","LABEL":"Janos"},
    {"ID":"238","CODE":"CHI","LABEL":"Jimenez"},
    {"ID":"239","CODE":"CHI","LABEL":"Julimes"},
    {"ID":"240","CODE":"CHI","LABEL":"Juarez"},
    {"ID":"241","CODE":"CHI","LABEL":"La Cruz"},
    {"ID":"242","CODE":"CHI","LABEL":"Lopez"},
    {"ID":"243","CODE":"CHI","LABEL":"Madera"},
    {"ID":"244","CODE":"CHI","LABEL":"Maguarichi"},
    {"ID":"245","CODE":"CHI","LABEL":"Manuel Benavides"},
    {"ID":"246","CODE":"CHI","LABEL":"Matachi"},
    {"ID":"247","CODE":"CHI","LABEL":"Matamoros"},
    {"ID":"248","CODE":"CHI","LABEL":"Meoqui"},
    {"ID":"249","CODE":"CHI","LABEL":"Morelos"},
    {"ID":"250","CODE":"CHI","LABEL":"Moris"},
    {"ID":"251","CODE":"CHI","LABEL":"Namiquipa"},
    {"ID":"252","CODE":"CHI","LABEL":"Nonoava"},
    {"ID":"253","CODE":"CHI","LABEL":"Nuevo Casas Grandes"},
    {"ID":"254","CODE":"CHI","LABEL":"Ocampo"},
    {"ID":"255","CODE":"CHI","LABEL":"Ojinaga"},
    {"ID":"256","CODE":"CHI","LABEL":"Praxedis G. Guerrero"},
    {"ID":"257","CODE":"CHI","LABEL":"Riva Palacio"},
    {"ID":"258","CODE":"CHI","LABEL":"Rosales"},
    {"ID":"259","CODE":"CHI","LABEL":"Rosario"},
    {"ID":"260","CODE":"CHI","LABEL":"San Francisco de Borja"},
    {"ID":"261","CODE":"CHI","LABEL":"San Francisco de Conchos"},
    {"ID":"262","CODE":"CHI","LABEL":"San Francisco del Oro"},
    {"ID":"263","CODE":"CHI","LABEL":"Santa Barbara"},
    {"ID":"264","CODE":"CHI","LABEL":"Santa Isabel"},
    {"ID":"265","CODE":"CHI","LABEL":"Satevo"},
    {"ID":"266","CODE":"CHI","LABEL":"Saucillo"},
    {"ID":"267","CODE":"CHI","LABEL":"Temosachic"},
    {"ID":"268","CODE":"CHI","LABEL":"Urique"},
    {"ID":"269","CODE":"CHI","LABEL":"Uruachi"},
    {"ID":"270","CODE":"CHI","LABEL":"Valle de Zaragoza"},
    {"ID":"271","CODE":"CDMX","LABEL":"Alvaro Obregon"},
    {"ID":"272","CODE":"CDMX","LABEL":"Azcapotzalco"},
    {"ID":"273","CODE":"CDMX","LABEL":"Benito Juarez"},
    {"ID":"274","CODE":"CDMX","LABEL":"Coyoacan"},
    {"ID":"275","CODE":"CDMX","LABEL":"Cuajimalpa de Morelos"},
    {"ID":"276","CODE":"CDMX","LABEL":"Cuauhtemoc"},
    {"ID":"277","CODE":"CDMX","LABEL":"Gustavo A. Madero"},
    {"ID":"278","CODE":"CDMX","LABEL":"Iztacalco"},
    {"ID":"279","CODE":"CDMX","LABEL":"Iztapalapa"},
    {"ID":"280","CODE":"CDMX","LABEL":"La Magdalena Contreras"},
    {"ID":"281","CODE":"CDMX","LABEL":"Miguel Hidalgo"},
    {"ID":"282","CODE":"CDMX","LABEL":"Milpa Alta"},
    {"ID":"283","CODE":"CDMX","LABEL":"Tlalpan"},
    {"ID":"284","CODE":"CDMX","LABEL":"Tlahuac"},
    {"ID":"285","CODE":"CDMX","LABEL":"Venustiano Carranza"},
    {"ID":"286","CODE":"CDMX","LABEL":"Xochimilco"},
    {"ID":"287","CODE":"DGO","LABEL":"Canatlan"},
    {"ID":"288","CODE":"DGO","LABEL":"Canelas"},
    {"ID":"289","CODE":"DGO","LABEL":"Coneto de Comonfort"},
    {"ID":"290","CODE":"DGO","LABEL":"Cuencame"},
    {"ID":"291","CODE":"DGO","LABEL":"Durango"},
    {"ID":"292","CODE":"DGO","LABEL":"El Oro"},
    {"ID":"293","CODE":"DGO","LABEL":"General Simon Bolivar"},
    {"ID":"294","CODE":"DGO","LABEL":"Gomez Palacio"},
    {"ID":"295","CODE":"DGO","LABEL":"Guadalupe Victoria"},
    {"ID":"296","CODE":"DGO","LABEL":"Guanacevi"},
    {"ID":"297","CODE":"DGO","LABEL":"Hidalgo"},
    {"ID":"298","CODE":"DGO","LABEL":"Inde"},
    {"ID":"299","CODE":"DGO","LABEL":"Lerdo"},
    {"ID":"300","CODE":"DGO","LABEL":"Mapimi"},
    {"ID":"301","CODE":"DGO","LABEL":"Mezquital"},
    {"ID":"302","CODE":"DGO","LABEL":"Nazas"},
    {"ID":"303","CODE":"DGO","LABEL":"Nombre de Dios"},
    {"ID":"304","CODE":"DGO","LABEL":"Nuevo Ideal"},
    {"ID":"305","CODE":"DGO","LABEL":"Ocampo"},
    {"ID":"306","CODE":"DGO","LABEL":"Otaez"},
    {"ID":"307","CODE":"DGO","LABEL":"Panuco de Coronado"},
    {"ID":"308","CODE":"DGO","LABEL":"Penon Blanco"},
    {"ID":"309","CODE":"DGO","LABEL":"Poanas"},
    {"ID":"310","CODE":"DGO","LABEL":"Pueblo Nuevo"},
    {"ID":"311","CODE":"DGO","LABEL":"Rodeo"},
    {"ID":"312","CODE":"DGO","LABEL":"San Bernardo"},
    {"ID":"313","CODE":"DGO","LABEL":"San Dimas"},
    {"ID":"314","CODE":"DGO","LABEL":"San Juan de Guadalupe"},
    {"ID":"315","CODE":"DGO","LABEL":"San Juan del Rio"},
    {"ID":"316","CODE":"DGO","LABEL":"San Luis del Cordero"},
    {"ID":"317","CODE":"DGO","LABEL":"San Pedro del Gallo"},
    {"ID":"318","CODE":"DGO","LABEL":"Santa Clara"},
    {"ID":"319","CODE":"DGO","LABEL":"Santiago Papasquiaro"},
    {"ID":"320","CODE":"DGO","LABEL":"Suchil"},
    {"ID":"321","CODE":"DGO","LABEL":"Tamazula"},
    {"ID":"322","CODE":"DGO","LABEL":"Tepehuanes"},
    {"ID":"323","CODE":"DGO","LABEL":"Tlahualilo"},
    {"ID":"324","CODE":"DGO","LABEL":"Topia"},
    {"ID":"325","CODE":"DGO","LABEL":"Vicente Guerrero"},
    {"ID":"326","CODE":"GTO","LABEL":"Abasolo"},
    {"ID":"327","CODE":"GTO","LABEL":"Acambaro"},
    {"ID":"328","CODE":"GTO","LABEL":"Apaseo el Alto"},
    {"ID":"329","CODE":"GTO","LABEL":"Apaseo el Grande"},
    {"ID":"330","CODE":"GTO","LABEL":"Atarjea"},
    {"ID":"331","CODE":"GTO","LABEL":"Celaya"},
    {"ID":"332","CODE":"GTO","LABEL":"Comonfort"},
    {"ID":"333","CODE":"GTO","LABEL":"Coroneo"},
    {"ID":"334","CODE":"GTO","LABEL":"Cortazar"},
    {"ID":"335","CODE":"GTO","LABEL":"Cueramaro"},
    {"ID":"336","CODE":"GTO","LABEL":"Doctor Mora"},
    {"ID":"337","CODE":"GTO","LABEL":"Dolores Hidalgo Cuna de la Independencia Nacional"},
    {"ID":"338","CODE":"GTO","LABEL":"Guanajuato"},
    {"ID":"339","CODE":"GTO","LABEL":"Huanimaro"},
    {"ID":"340","CODE":"GTO","LABEL":"Irapuato"},
    {"ID":"341","CODE":"GTO","LABEL":"Jaral del Progreso"},
    {"ID":"342","CODE":"GTO","LABEL":"Jerecuaro"},
    {"ID":"343","CODE":"GTO","LABEL":"Leon"},
    {"ID":"344","CODE":"GTO","LABEL":"Manuel Doblado"},
    {"ID":"345","CODE":"GTO","LABEL":"Moroleon"},
    {"ID":"346","CODE":"GTO","LABEL":"Ocampo"},
    {"ID":"347","CODE":"GTO","LABEL":"Penjamo"},
    {"ID":"348","CODE":"GTO","LABEL":"Pueblo Nuevo"},
    {"ID":"349","CODE":"GTO","LABEL":"Purisima del Rincon"},
    {"ID":"350","CODE":"GTO","LABEL":"Romita"},
    {"ID":"351","CODE":"GTO","LABEL":"Salamanca"},
    {"ID":"352","CODE":"GTO","LABEL":"Salvatierra"},
    {"ID":"353","CODE":"GTO","LABEL":"San Diego de la Union"},
    {"ID":"354","CODE":"GTO","LABEL":"San Felipe"},
    {"ID":"355","CODE":"GTO","LABEL":"San Francisco del Rincon"},
    {"ID":"356","CODE":"GTO","LABEL":"San Jose Iturbide"},
    {"ID":"357","CODE":"GTO","LABEL":"San Luis de la Paz"},
    {"ID":"358","CODE":"GTO","LABEL":"San Miguel de Allende"},
    {"ID":"359","CODE":"GTO","LABEL":"Santa Catarina"},
    {"ID":"360","CODE":"GTO","LABEL":"Santa Cruz de Juventino Rosas"},
    {"ID":"361","CODE":"GTO","LABEL":"Santiago Maravatio"},
    {"ID":"362","CODE":"GTO","LABEL":"Silao de la Victoria"},
    {"ID":"363","CODE":"GTO","LABEL":"Tarandacuao"},
    {"ID":"364","CODE":"GTO","LABEL":"Tarimoro"},
    {"ID":"365","CODE":"GTO","LABEL":"Tierra Blanca"},
    {"ID":"366","CODE":"GTO","LABEL":"Uriangato"},
    {"ID":"367","CODE":"GTO","LABEL":"Valle de Santiago"},
    {"ID":"368","CODE":"GTO","LABEL":"Victoria"},
    {"ID":"369","CODE":"GTO","LABEL":"Villagran"},
    {"ID":"370","CODE":"GTO","LABEL":"Xichu"},
    {"ID":"371","CODE":"GTO","LABEL":"Yuriria"},
    {"ID":"372","CODE":"GRO","LABEL":"Acapulco de Juarez"},
    {"ID":"373","CODE":"GRO","LABEL":"Acatepec"},
    {"ID":"374","CODE":"GRO","LABEL":"Ahuacuotzingo"},
    {"ID":"375","CODE":"GRO","LABEL":"Ajuchitlan del Progreso"},
    {"ID":"376","CODE":"GRO","LABEL":"Alcozauca de Guerrero"},
    {"ID":"377","CODE":"GRO","LABEL":"Alpoyeca"},
    {"ID":"378","CODE":"GRO","LABEL":"Apaxtla"},
    {"ID":"379","CODE":"GRO","LABEL":"Arcelia"},
    {"ID":"380","CODE":"GRO","LABEL":"Atenango del Rio"},
    {"ID":"381","CODE":"GRO","LABEL":"Atlamajalcingo del Monte"},
    {"ID":"382","CODE":"GRO","LABEL":"Atlixtac"},
    {"ID":"383","CODE":"GRO","LABEL":"Atoyac de Alvarez"},
    {"ID":"384","CODE":"GRO","LABEL":"Ayutla de los Libres"},
    {"ID":"385","CODE":"GRO","LABEL":"Azoyu"},
    {"ID":"386","CODE":"GRO","LABEL":"Benito Juarez"},
    {"ID":"387","CODE":"GRO","LABEL":"Buenavista de Cuellar"},
    {"ID":"388","CODE":"GRO","LABEL":"Chilapa de Alvarez"},
    {"ID":"389","CODE":"GRO","LABEL":"Chilpancingo de los Bravo"},
    {"ID":"390","CODE":"GRO","LABEL":"Coahuayutla de Jose Maria Izazaga"},
    {"ID":"391","CODE":"GRO","LABEL":"Cochoapa el Grande"},
    {"ID":"392","CODE":"GRO","LABEL":"Cocula"},
    {"ID":"393","CODE":"GRO","LABEL":"Copala"},
    {"ID":"394","CODE":"GRO","LABEL":"Copalillo"},
    {"ID":"395","CODE":"GRO","LABEL":"Copanatoyac"},
    {"ID":"396","CODE":"GRO","LABEL":"Coyuca de Benitez"},
    {"ID":"397","CODE":"GRO","LABEL":"Coyuca de Catalan"},
    {"ID":"398","CODE":"GRO","LABEL":"Cuajinicuilapa"},
    {"ID":"399","CODE":"GRO","LABEL":"Cualac"},
    {"ID":"400","CODE":"GRO","LABEL":"Cuautepec"},
    {"ID":"401","CODE":"GRO","LABEL":"Cuetzala del Progreso"},
    {"ID":"402","CODE":"GRO","LABEL":"Cutzamala de Pinzon"},
    {"ID":"403","CODE":"GRO","LABEL":"Eduardo Neri"},
    {"ID":"404","CODE":"GRO","LABEL":"Florencio Villarreal"},
    {"ID":"405","CODE":"GRO","LABEL":"General Canuto A. Neri"},
    {"ID":"406","CODE":"GRO","LABEL":"General Heliodoro Castillo"},
    {"ID":"407","CODE":"GRO","LABEL":"Huamuxtitlan"},
    {"ID":"408","CODE":"GRO","LABEL":"Huitzuco de los Figueroa"},
    {"ID":"409","CODE":"GRO","LABEL":"Iguala de la Independencia"},
    {"ID":"410","CODE":"GRO","LABEL":"Igualapa"},
    {"ID":"411","CODE":"GRO","LABEL":"Iliatenco"},
    {"ID":"412","CODE":"GRO","LABEL":"Ixcateopan de Cuauhtemoc"},
    {"ID":"413","CODE":"GRO","LABEL":"Jose Joaquin de Herrera"},
    {"ID":"414","CODE":"GRO","LABEL":"Juan R. Escudero"},
    {"ID":"415","CODE":"GRO","LABEL":"Juchitan"},
    {"ID":"416","CODE":"GRO","LABEL":"La Union de Isidoro Montes de Oca"},
    {"ID":"417","CODE":"GRO","LABEL":"Leonardo Bravo"},
    {"ID":"418","CODE":"GRO","LABEL":"Malinaltepec"},
    {"ID":"419","CODE":"GRO","LABEL":"Marquelia"},
    {"ID":"420","CODE":"GRO","LABEL":"Martir de Cuilapan"},
    {"ID":"421","CODE":"GRO","LABEL":"Metlatonoc"},
    {"ID":"422","CODE":"GRO","LABEL":"Mochitlan"},
    {"ID":"423","CODE":"GRO","LABEL":"Olinala"},
    {"ID":"424","CODE":"GRO","LABEL":"Ometepec"},
    {"ID":"425","CODE":"GRO","LABEL":"Pedro Ascencio Alquisiras"},
    {"ID":"426","CODE":"GRO","LABEL":"Petatlan"},
    {"ID":"427","CODE":"GRO","LABEL":"Pilcaya"},
    {"ID":"428","CODE":"GRO","LABEL":"Pungarabato"},
    {"ID":"429","CODE":"GRO","LABEL":"Quechultenango"},
    {"ID":"430","CODE":"GRO","LABEL":"San Luis Acatlan"},
    {"ID":"431","CODE":"GRO","LABEL":"San Marcos"},
    {"ID":"432","CODE":"GRO","LABEL":"San Miguel Totolapan"},
    {"ID":"433","CODE":"GRO","LABEL":"Taxco de Alarcon"},
    {"ID":"434","CODE":"GRO","LABEL":"Tecoanapa"},
    {"ID":"435","CODE":"GRO","LABEL":"Tecpan de Galeana"},
    {"ID":"436","CODE":"GRO","LABEL":"Teloloapan"},
    {"ID":"437","CODE":"GRO","LABEL":"Tepecoacuilco de Trujano"},
    {"ID":"438","CODE":"GRO","LABEL":"Tetipac"},
    {"ID":"439","CODE":"GRO","LABEL":"Tixtla de Guerrero"},
    {"ID":"440","CODE":"GRO","LABEL":"Tlacoachistlahuaca"},
    {"ID":"441","CODE":"GRO","LABEL":"Tlacoapa"},
    {"ID":"442","CODE":"GRO","LABEL":"Tlalchapa"},
    {"ID":"443","CODE":"GRO","LABEL":"Tlalixtaquilla de Maldonado"},
    {"ID":"444","CODE":"GRO","LABEL":"Tlapa de Comonfort"},
    {"ID":"445","CODE":"GRO","LABEL":"Tlapehuala"},
    {"ID":"446","CODE":"GRO","LABEL":"Xalpatlahuac"},
    {"ID":"447","CODE":"GRO","LABEL":"Xochihuehuetlan"},
    {"ID":"448","CODE":"GRO","LABEL":"Xochistlahuaca"},
    {"ID":"449","CODE":"GRO","LABEL":"Zapotitlan Tablas"},
    {"ID":"450","CODE":"GRO","LABEL":"Zihuatanejo de Azueta"},
    {"ID":"451","CODE":"GRO","LABEL":"Zirandaro"},
    {"ID":"452","CODE":"GRO","LABEL":"Zitlala"},
    {"ID":"453","CODE":"HGO","LABEL":"Acatlan"},
    {"ID":"454","CODE":"HGO","LABEL":"Acaxochitlan"},
    {"ID":"455","CODE":"HGO","LABEL":"Actopan"},
    {"ID":"456","CODE":"HGO","LABEL":"Agua Blanca de Iturbide"},
    {"ID":"457","CODE":"HGO","LABEL":"Ajacuba"},
    {"ID":"458","CODE":"HGO","LABEL":"Alfajayucan"},
    {"ID":"459","CODE":"HGO","LABEL":"Almoloya"},
    {"ID":"460","CODE":"HGO","LABEL":"Apan"},
    {"ID":"461","CODE":"HGO","LABEL":"Atitalaquia"},
    {"ID":"462","CODE":"HGO","LABEL":"Atlapexco"},
    {"ID":"463","CODE":"HGO","LABEL":"Atotonilco de Tula"},
    {"ID":"464","CODE":"HGO","LABEL":"Atotonilco el Grande"},
    {"ID":"465","CODE":"HGO","LABEL":"Calnali"},
    {"ID":"466","CODE":"HGO","LABEL":"Cardonal"},
    {"ID":"467","CODE":"HGO","LABEL":"Chapantongo"},
    {"ID":"468","CODE":"HGO","LABEL":"Chapulhuacan"},
    {"ID":"469","CODE":"HGO","LABEL":"Chilcuautla"},
    {"ID":"470","CODE":"HGO","LABEL":"Cuautepec de Hinojosa"},
    {"ID":"471","CODE":"HGO","LABEL":"El Arenal"},
    {"ID":"472","CODE":"HGO","LABEL":"Eloxochitlan"},
    {"ID":"473","CODE":"HGO","LABEL":"Emiliano Zapata"},
    {"ID":"474","CODE":"HGO","LABEL":"Epazoyucan"},
    {"ID":"475","CODE":"HGO","LABEL":"Francisco I. Madero"},
    {"ID":"476","CODE":"HGO","LABEL":"Huasca de Ocampo"},
    {"ID":"477","CODE":"HGO","LABEL":"Huautla"},
    {"ID":"478","CODE":"HGO","LABEL":"Huazalingo"},
    {"ID":"479","CODE":"HGO","LABEL":"Huehuetla"},
    {"ID":"480","CODE":"HGO","LABEL":"Huejutla de Reyes"},
    {"ID":"481","CODE":"HGO","LABEL":"Huichapan"},
    {"ID":"482","CODE":"HGO","LABEL":"Ixmiquilpan"},
    {"ID":"483","CODE":"HGO","LABEL":"Jacala de Ledezma"},
    {"ID":"484","CODE":"HGO","LABEL":"Jaltocan"},
    {"ID":"485","CODE":"HGO","LABEL":"Juarez Hidalgo"},
    {"ID":"486","CODE":"HGO","LABEL":"La Mision"},
    {"ID":"487","CODE":"HGO","LABEL":"Lolotla"},
    {"ID":"488","CODE":"HGO","LABEL":"Metepec"},
    {"ID":"489","CODE":"HGO","LABEL":"Metztitlan"},
    {"ID":"490","CODE":"HGO","LABEL":"Mineral de la Reforma"},
    {"ID":"491","CODE":"HGO","LABEL":"Mineral del Chico"},
    {"ID":"492","CODE":"HGO","LABEL":"Mineral del Monte"},
    {"ID":"493","CODE":"HGO","LABEL":"Mixquiahuala de Juarez"},
    {"ID":"494","CODE":"HGO","LABEL":"Molango de Escamilla"},
    {"ID":"495","CODE":"HGO","LABEL":"Nicolas Flores"},
    {"ID":"496","CODE":"HGO","LABEL":"Nopala de Villagran"},
    {"ID":"497","CODE":"HGO","LABEL":"Omitlan de Juarez"},
    {"ID":"498","CODE":"HGO","LABEL":"Pachuca de Soto"},
    {"ID":"499","CODE":"HGO","LABEL":"Pacula"},
    {"ID":"500","CODE":"HGO","LABEL":"Pisaflores"},
    {"ID":"501","CODE":"HGO","LABEL":"Progreso de Obregon"},
    {"ID":"502","CODE":"HGO","LABEL":"San Agustin Metzquititlan"},
    {"ID":"503","CODE":"HGO","LABEL":"San Agustin Tlaxiaca"},
    {"ID":"504","CODE":"HGO","LABEL":"San Bartolo Tutotepec"},
    {"ID":"505","CODE":"HGO","LABEL":"San Felipe Orizatlan"},
    {"ID":"506","CODE":"HGO","LABEL":"San Salvador"},
    {"ID":"507","CODE":"HGO","LABEL":"Santiago Tulantepec de Lugo Guerrero"},
    {"ID":"508","CODE":"HGO","LABEL":"Santiago de Anaya"},
    {"ID":"509","CODE":"HGO","LABEL":"Singuilucan"},
    {"ID":"510","CODE":"HGO","LABEL":"Tasquillo"},
    {"ID":"511","CODE":"HGO","LABEL":"Tecozautla"},
    {"ID":"512","CODE":"HGO","LABEL":"Tenango de Doria"},
    {"ID":"513","CODE":"HGO","LABEL":"Tepeapulco"},
    {"ID":"514","CODE":"HGO","LABEL":"Tepehuacan de Guerrero"},
    {"ID":"515","CODE":"HGO","LABEL":"Tepeji del Rio de Ocampo"},
    {"ID":"516","CODE":"HGO","LABEL":"Tepetitlan"},
    {"ID":"517","CODE":"HGO","LABEL":"Tetepango"},
    {"ID":"518","CODE":"HGO","LABEL":"Tezontepec de Aldama"},
    {"ID":"519","CODE":"HGO","LABEL":"Tianguistengo"},
    {"ID":"520","CODE":"HGO","LABEL":"Tizayuca"},
    {"ID":"521","CODE":"HGO","LABEL":"Tlahuelilpan"},
    {"ID":"522","CODE":"HGO","LABEL":"Tlahuiltepa"},
    {"ID":"523","CODE":"HGO","LABEL":"Tlanalapa"},
    {"ID":"524","CODE":"HGO","LABEL":"Tlanchinol"},
    {"ID":"525","CODE":"HGO","LABEL":"Tlaxcoapan"},
    {"ID":"526","CODE":"HGO","LABEL":"Tolcayuca"},
    {"ID":"527","CODE":"HGO","LABEL":"Tula de Allende"},
    {"ID":"528","CODE":"HGO","LABEL":"Tulancingo de Bravo"},
    {"ID":"529","CODE":"HGO","LABEL":"Villa de Tezontepec"},
    {"ID":"530","CODE":"HGO","LABEL":"Xochiatipan"},
    {"ID":"531","CODE":"HGO","LABEL":"Xochicoatlan"},
    {"ID":"532","CODE":"HGO","LABEL":"Yahualica"},
    {"ID":"533","CODE":"HGO","LABEL":"Zacualtipan de Angeles"},
    {"ID":"534","CODE":"HGO","LABEL":"Zapotlan de Juarez"},
    {"ID":"535","CODE":"HGO","LABEL":"Zempoala"},
    {"ID":"536","CODE":"HGO","LABEL":"Zimapan"},
    {"ID":"537","CODE":"JAL","LABEL":"Acatic"},
    {"ID":"538","CODE":"JAL","LABEL":"Acatlan de Juarez"},
    {"ID":"539","CODE":"JAL","LABEL":"Ahualulco de Mercado"},
    {"ID":"540","CODE":"JAL","LABEL":"Amacueca"},
    {"ID":"541","CODE":"JAL","LABEL":"Amatitan"},
    {"ID":"542","CODE":"JAL","LABEL":"Ameca"},
    {"ID":"543","CODE":"JAL","LABEL":"Arandas"},
    {"ID":"544","CODE":"JAL","LABEL":"Atemajac de Brizuela"},
    {"ID":"545","CODE":"JAL","LABEL":"Atengo"},
    {"ID":"546","CODE":"JAL","LABEL":"Atenguillo"},
    {"ID":"547","CODE":"JAL","LABEL":"Atotonilco el Alto"},
    {"ID":"548","CODE":"JAL","LABEL":"Atoyac"},
    {"ID":"549","CODE":"JAL","LABEL":"Autlan de Navarro"},
    {"ID":"550","CODE":"JAL","LABEL":"Ayotlan"},
    {"ID":"551","CODE":"JAL","LABEL":"Ayutla"},
    {"ID":"552","CODE":"JAL","LABEL":"Bolanos"},
    {"ID":"553","CODE":"JAL","LABEL":"Cabo Corrientes"},
    {"ID":"554","CODE":"JAL","LABEL":"Canadas de Obregon"},
    {"ID":"555","CODE":"JAL","LABEL":"Casimiro Castillo"},
    {"ID":"556","CODE":"JAL","LABEL":"Chapala"},
    {"ID":"557","CODE":"JAL","LABEL":"Chimaltitan"},
    {"ID":"558","CODE":"JAL","LABEL":"Chiquilistlan"},
    {"ID":"559","CODE":"JAL","LABEL":"Cihuatlan"},
    {"ID":"560","CODE":"JAL","LABEL":"Cocula"},
    {"ID":"561","CODE":"JAL","LABEL":"Colotlan"},
    {"ID":"562","CODE":"JAL","LABEL":"Concepcion de Buenos Aires"},
    {"ID":"563","CODE":"JAL","LABEL":"Cuautitlan de Garcia Barragan"},
    {"ID":"564","CODE":"JAL","LABEL":"Cuautla"},
    {"ID":"565","CODE":"JAL","LABEL":"Cuquio"},
    {"ID":"566","CODE":"JAL","LABEL":"Degollado"},
    {"ID":"567","CODE":"JAL","LABEL":"Ejutla"},
    {"ID":"568","CODE":"JAL","LABEL":"El Arenal"},
    {"ID":"569","CODE":"JAL","LABEL":"El Grullo"},
    {"ID":"570","CODE":"JAL","LABEL":"El Limon"},
    {"ID":"571","CODE":"JAL","LABEL":"El Salto"},
    {"ID":"572","CODE":"JAL","LABEL":"Encarnacion de Diaz"},
    {"ID":"573","CODE":"JAL","LABEL":"Etzatlan"},
    {"ID":"574","CODE":"JAL","LABEL":"Gomez Farias"},
    {"ID":"575","CODE":"JAL","LABEL":"Guachinango"},
    {"ID":"576","CODE":"JAL","LABEL":"Guadalajara"},
    {"ID":"577","CODE":"JAL","LABEL":"Hostotipaquillo"},
    {"ID":"578","CODE":"JAL","LABEL":"Huejucar"},
    {"ID":"579","CODE":"JAL","LABEL":"Huejuquilla el Alto"},
    {"ID":"580","CODE":"JAL","LABEL":"Ixtlahuacan de los Membrillos"},
    {"ID":"581","CODE":"JAL","LABEL":"Ixtlahuacan del Rio"},
    {"ID":"582","CODE":"JAL","LABEL":"Jalostotitlan"},
    {"ID":"583","CODE":"JAL","LABEL":"Jamay"},
    {"ID":"584","CODE":"JAL","LABEL":"Jesus Maria"},
    {"ID":"585","CODE":"JAL","LABEL":"Jilotlan de los Dolores"},
    {"ID":"586","CODE":"JAL","LABEL":"Jocotepec"},
    {"ID":"587","CODE":"JAL","LABEL":"Juanacatlan"},
    {"ID":"588","CODE":"JAL","LABEL":"Juchitlan"},
    {"ID":"589","CODE":"JAL","LABEL":"La Barca"},
    {"ID":"590","CODE":"JAL","LABEL":"La Huerta"},
    {"ID":"591","CODE":"JAL","LABEL":"La Manzanilla de la Paz"},
    {"ID":"592","CODE":"JAL","LABEL":"Lagos de Moreno"},
    {"ID":"593","CODE":"JAL","LABEL":"Magdalena"},
    {"ID":"594","CODE":"JAL","LABEL":"Mascota"},
    {"ID":"595","CODE":"JAL","LABEL":"Mazamitla"},
    {"ID":"596","CODE":"JAL","LABEL":"Mexticacan"},
    {"ID":"597","CODE":"JAL","LABEL":"Mezquitic"},
    {"ID":"598","CODE":"JAL","LABEL":"Mixtlan"},
    {"ID":"599","CODE":"JAL","LABEL":"Ocotlan"},
    {"ID":"600","CODE":"JAL","LABEL":"Ojuelos de Jalisco"},
    {"ID":"601","CODE":"JAL","LABEL":"Pihuamo"},
    {"ID":"602","CODE":"JAL","LABEL":"Poncitlan"},
    {"ID":"603","CODE":"JAL","LABEL":"Puerto Vallarta"},
    {"ID":"604","CODE":"JAL","LABEL":"Quitupan"},
    {"ID":"605","CODE":"JAL","LABEL":"San Cristobal de la Barranca"},
    {"ID":"606","CODE":"JAL","LABEL":"San Diego de Alejandria"},
    {"ID":"607","CODE":"JAL","LABEL":"San Gabriel"},
    {"ID":"608","CODE":"JAL","LABEL":"San Ignacio Cerro Gordo"},
    {"ID":"609","CODE":"JAL","LABEL":"San Juan de los Lagos"},
    {"ID":"610","CODE":"JAL","LABEL":"San Juanito de Escobedo"},
    {"ID":"611","CODE":"JAL","LABEL":"San Julian"},
    {"ID":"612","CODE":"JAL","LABEL":"San Marcos"},
    {"ID":"613","CODE":"JAL","LABEL":"San Martin Hidalgo"},
    {"ID":"614","CODE":"JAL","LABEL":"San Martin de Bolanos"},
    {"ID":"615","CODE":"JAL","LABEL":"San Miguel el Alto"},
    {"ID":"616","CODE":"JAL","LABEL":"San Pedro Tlaquepaque"},
    {"ID":"617","CODE":"JAL","LABEL":"San Sebastian del Oeste"},
    {"ID":"618","CODE":"JAL","LABEL":"Santa Maria de los Angeles"},
    {"ID":"619","CODE":"JAL","LABEL":"Santa Maria del Oro"},
    {"ID":"620","CODE":"JAL","LABEL":"Sayula"},
    {"ID":"621","CODE":"JAL","LABEL":"Tala"},
    {"ID":"622","CODE":"JAL","LABEL":"Talpa de Allende"},
    {"ID":"623","CODE":"JAL","LABEL":"Tamazula de Gordiano"},
    {"ID":"624","CODE":"JAL","LABEL":"Tapalpa"},
    {"ID":"625","CODE":"JAL","LABEL":"Tecalitlan"},
    {"ID":"626","CODE":"JAL","LABEL":"Techaluta de Montenegro"},
    {"ID":"627","CODE":"JAL","LABEL":"Tecolotlan"},
    {"ID":"628","CODE":"JAL","LABEL":"Tenamaxtlan"},
    {"ID":"629","CODE":"JAL","LABEL":"Teocaltiche"},
    {"ID":"630","CODE":"JAL","LABEL":"Teocuitatlan de Corona"},
    {"ID":"631","CODE":"JAL","LABEL":"Tepatitlan de Morelos"},
    {"ID":"632","CODE":"JAL","LABEL":"Tequila"},
    {"ID":"633","CODE":"JAL","LABEL":"Teuchitlan"},
    {"ID":"634","CODE":"JAL","LABEL":"Tizapan el Alto"},
    {"ID":"635","CODE":"JAL","LABEL":"Tlajomulco de Zuniga"},
    {"ID":"636","CODE":"JAL","LABEL":"Toliman"},
    {"ID":"637","CODE":"JAL","LABEL":"Tomatlan"},
    {"ID":"638","CODE":"JAL","LABEL":"Tonala"},
    {"ID":"639","CODE":"JAL","LABEL":"Tonaya"},
    {"ID":"640","CODE":"JAL","LABEL":"Tonila"},
    {"ID":"641","CODE":"JAL","LABEL":"Totatiche"},
    {"ID":"642","CODE":"JAL","LABEL":"Tototlan"},
    {"ID":"643","CODE":"JAL","LABEL":"Tuxcacuesco"},
    {"ID":"644","CODE":"JAL","LABEL":"Tuxcueca"},
    {"ID":"645","CODE":"JAL","LABEL":"Tuxpan"},
    {"ID":"646","CODE":"JAL","LABEL":"Union de San Antonio"},
    {"ID":"647","CODE":"JAL","LABEL":"Union de Tula"},
    {"ID":"648","CODE":"JAL","LABEL":"Valle de Guadalupe"},
    {"ID":"649","CODE":"JAL","LABEL":"Valle de Juarez"},
    {"ID":"650","CODE":"JAL","LABEL":"Villa Corona"},
    {"ID":"651","CODE":"JAL","LABEL":"Villa Guerrero"},
    {"ID":"652","CODE":"JAL","LABEL":"Villa Hidalgo"},
    {"ID":"653","CODE":"JAL","LABEL":"Villa Purificacion"},
    {"ID":"654","CODE":"JAL","LABEL":"Yahualica de Gonzalez Gallo"},
    {"ID":"655","CODE":"JAL","LABEL":"Zacoalco de Torres"},
    {"ID":"656","CODE":"JAL","LABEL":"Zapopan"},
    {"ID":"657","CODE":"JAL","LABEL":"Zapotiltic"},
    {"ID":"658","CODE":"JAL","LABEL":"Zapotitlan de Vadillo"},
    {"ID":"659","CODE":"JAL","LABEL":"Zapotlan del Rey"},
    {"ID":"660","CODE":"JAL","LABEL":"Zapotlan el Grande"},
    {"ID":"661","CODE":"JAL","LABEL":"Zapotlanejo"},
    {"ID":"662","CODE":"MEX","LABEL":"Acambay de Ruiz Castaneda"},
    {"ID":"663","CODE":"MEX","LABEL":"Acolman"},
    {"ID":"664","CODE":"MEX","LABEL":"Aculco"},
    {"ID":"665","CODE":"MEX","LABEL":"Almoloya de Alquisiras"},
    {"ID":"666","CODE":"MEX","LABEL":"Almoloya de Juarez"},
    {"ID":"667","CODE":"MEX","LABEL":"Almoloya del Rio"},
    {"ID":"668","CODE":"MEX","LABEL":"Amanalco"},
    {"ID":"669","CODE":"MEX","LABEL":"Amatepec"},
    {"ID":"670","CODE":"MEX","LABEL":"Amecameca"},
    {"ID":"671","CODE":"MEX","LABEL":"Apaxco"},
    {"ID":"672","CODE":"MEX","LABEL":"Atenco"},
    {"ID":"673","CODE":"MEX","LABEL":"Atizapan de Zaragoza"},
    {"ID":"674","CODE":"MEX","LABEL":"Atizapan"},
    {"ID":"675","CODE":"MEX","LABEL":"Atlacomulco"},
    {"ID":"676","CODE":"MEX","LABEL":"Atlautla"},
    {"ID":"677","CODE":"MEX","LABEL":"Axapusco"},
    {"ID":"678","CODE":"MEX","LABEL":"Ayapango"},
    {"ID":"679","CODE":"MEX","LABEL":"Calimaya"},
    {"ID":"680","CODE":"MEX","LABEL":"Capulhuac"},
    {"ID":"681","CODE":"MEX","LABEL":"Chalco"},
    {"ID":"682","CODE":"MEX","LABEL":"Chapa de Mota"},
    {"ID":"683","CODE":"MEX","LABEL":"Chapultepec"},
    {"ID":"684","CODE":"MEX","LABEL":"Chiautla"},
    {"ID":"685","CODE":"MEX","LABEL":"Chicoloapan"},
    {"ID":"686","CODE":"MEX","LABEL":"Chiconcuac"},
    {"ID":"687","CODE":"MEX","LABEL":"Chimalhuacan"},
    {"ID":"688","CODE":"MEX","LABEL":"Coacalco de Berriozabal"},
    {"ID":"689","CODE":"MEX","LABEL":"Coatepec Harinas"},
    {"ID":"690","CODE":"MEX","LABEL":"Cocotitlan"},
    {"ID":"691","CODE":"MEX","LABEL":"Coyotepec"},
    {"ID":"692","CODE":"MEX","LABEL":"Cuautitlan Izcalli"},
    {"ID":"693","CODE":"MEX","LABEL":"Cuautitlan"},
    {"ID":"694","CODE":"MEX","LABEL":"Donato Guerra"},
    {"ID":"695","CODE":"MEX","LABEL":"Ecatepec de Morelos"},
    {"ID":"696","CODE":"MEX","LABEL":"Ecatzingo"},
    {"ID":"697","CODE":"MEX","LABEL":"El Oro"},
    {"ID":"698","CODE":"MEX","LABEL":"Huehuetoca"},
    {"ID":"699","CODE":"MEX","LABEL":"Hueypoxtla"},
    {"ID":"700","CODE":"MEX","LABEL":"Huixquilucan"},
    {"ID":"701","CODE":"MEX","LABEL":"Isidro Fabela"},
    {"ID":"702","CODE":"MEX","LABEL":"Ixtapaluca"},
    {"ID":"703","CODE":"MEX","LABEL":"Ixtapan de la Sal"},
    {"ID":"704","CODE":"MEX","LABEL":"Ixtapan del Oro"},
    {"ID":"705","CODE":"MEX","LABEL":"Ixtlahuaca"},
    {"ID":"706","CODE":"MEX","LABEL":"Jaltenco"},
    {"ID":"707","CODE":"MEX","LABEL":"Jilotepec"},
    {"ID":"708","CODE":"MEX","LABEL":"Jilotzingo"},
    {"ID":"709","CODE":"MEX","LABEL":"Jiquipilco"},
    {"ID":"710","CODE":"MEX","LABEL":"Jocotitlan"},
    {"ID":"711","CODE":"MEX","LABEL":"Joquicingo"},
    {"ID":"712","CODE":"MEX","LABEL":"Juchitepec"},
    {"ID":"713","CODE":"MEX","LABEL":"La Paz"},
    {"ID":"714","CODE":"MEX","LABEL":"Lerma"},
    {"ID":"715","CODE":"MEX","LABEL":"Luvianos"},
    {"ID":"716","CODE":"MEX","LABEL":"Malinalco"},
    {"ID":"717","CODE":"MEX","LABEL":"Melchor Ocampo"},
    {"ID":"718","CODE":"MEX","LABEL":"Metepec"},
    {"ID":"719","CODE":"MEX","LABEL":"Mexicaltzingo"},
    {"ID":"720","CODE":"MEX","LABEL":"Morelos"},
    {"ID":"721","CODE":"MEX","LABEL":"Naucalpan de Juarez"},
    {"ID":"722","CODE":"MEX","LABEL":"Nextlalpan"},
    {"ID":"723","CODE":"MEX","LABEL":"Nezahualcoyotl"},
    {"ID":"724","CODE":"MEX","LABEL":"Nicolas Romero"},
    {"ID":"725","CODE":"MEX","LABEL":"Nopaltepec"},
    {"ID":"726","CODE":"MEX","LABEL":"Ocoyoacac"},
    {"ID":"727","CODE":"MEX","LABEL":"Ocuilan"},
    {"ID":"728","CODE":"MEX","LABEL":"Otumba"},
    {"ID":"729","CODE":"MEX","LABEL":"Otzoloapan"},
    {"ID":"730","CODE":"MEX","LABEL":"Otzolotepec"},
    {"ID":"731","CODE":"MEX","LABEL":"Ozumba"},
    {"ID":"732","CODE":"MEX","LABEL":"Papalotla"},
    {"ID":"733","CODE":"MEX","LABEL":"Polotitlan"},
    {"ID":"734","CODE":"MEX","LABEL":"Rayon"},
    {"ID":"735","CODE":"MEX","LABEL":"San Antonio la Isla"},
    {"ID":"736","CODE":"MEX","LABEL":"San Felipe del Progreso"},
    {"ID":"737","CODE":"MEX","LABEL":"San Jose del Rincon"},
    {"ID":"738","CODE":"MEX","LABEL":"San Martin de las Piramides"},
    {"ID":"739","CODE":"MEX","LABEL":"San Mateo Atenco"},
    {"ID":"740","CODE":"MEX","LABEL":"San Simon de Guerrero"},
    {"ID":"741","CODE":"MEX","LABEL":"Santo Tomas"},
    {"ID":"742","CODE":"MEX","LABEL":"Soyaniquilpan de Juarez"},
    {"ID":"743","CODE":"MEX","LABEL":"Sultepec"},
    {"ID":"744","CODE":"MEX","LABEL":"Tecamac"},
    {"ID":"745","CODE":"MEX","LABEL":"Tejupilco"},
    {"ID":"746","CODE":"MEX","LABEL":"Temamatla"},
    {"ID":"747","CODE":"MEX","LABEL":"Temascalapa"},
    {"ID":"748","CODE":"MEX","LABEL":"Temascalcingo"},
    {"ID":"749","CODE":"MEX","LABEL":"Temascaltepec"},
    {"ID":"750","CODE":"MEX","LABEL":"Temoaya"},
    {"ID":"751","CODE":"MEX","LABEL":"Tenancingo"},
    {"ID":"752","CODE":"MEX","LABEL":"Tenango del Aire"},
    {"ID":"753","CODE":"MEX","LABEL":"Tenango del Valle"},
    {"ID":"754","CODE":"MEX","LABEL":"Teoloyucan"},
    {"ID":"755","CODE":"MEX","LABEL":"Teotihuacan"},
    {"ID":"756","CODE":"MEX","LABEL":"Tepetlaoxtoc"},
    {"ID":"757","CODE":"MEX","LABEL":"Tepetlixpa"},
    {"ID":"758","CODE":"MEX","LABEL":"Tepotzotlan"},
    {"ID":"759","CODE":"MEX","LABEL":"Tequixquiac"},
    {"ID":"760","CODE":"MEX","LABEL":"Texcaltitlan"},
    {"ID":"761","CODE":"MEX","LABEL":"Texcalyacac"},
    {"ID":"762","CODE":"MEX","LABEL":"Texcoco"},
    {"ID":"763","CODE":"MEX","LABEL":"Tezoyuca"},
    {"ID":"764","CODE":"MEX","LABEL":"Tianguistenco"},
    {"ID":"765","CODE":"MEX","LABEL":"Timilpan"},
    {"ID":"766","CODE":"MEX","LABEL":"Tlalmanalco"},
    {"ID":"767","CODE":"MEX","LABEL":"Tlalnepantla de Baz"},
    {"ID":"768","CODE":"MEX","LABEL":"Tlatlaya"},
    {"ID":"769","CODE":"MEX","LABEL":"Toluca"},
    {"ID":"770","CODE":"MEX","LABEL":"Tonanitla"},
    {"ID":"771","CODE":"MEX","LABEL":"Tonatico"},
    {"ID":"772","CODE":"MEX","LABEL":"Tultepec"},
    {"ID":"773","CODE":"MEX","LABEL":"Tultitlan"},
    {"ID":"774","CODE":"MEX","LABEL":"Valle de Bravo"},
    {"ID":"775","CODE":"MEX","LABEL":"Valle de Chalco Solidaridad"},
    {"ID":"776","CODE":"MEX","LABEL":"Villa Guerrero"},
    {"ID":"777","CODE":"MEX","LABEL":"Villa Victoria"},
    {"ID":"778","CODE":"MEX","LABEL":"Villa de Allende"},
    {"ID":"779","CODE":"MEX","LABEL":"Villa del Carbon"},
    {"ID":"780","CODE":"MEX","LABEL":"Xalatlaco"},
    {"ID":"781","CODE":"MEX","LABEL":"Xonacatlan"},
    {"ID":"782","CODE":"MEX","LABEL":"Zacazonapan"},
    {"ID":"783","CODE":"MEX","LABEL":"Zacualpan"},
    {"ID":"784","CODE":"MEX","LABEL":"Zinacantepec"},
    {"ID":"785","CODE":"MEX","LABEL":"Zumpahuacan"},
    {"ID":"786","CODE":"MEX","LABEL":"Zumpango"},
    {"ID":"787","CODE":"MCH","LABEL":"Acuitzio"},
    {"ID":"788","CODE":"MCH","LABEL":"Aguililla"},
    {"ID":"789","CODE":"MCH","LABEL":"Alvaro Obregon"},
    {"ID":"790","CODE":"MCH","LABEL":"Angamacutiro"},
    {"ID":"791","CODE":"MCH","LABEL":"Angangueo"},
    {"ID":"792","CODE":"MCH","LABEL":"Apatzingan"},
    {"ID":"793","CODE":"MCH","LABEL":"Aporo"},
    {"ID":"794","CODE":"MCH","LABEL":"Aquila"},
    {"ID":"795","CODE":"MCH","LABEL":"Ario"},
    {"ID":"796","CODE":"MCH","LABEL":"Arteaga"},
    {"ID":"797","CODE":"MCH","LABEL":"Brisenas"},
    {"ID":"798","CODE":"MCH","LABEL":"Buenavista"},
    {"ID":"799","CODE":"MCH","LABEL":"Caracuaro"},
    {"ID":"800","CODE":"MCH","LABEL":"Charapan"},
    {"ID":"801","CODE":"MCH","LABEL":"Charo"},
    {"ID":"802","CODE":"MCH","LABEL":"Chavinda"},
    {"ID":"803","CODE":"MCH","LABEL":"Cheran"},
    {"ID":"804","CODE":"MCH","LABEL":"Chilchota"},
    {"ID":"805","CODE":"MCH","LABEL":"Chinicuila"},
    {"ID":"806","CODE":"MCH","LABEL":"Chucandiro"},
    {"ID":"807","CODE":"MCH","LABEL":"Churintzio"},
    {"ID":"808","CODE":"MCH","LABEL":"Churumuco"},
    {"ID":"809","CODE":"MCH","LABEL":"Coahuayana"},
    {"ID":"810","CODE":"MCH","LABEL":"Coalcoman de Vazquez Pallares"},
    {"ID":"811","CODE":"MCH","LABEL":"Coeneo"},
    {"ID":"812","CODE":"MCH","LABEL":"Cojumatlan de Regules"},
    {"ID":"813","CODE":"MCH","LABEL":"Contepec"},
    {"ID":"814","CODE":"MCH","LABEL":"Copandaro"},
    {"ID":"815","CODE":"MCH","LABEL":"Cotija"},
    {"ID":"816","CODE":"MCH","LABEL":"Cuitzeo"},
    {"ID":"817","CODE":"MCH","LABEL":"Ecuandureo"},
    {"ID":"818","CODE":"MCH","LABEL":"Epitacio Huerta"},
    {"ID":"819","CODE":"MCH","LABEL":"Erongaricuaro"},
    {"ID":"820","CODE":"MCH","LABEL":"Gabriel Zamora"},
    {"ID":"821","CODE":"MCH","LABEL":"Hidalgo"},
    {"ID":"822","CODE":"MCH","LABEL":"Huandacareo"},
    {"ID":"823","CODE":"MCH","LABEL":"Huaniqueo"},
    {"ID":"824","CODE":"MCH","LABEL":"Huetamo"},
    {"ID":"825","CODE":"MCH","LABEL":"Huiramba"},
    {"ID":"826","CODE":"MCH","LABEL":"Indaparapeo"},
    {"ID":"827","CODE":"MCH","LABEL":"Irimbo"},
    {"ID":"828","CODE":"MCH","LABEL":"Ixtlan"},
    {"ID":"829","CODE":"MCH","LABEL":"Jacona"},
    {"ID":"830","CODE":"MCH","LABEL":"Jimenez"},
    {"ID":"831","CODE":"MCH","LABEL":"Jiquilpan"},
    {"ID":"832","CODE":"MCH","LABEL":"Jose Sixto Verduzco"},
    {"ID":"833","CODE":"MCH","LABEL":"Juarez"},
    {"ID":"834","CODE":"MCH","LABEL":"Jungapeo"},
    {"ID":"835","CODE":"MCH","LABEL":"La Huacana"},
    {"ID":"836","CODE":"MCH","LABEL":"La Piedad"},
    {"ID":"837","CODE":"MCH","LABEL":"Lagunillas"},
    {"ID":"838","CODE":"MCH","LABEL":"Lazaro Cardenas"},
    {"ID":"839","CODE":"MCH","LABEL":"Los Reyes"},
    {"ID":"840","CODE":"MCH","LABEL":"Madero"},
    {"ID":"841","CODE":"MCH","LABEL":"Maravatio"},
    {"ID":"842","CODE":"MCH","LABEL":"Marcos Castellanos"},
    {"ID":"843","CODE":"MCH","LABEL":"Morelia"},
    {"ID":"844","CODE":"MCH","LABEL":"Morelos"},
    {"ID":"845","CODE":"MCH","LABEL":"Mugica"},
    {"ID":"846","CODE":"MCH","LABEL":"Nahuatzen"},
    {"ID":"847","CODE":"MCH","LABEL":"Nocupetaro"},
    {"ID":"848","CODE":"MCH","LABEL":"Nuevo Parangaricutiro"},
    {"ID":"849","CODE":"MCH","LABEL":"Nuevo Urecho"},
    {"ID":"850","CODE":"MCH","LABEL":"Numaran"},
    {"ID":"851","CODE":"MCH","LABEL":"Ocampo"},
    {"ID":"852","CODE":"MCH","LABEL":"Pajacuaran"},
    {"ID":"853","CODE":"MCH","LABEL":"Panindicuaro"},
    {"ID":"854","CODE":"MCH","LABEL":"Paracho"},
    {"ID":"855","CODE":"MCH","LABEL":"Paracuaro"},
    {"ID":"856","CODE":"MCH","LABEL":"Patzcuaro"},
    {"ID":"857","CODE":"MCH","LABEL":"Penjamillo"},
    {"ID":"858","CODE":"MCH","LABEL":"Periban"},
    {"ID":"859","CODE":"MCH","LABEL":"Purepero"},
    {"ID":"860","CODE":"MCH","LABEL":"Puruandiro"},
    {"ID":"861","CODE":"MCH","LABEL":"Querendaro"},
    {"ID":"862","CODE":"MCH","LABEL":"Quiroga"},
    {"ID":"863","CODE":"MCH","LABEL":"Sahuayo"},
    {"ID":"864","CODE":"MCH","LABEL":"Salvador Escalante"},
    {"ID":"865","CODE":"MCH","LABEL":"San Lucas"},
    {"ID":"866","CODE":"MCH","LABEL":"Santa Ana Maya"},
    {"ID":"867","CODE":"MCH","LABEL":"Senguio"},
    {"ID":"868","CODE":"MCH","LABEL":"Susupuato"},
    {"ID":"869","CODE":"MCH","LABEL":"Tacambaro"},
    {"ID":"870","CODE":"MCH","LABEL":"Tancitaro"},
    {"ID":"871","CODE":"MCH","LABEL":"Tangamandapio"},
    {"ID":"872","CODE":"MCH","LABEL":"Tangancicuaro"},
    {"ID":"873","CODE":"MCH","LABEL":"Tanhuato"},
    {"ID":"874","CODE":"MCH","LABEL":"Taretan"},
    {"ID":"875","CODE":"MCH","LABEL":"Tarimbaro"},
    {"ID":"876","CODE":"MCH","LABEL":"Tepalcatepec"},
    {"ID":"877","CODE":"MCH","LABEL":"Tingambato"},
    {"ID":"878","CODE":"MCH","LABEL":"Tinguindin"},
    {"ID":"879","CODE":"MCH","LABEL":"Tiquicheo de Nicolas Romero"},
    {"ID":"880","CODE":"MCH","LABEL":"Tlalpujahua"},
    {"ID":"881","CODE":"MCH","LABEL":"Tlazazalca"},
    {"ID":"882","CODE":"MCH","LABEL":"Tocumbo"},
    {"ID":"883","CODE":"MCH","LABEL":"Tumbiscatio"},
    {"ID":"884","CODE":"MCH","LABEL":"Turicato"},
    {"ID":"885","CODE":"MCH","LABEL":"Tuxpan"},
    {"ID":"886","CODE":"MCH","LABEL":"Tuzantla"},
    {"ID":"887","CODE":"MCH","LABEL":"Tzintzuntzan"},
    {"ID":"888","CODE":"MCH","LABEL":"Tzitzio"},
    {"ID":"889","CODE":"MCH","LABEL":"Uruapan"},
    {"ID":"890","CODE":"MCH","LABEL":"Venustiano Carranza"},
    {"ID":"891","CODE":"MCH","LABEL":"Villamar"},
    {"ID":"892","CODE":"MCH","LABEL":"Vista Hermosa"},
    {"ID":"893","CODE":"MCH","LABEL":"Yurecuaro"},
    {"ID":"894","CODE":"MCH","LABEL":"Zacapu"},
    {"ID":"895","CODE":"MCH","LABEL":"Zamora"},
    {"ID":"896","CODE":"MCH","LABEL":"Zinaparo"},
    {"ID":"897","CODE":"MCH","LABEL":"Zinapecuaro"},
    {"ID":"898","CODE":"MCH","LABEL":"Ziracuaretiro"},
    {"ID":"899","CODE":"MCH","LABEL":"Zitacuaro"},
    {"ID":"900","CODE":"MOR","LABEL":"Amacuzac"},
    {"ID":"901","CODE":"MOR","LABEL":"Atlatlahucan"},
    {"ID":"902","CODE":"MOR","LABEL":"Axochiapan"},
    {"ID":"903","CODE":"MOR","LABEL":"Ayala"},
    {"ID":"904","CODE":"MOR","LABEL":"Coatlan del Rio"},
    {"ID":"905","CODE":"MOR","LABEL":"Cuautla"},
    {"ID":"906","CODE":"MOR","LABEL":"Cuernavaca"},
    {"ID":"907","CODE":"MOR","LABEL":"Emiliano Zapata"},
    {"ID":"908","CODE":"MOR","LABEL":"Huitzilac"},
    {"ID":"909","CODE":"MOR","LABEL":"Jantetelco"},
    {"ID":"910","CODE":"MOR","LABEL":"Jiutepec"},
    {"ID":"911","CODE":"MOR","LABEL":"Jojutla"},
    {"ID":"912","CODE":"MOR","LABEL":"Jonacatepec de Leandro Valle"},
    {"ID":"913","CODE":"MOR","LABEL":"Mazatepec"},
    {"ID":"914","CODE":"MOR","LABEL":"Miacatlan"},
    {"ID":"915","CODE":"MOR","LABEL":"Ocuituco"},
    {"ID":"916","CODE":"MOR","LABEL":"Puente de Ixtla"},
    {"ID":"917","CODE":"MOR","LABEL":"Temixco"},
    {"ID":"918","CODE":"MOR","LABEL":"Temoac"},
    {"ID":"919","CODE":"MOR","LABEL":"Tepalcingo"},
    {"ID":"920","CODE":"MOR","LABEL":"Tepoztlan"},
    {"ID":"921","CODE":"MOR","LABEL":"Tetecala"},
    {"ID":"922","CODE":"MOR","LABEL":"Tetela del Volcan"},
    {"ID":"923","CODE":"MOR","LABEL":"Tlalnepantla"},
    {"ID":"924","CODE":"MOR","LABEL":"Tlaltizapan de Zapata"},
    {"ID":"925","CODE":"MOR","LABEL":"Tlaquiltenango"},
    {"ID":"926","CODE":"MOR","LABEL":"Tlayacapan"},
    {"ID":"927","CODE":"MOR","LABEL":"Totolapan"},
    {"ID":"928","CODE":"MOR","LABEL":"Xochitepec"},
    {"ID":"929","CODE":"MOR","LABEL":"Yautepec"},
    {"ID":"930","CODE":"MOR","LABEL":"Yecapixtla"},
    {"ID":"931","CODE":"MOR","LABEL":"Zacatepec"},
    {"ID":"932","CODE":"MOR","LABEL":"Zacualpan de Amilpas"},
    {"ID":"933","CODE":"NAY","LABEL":"Acaponeta"},
    {"ID":"934","CODE":"NAY","LABEL":"Ahuacatlan"},
    {"ID":"935","CODE":"NAY","LABEL":"Amatlan de Canas"},
    {"ID":"936","CODE":"NAY","LABEL":"Bahia de Banderas"},
    {"ID":"937","CODE":"NAY","LABEL":"Compostela"},
    {"ID":"938","CODE":"NAY","LABEL":"Del Nayar"},
    {"ID":"939","CODE":"NAY","LABEL":"Huajicori"},
    {"ID":"940","CODE":"NAY","LABEL":"Ixtlan del Rio"},
    {"ID":"941","CODE":"NAY","LABEL":"Jala"},
    {"ID":"942","CODE":"NAY","LABEL":"La Yesca"},
    {"ID":"943","CODE":"NAY","LABEL":"Rosamorada"},
    {"ID":"944","CODE":"NAY","LABEL":"Ruiz"},
    {"ID":"945","CODE":"NAY","LABEL":"San Blas"},
    {"ID":"946","CODE":"NAY","LABEL":"San Pedro Lagunillas"},
    {"ID":"947","CODE":"NAY","LABEL":"Santa Maria del Oro"},
    {"ID":"948","CODE":"NAY","LABEL":"Santiago Ixcuintla"},
    {"ID":"949","CODE":"NAY","LABEL":"Tecuala"},
    {"ID":"950","CODE":"NAY","LABEL":"Tepic"},
    {"ID":"951","CODE":"NAY","LABEL":"Tuxpan"},
    {"ID":"952","CODE":"NAY","LABEL":"Xalisco"},
    {"ID":"953","CODE":"NL","LABEL":"Abasolo"},
    {"ID":"954","CODE":"NL","LABEL":"Agualeguas"},
    {"ID":"955","CODE":"NL","LABEL":"Allende"},
    {"ID":"956","CODE":"NL","LABEL":"Anahuac"},
    {"ID":"957","CODE":"NL","LABEL":"Apodaca"},
    {"ID":"958","CODE":"NL","LABEL":"Aramberri"},
    {"ID":"959","CODE":"NL","LABEL":"Bustamante"},
    {"ID":"960","CODE":"NL","LABEL":"Cadereyta Jimenez"},
    {"ID":"961","CODE":"NL","LABEL":"Cerralvo"},
    {"ID":"962","CODE":"NL","LABEL":"China"},
    {"ID":"963","CODE":"NL","LABEL":"Cienega de Flores"},
    {"ID":"964","CODE":"NL","LABEL":"Doctor Arroyo"},
    {"ID":"965","CODE":"NL","LABEL":"Doctor Coss"},
    {"ID":"966","CODE":"NL","LABEL":"Doctor Gonzalez"},
    {"ID":"967","CODE":"NL","LABEL":"El Carmen"},
    {"ID":"968","CODE":"NL","LABEL":"Galeana"},
    {"ID":"969","CODE":"NL","LABEL":"Garcia"},
    {"ID":"970","CODE":"NL","LABEL":"General Bravo"},
    {"ID":"971","CODE":"NL","LABEL":"General Escobedo"},
    {"ID":"972","CODE":"NL","LABEL":"General Teran"},
    {"ID":"973","CODE":"NL","LABEL":"General Trevino"},
    {"ID":"974","CODE":"NL","LABEL":"General Zaragoza"},
    {"ID":"975","CODE":"NL","LABEL":"General Zuazua"},
    {"ID":"976","CODE":"NL","LABEL":"Guadalupe"},
    {"ID":"977","CODE":"NL","LABEL":"Hidalgo"},
    {"ID":"978","CODE":"NL","LABEL":"Higueras"},
    {"ID":"979","CODE":"NL","LABEL":"Hualahuises"},
    {"ID":"980","CODE":"NL","LABEL":"Iturbide"},
    {"ID":"981","CODE":"NL","LABEL":"Juarez"},
    {"ID":"982","CODE":"NL","LABEL":"Lampazos de Naranjo"},
    {"ID":"983","CODE":"NL","LABEL":"Linares"},
    {"ID":"984","CODE":"NL","LABEL":"Los Aldamas"},
    {"ID":"985","CODE":"NL","LABEL":"Los Herreras"},
    {"ID":"986","CODE":"NL","LABEL":"Los Ramones"},
    {"ID":"987","CODE":"NL","LABEL":"Marin"},
    {"ID":"988","CODE":"NL","LABEL":"Melchor Ocampo"},
    {"ID":"989","CODE":"NL","LABEL":"Mier y Noriega"},
    {"ID":"990","CODE":"NL","LABEL":"Mina"},
    {"ID":"991","CODE":"NL","LABEL":"Montemorelos"},
    {"ID":"992","CODE":"NL","LABEL":"Monterrey"},
    {"ID":"993","CODE":"NL","LABEL":"Paras"},
    {"ID":"994","CODE":"NL","LABEL":"Pesqueria"},
    {"ID":"995","CODE":"NL","LABEL":"Rayones"},
    {"ID":"996","CODE":"NL","LABEL":"Sabinas Hidalgo"},
    {"ID":"997","CODE":"NL","LABEL":"Salinas Victoria"},
    {"ID":"998","CODE":"NL","LABEL":"San Nicolas de los Garza"},
    {"ID":"999","CODE":"NL","LABEL":"San Pedro Garza Garcia"},
    {"ID":"1000","CODE":"NL","LABEL":"Santa Catarina"},
    {"ID":"1001","CODE":"NL","LABEL":"Santiago"},
    {"ID":"1002","CODE":"NL","LABEL":"Vallecillo"},
    {"ID":"1003","CODE":"NL","LABEL":"Villaldama"},
    {"ID":"1004","CODE":"OAX","LABEL":"Abejones"},
    {"ID":"1005","CODE":"OAX","LABEL":"Acatlan de Perez Figueroa"},
    {"ID":"1006","CODE":"OAX","LABEL":"Animas Trujano"},
    {"ID":"1007","CODE":"OAX","LABEL":"Asuncion Cacalotepec"},
    {"ID":"1008","CODE":"OAX","LABEL":"Asuncion Cuyotepeji"},
    {"ID":"1009","CODE":"OAX","LABEL":"Asuncion Ixtaltepec"},
    {"ID":"1010","CODE":"OAX","LABEL":"Asuncion Nochixtlan"},
    {"ID":"1011","CODE":"OAX","LABEL":"Asuncion Ocotlan"},
    {"ID":"1012","CODE":"OAX","LABEL":"Asuncion Tlacolulita"},
    {"ID":"1013","CODE":"OAX","LABEL":"Ayoquezco de Aldama"},
    {"ID":"1014","CODE":"OAX","LABEL":"Ayotzintepec"},
    {"ID":"1015","CODE":"OAX","LABEL":"Calihuala"},
    {"ID":"1016","CODE":"OAX","LABEL":"Candelaria Loxicha"},
    {"ID":"1017","CODE":"OAX","LABEL":"Capulalpam de Mendez"},
    {"ID":"1018","CODE":"OAX","LABEL":"Chahuites"},
    {"ID":"1019","CODE":"OAX","LABEL":"Chalcatongo de Hidalgo"},
    {"ID":"1020","CODE":"OAX","LABEL":"Chiquihuitlan de Benito Juarez"},
    {"ID":"1021","CODE":"OAX","LABEL":"Cienega de Zimatlan"},
    {"ID":"1022","CODE":"OAX","LABEL":"Ciudad Ixtepec"},
    {"ID":"1023","CODE":"OAX","LABEL":"Coatecas Altas"},
    {"ID":"1024","CODE":"OAX","LABEL":"Coicoyan de las Flores"},
    {"ID":"1025","CODE":"OAX","LABEL":"Concepcion Buenavista"},
    {"ID":"1026","CODE":"OAX","LABEL":"Concepcion Papalo"},
    {"ID":"1027","CODE":"OAX","LABEL":"Constancia del Rosario"},
    {"ID":"1028","CODE":"OAX","LABEL":"Cosolapa"},
    {"ID":"1029","CODE":"OAX","LABEL":"Cosoltepec"},
    {"ID":"1030","CODE":"OAX","LABEL":"Cuilapam de Guerrero"},
    {"ID":"1031","CODE":"OAX","LABEL":"Cuna de la Independencia de Oaxaca"},
    {"ID":"1032","CODE":"OAX","LABEL":"Cuyamecalco Villa de Zaragoza"},
    {"ID":"1033","CODE":"OAX","LABEL":"El Barrio de la Soledad"},
    {"ID":"1034","CODE":"OAX","LABEL":"El Espinal"},
    {"ID":"1035","CODE":"OAX","LABEL":"Eloxochitlan de Flores Magon"},
    {"ID":"1036","CODE":"OAX","LABEL":"Fresnillo de Trujano"},
    {"ID":"1037","CODE":"OAX","LABEL":"Guadalupe Etla"},
    {"ID":"1038","CODE":"OAX","LABEL":"Guadalupe de Ramirez"},
    {"ID":"1039","CODE":"OAX","LABEL":"Guelatao de Juarez"},
    {"ID":"1040","CODE":"OAX","LABEL":"Guevea de Humboldt"},
    {"ID":"1041","CODE":"OAX","LABEL":"Heroica Ciudad de Ejutla de Crespo"},
    {"ID":"1042","CODE":"OAX","LABEL":"Heroica Ciudad de Huajuapan de Leon"},
    {"ID":"1043","CODE":"OAX","LABEL":"Heroica Ciudad de Juchitan de Zaragoza"},
    {"ID":"1044","CODE":"OAX","LABEL":"Heroica Ciudad de Tlaxiaco"},
    {"ID":"1045","CODE":"OAX","LABEL":"Heroica Villa Tezoatlan de Segura y Luna"},
    {"ID":"1046","CODE":"OAX","LABEL":"Huautepec"},
    {"ID":"1047","CODE":"OAX","LABEL":"Huautla de Jimenez"},
    {"ID":"1048","CODE":"OAX","LABEL":"Ixpantepec Nieves"},
    {"ID":"1049","CODE":"OAX","LABEL":"Ixtlan de Juarez"},
    {"ID":"1050","CODE":"OAX","LABEL":"La Compania"},
    {"ID":"1051","CODE":"OAX","LABEL":"La Pe"},
    {"ID":"1052","CODE":"OAX","LABEL":"La Reforma"},
    {"ID":"1053","CODE":"OAX","LABEL":"La Trinidad Vista Hermosa"},
    {"ID":"1054","CODE":"OAX","LABEL":"Loma Bonita"},
    {"ID":"1055","CODE":"OAX","LABEL":"Magdalena Apasco"},
    {"ID":"1056","CODE":"OAX","LABEL":"Magdalena Jaltepec"},
    {"ID":"1057","CODE":"OAX","LABEL":"Magdalena Mixtepec"},
    {"ID":"1058","CODE":"OAX","LABEL":"Magdalena Ocotlan"},
    {"ID":"1059","CODE":"OAX","LABEL":"Magdalena Penasco"},
    {"ID":"1060","CODE":"OAX","LABEL":"Magdalena Teitipac"},
    {"ID":"1061","CODE":"OAX","LABEL":"Magdalena Tequisistlan"},
    {"ID":"1062","CODE":"OAX","LABEL":"Magdalena Tlacotepec"},
    {"ID":"1063","CODE":"OAX","LABEL":"Magdalena Yodocono de Porfirio Diaz"},
    {"ID":"1064","CODE":"OAX","LABEL":"Magdalena Zahuatlan"},
    {"ID":"1065","CODE":"OAX","LABEL":"Mariscala de Juarez"},
    {"ID":"1066","CODE":"OAX","LABEL":"Martires de Tacubaya"},
    {"ID":"1067","CODE":"OAX","LABEL":"Matias Romero Avendano"},
    {"ID":"1068","CODE":"OAX","LABEL":"Mazatlan Villa de Flores"},
    {"ID":"1069","CODE":"OAX","LABEL":"Mesones Hidalgo"},
    {"ID":"1070","CODE":"OAX","LABEL":"Miahuatlan de Porfirio Diaz"},
    {"ID":"1071","CODE":"OAX","LABEL":"Mixistlan de la Reforma"},
    {"ID":"1072","CODE":"OAX","LABEL":"Monjas"},
    {"ID":"1073","CODE":"OAX","LABEL":"Natividad"},
    {"ID":"1074","CODE":"OAX","LABEL":"Nazareno Etla"},
    {"ID":"1075","CODE":"OAX","LABEL":"Nejapa de Madero"},
    {"ID":"1076","CODE":"OAX","LABEL":"Nuevo Zoquiapam"},
    {"ID":"1077","CODE":"OAX","LABEL":"Oaxaca de Juarez"},
    {"ID":"1078","CODE":"OAX","LABEL":"Ocotlan de Morelos"},
    {"ID":"1079","CODE":"OAX","LABEL":"Pinotepa de Don Luis"},
    {"ID":"1080","CODE":"OAX","LABEL":"Pluma Hidalgo"},
    {"ID":"1081","CODE":"OAX","LABEL":"Putla Villa de Guerrero"},
    {"ID":"1082","CODE":"OAX","LABEL":"Reforma de Pineda"},
    {"ID":"1083","CODE":"OAX","LABEL":"Reyes Etla"},
    {"ID":"1084","CODE":"OAX","LABEL":"Rojas de Cuauhtemoc"},
    {"ID":"1085","CODE":"OAX","LABEL":"Salina Cruz"},
    {"ID":"1086","CODE":"OAX","LABEL":"San Agustin Amatengo"},
    {"ID":"1087","CODE":"OAX","LABEL":"San Agustin Atenango"},
    {"ID":"1088","CODE":"OAX","LABEL":"San Agustin Chayuco"},
    {"ID":"1089","CODE":"OAX","LABEL":"San Agustin Etla"},
    {"ID":"1090","CODE":"OAX","LABEL":"San Agustin Loxicha"},
    {"ID":"1091","CODE":"OAX","LABEL":"San Agustin Tlacotepec"},
    {"ID":"1092","CODE":"OAX","LABEL":"San Agustin Yatareni"},
    {"ID":"1093","CODE":"OAX","LABEL":"San Agustin de las Juntas"},
    {"ID":"1094","CODE":"OAX","LABEL":"San Andres Cabecera Nueva"},
    {"ID":"1095","CODE":"OAX","LABEL":"San Andres Dinicuiti"},
    {"ID":"1096","CODE":"OAX","LABEL":"San Andres Huaxpaltepec"},
    {"ID":"1097","CODE":"OAX","LABEL":"San Andres Huayapam"},
    {"ID":"1098","CODE":"OAX","LABEL":"San Andres Ixtlahuaca"},
    {"ID":"1099","CODE":"OAX","LABEL":"San Andres Lagunas"},
    {"ID":"1100","CODE":"OAX","LABEL":"San Andres Nuxino"},
    {"ID":"1101","CODE":"OAX","LABEL":"San Andres Paxtlan"},
    {"ID":"1102","CODE":"OAX","LABEL":"San Andres Sinaxtla"},
    {"ID":"1103","CODE":"OAX","LABEL":"San Andres Solaga"},
    {"ID":"1104","CODE":"OAX","LABEL":"San Andres Teotilalpam"},
    {"ID":"1105","CODE":"OAX","LABEL":"San Andres Tepetlapa"},
    {"ID":"1106","CODE":"OAX","LABEL":"San Andres Yaa"},
    {"ID":"1107","CODE":"OAX","LABEL":"San Andres Zabache"},
    {"ID":"1108","CODE":"OAX","LABEL":"San Andres Zautla"},
    {"ID":"1109","CODE":"OAX","LABEL":"San Antonino Castillo Velasco"},
    {"ID":"1110","CODE":"OAX","LABEL":"San Antonino Monte Verde"},
    {"ID":"1111","CODE":"OAX","LABEL":"San Antonino el Alto"},
    {"ID":"1112","CODE":"OAX","LABEL":"San Antonio Acutla"},
    {"ID":"1113","CODE":"OAX","LABEL":"San Antonio Huitepec"},
    {"ID":"1114","CODE":"OAX","LABEL":"San Antonio Nanahuatipam"},
    {"ID":"1115","CODE":"OAX","LABEL":"San Antonio Sinicahua"},
    {"ID":"1116","CODE":"OAX","LABEL":"San Antonio Tepetlapa"},
    {"ID":"1117","CODE":"OAX","LABEL":"San Antonio de la Cal"},
    {"ID":"1118","CODE":"OAX","LABEL":"San Baltazar Chichicapam"},
    {"ID":"1119","CODE":"OAX","LABEL":"San Baltazar Loxicha"},
    {"ID":"1120","CODE":"OAX","LABEL":"San Baltazar Yatzachi el Bajo"},
    {"ID":"1121","CODE":"OAX","LABEL":"San Bartolo Coyotepec"},
    {"ID":"1122","CODE":"OAX","LABEL":"San Bartolo Soyaltepec"},
    {"ID":"1123","CODE":"OAX","LABEL":"San Bartolo Yautepec"},
    {"ID":"1124","CODE":"OAX","LABEL":"San Bartolome Ayautla"},
    {"ID":"1125","CODE":"OAX","LABEL":"San Bartolome Loxicha"},
    {"ID":"1126","CODE":"OAX","LABEL":"San Bartolome Quialana"},
    {"ID":"1127","CODE":"OAX","LABEL":"San Bartolome Yucuane"},
    {"ID":"1128","CODE":"OAX","LABEL":"San Bartolome Zoogocho"},
    {"ID":"1129","CODE":"OAX","LABEL":"San Bernardo Mixtepec"},
    {"ID":"1130","CODE":"OAX","LABEL":"San Blas Atempa"},
    {"ID":"1131","CODE":"OAX","LABEL":"San Carlos Yautepec"},
    {"ID":"1132","CODE":"OAX","LABEL":"San Cristobal Amatlan"},
    {"ID":"1133","CODE":"OAX","LABEL":"San Cristobal Amoltepec"},
    {"ID":"1134","CODE":"OAX","LABEL":"San Cristobal Lachirioag"},
    {"ID":"1135","CODE":"OAX","LABEL":"San Cristobal Suchixtlahuaca"},
    {"ID":"1136","CODE":"OAX","LABEL":"San Dionisio Ocotepec"},
    {"ID":"1137","CODE":"OAX","LABEL":"San Dionisio Ocotlan"},
    {"ID":"1138","CODE":"OAX","LABEL":"San Dionisio del Mar"},
    {"ID":"1139","CODE":"OAX","LABEL":"San Esteban Atatlahuca"},
    {"ID":"1140","CODE":"OAX","LABEL":"San Felipe Jalapa de Diaz"},
    {"ID":"1141","CODE":"OAX","LABEL":"San Felipe Tejalapam"},
    {"ID":"1142","CODE":"OAX","LABEL":"San Felipe Usila"},
    {"ID":"1143","CODE":"OAX","LABEL":"San Francisco Cahuacua"},
    {"ID":"1144","CODE":"OAX","LABEL":"San Francisco Cajonos"},
    {"ID":"1145","CODE":"OAX","LABEL":"San Francisco Chapulapa"},
    {"ID":"1146","CODE":"OAX","LABEL":"San Francisco Chindua"},
    {"ID":"1147","CODE":"OAX","LABEL":"San Francisco Huehuetlan"},
    {"ID":"1148","CODE":"OAX","LABEL":"San Francisco Ixhuatan"},
    {"ID":"1149","CODE":"OAX","LABEL":"San Francisco Jaltepetongo"},
    {"ID":"1150","CODE":"OAX","LABEL":"San Francisco Lachigolo"},
    {"ID":"1151","CODE":"OAX","LABEL":"San Francisco Logueche"},
    {"ID":"1152","CODE":"OAX","LABEL":"San Francisco Nuxano"},
    {"ID":"1153","CODE":"OAX","LABEL":"San Francisco Ozolotepec"},
    {"ID":"1154","CODE":"OAX","LABEL":"San Francisco Sola"},
    {"ID":"1155","CODE":"OAX","LABEL":"San Francisco Telixtlahuaca"},
    {"ID":"1156","CODE":"OAX","LABEL":"San Francisco Teopan"},
    {"ID":"1157","CODE":"OAX","LABEL":"San Francisco Tlapancingo"},
    {"ID":"1158","CODE":"OAX","LABEL":"San Francisco del Mar"},
    {"ID":"1159","CODE":"OAX","LABEL":"San Gabriel Mixtepec"},
    {"ID":"1160","CODE":"OAX","LABEL":"San Ildefonso Amatlan"},
    {"ID":"1161","CODE":"OAX","LABEL":"San Ildefonso Sola"},
    {"ID":"1162","CODE":"OAX","LABEL":"San Ildefonso Villa Alta"},
    {"ID":"1163","CODE":"OAX","LABEL":"San Jacinto Amilpas"},
    {"ID":"1164","CODE":"OAX","LABEL":"San Jacinto Tlacotepec"},
    {"ID":"1165","CODE":"OAX","LABEL":"San Jeronimo Coatlan"},
    {"ID":"1166","CODE":"OAX","LABEL":"San Jeronimo Silacayoapilla"},
    {"ID":"1167","CODE":"OAX","LABEL":"San Jeronimo Sosola"},
    {"ID":"1168","CODE":"OAX","LABEL":"San Jeronimo Taviche"},
    {"ID":"1169","CODE":"OAX","LABEL":"San Jeronimo Tecoatl"},
    {"ID":"1170","CODE":"OAX","LABEL":"San Jeronimo Tlacochahuaya"},
    {"ID":"1171","CODE":"OAX","LABEL":"San Jorge Nuchita"},
    {"ID":"1172","CODE":"OAX","LABEL":"San Jose Ayuquila"},
    {"ID":"1173","CODE":"OAX","LABEL":"San Jose Chiltepec"},
    {"ID":"1174","CODE":"OAX","LABEL":"San Jose Estancia Grande"},
    {"ID":"1175","CODE":"OAX","LABEL":"San Jose Independencia"},
    {"ID":"1176","CODE":"OAX","LABEL":"San Jose Lachiguiri"},
    {"ID":"1177","CODE":"OAX","LABEL":"San Jose Tenango"},
    {"ID":"1178","CODE":"OAX","LABEL":"San Jose del Penasco"},
    {"ID":"1179","CODE":"OAX","LABEL":"San Jose del Progreso"},
    {"ID":"1180","CODE":"OAX","LABEL":"San Juan Achiutla"},
    {"ID":"1181","CODE":"OAX","LABEL":"San Juan Atepec"},
    {"ID":"1182","CODE":"OAX","LABEL":"San Juan Bautista Atatlahuca"},
    {"ID":"1183","CODE":"OAX","LABEL":"San Juan Bautista Coixtlahuaca"},
    {"ID":"1184","CODE":"OAX","LABEL":"San Juan Bautista Cuicatlan"},
    {"ID":"1185","CODE":"OAX","LABEL":"San Juan Bautista Guelache"},
    {"ID":"1186","CODE":"OAX","LABEL":"San Juan Bautista Jayacatlan"},
    {"ID":"1187","CODE":"OAX","LABEL":"San Juan Bautista Lo de Soto"},
    {"ID":"1188","CODE":"OAX","LABEL":"San Juan Bautista Suchitepec"},
    {"ID":"1189","CODE":"OAX","LABEL":"San Juan Bautista Tlachichilco"},
    {"ID":"1190","CODE":"OAX","LABEL":"San Juan Bautista Tlacoatzintepec"},
    {"ID":"1191","CODE":"OAX","LABEL":"San Juan Bautista Tuxtepec"},
    {"ID":"1192","CODE":"OAX","LABEL":"San Juan Bautista Valle Nacional"},
    {"ID":"1193","CODE":"OAX","LABEL":"San Juan Cacahuatepec"},
    {"ID":"1194","CODE":"OAX","LABEL":"San Juan Chicomezuchil"},
    {"ID":"1195","CODE":"OAX","LABEL":"San Juan Chilateca"},
    {"ID":"1196","CODE":"OAX","LABEL":"San Juan Cieneguilla"},
    {"ID":"1197","CODE":"OAX","LABEL":"San Juan Coatzospam"},
    {"ID":"1198","CODE":"OAX","LABEL":"San Juan Colorado"},
    {"ID":"1199","CODE":"OAX","LABEL":"San Juan Comaltepec"},
    {"ID":"1200","CODE":"OAX","LABEL":"San Juan Cotzocon"},
    {"ID":"1201","CODE":"OAX","LABEL":"San Juan Diuxi"},
    {"ID":"1202","CODE":"OAX","LABEL":"San Juan Evangelista Analco"},
    {"ID":"1203","CODE":"OAX","LABEL":"San Juan Guelavia"},
    {"ID":"1204","CODE":"OAX","LABEL":"San Juan Guichicovi"},
    {"ID":"1205","CODE":"OAX","LABEL":"San Juan Ihualtepec"},
    {"ID":"1206","CODE":"OAX","LABEL":"San Juan Juquila Mixes"},
    {"ID":"1207","CODE":"OAX","LABEL":"San Juan Juquila Vijanos"},
    {"ID":"1208","CODE":"OAX","LABEL":"San Juan Lachao"},
    {"ID":"1209","CODE":"OAX","LABEL":"San Juan Lachigalla"},
    {"ID":"1210","CODE":"OAX","LABEL":"San Juan Lajarcia"},
    {"ID":"1211","CODE":"OAX","LABEL":"San Juan Lalana"},
    {"ID":"1212","CODE":"OAX","LABEL":"San Juan Mazatlan"},
    {"ID":"1213","CODE":"OAX","LABEL":"San Juan Mixtepec"},
    {"ID":"1214","CODE":"OAX","LABEL":"San Juan Mixtepec"},
    {"ID":"1215","CODE":"OAX","LABEL":"San Juan Numi"},
    {"ID":"1216","CODE":"OAX","LABEL":"San Juan Ozolotepec"},
    {"ID":"1217","CODE":"OAX","LABEL":"San Juan Petlapa"},
    {"ID":"1218","CODE":"OAX","LABEL":"San Juan Quiahije"},
    {"ID":"1219","CODE":"OAX","LABEL":"San Juan Quiotepec"},
    {"ID":"1220","CODE":"OAX","LABEL":"San Juan Sayultepec"},
    {"ID":"1221","CODE":"OAX","LABEL":"San Juan Tabaa"},
    {"ID":"1222","CODE":"OAX","LABEL":"San Juan Tamazola"},
    {"ID":"1223","CODE":"OAX","LABEL":"San Juan Teita"},
    {"ID":"1224","CODE":"OAX","LABEL":"San Juan Teitipac"},
    {"ID":"1225","CODE":"OAX","LABEL":"San Juan Tepeuxila"},
    {"ID":"1226","CODE":"OAX","LABEL":"San Juan Teposcolula"},
    {"ID":"1227","CODE":"OAX","LABEL":"San Juan Yaee"},
    {"ID":"1228","CODE":"OAX","LABEL":"San Juan Yatzona"},
    {"ID":"1229","CODE":"OAX","LABEL":"San Juan Yucuita"},
    {"ID":"1230","CODE":"OAX","LABEL":"San Juan de los Cues"},
    {"ID":"1231","CODE":"OAX","LABEL":"San Juan del Estado"},
    {"ID":"1232","CODE":"OAX","LABEL":"San Juan del Rio"},
    {"ID":"1233","CODE":"OAX","LABEL":"San Lorenzo Albarradas"},
    {"ID":"1234","CODE":"OAX","LABEL":"San Lorenzo Cacaotepec"},
    {"ID":"1235","CODE":"OAX","LABEL":"San Lorenzo Cuaunecuiltitla"},
    {"ID":"1236","CODE":"OAX","LABEL":"San Lorenzo Texmelucan"},
    {"ID":"1237","CODE":"OAX","LABEL":"San Lorenzo Victoria"},
    {"ID":"1238","CODE":"OAX","LABEL":"San Lorenzo"},
    {"ID":"1239","CODE":"OAX","LABEL":"San Lucas Camotlan"},
    {"ID":"1240","CODE":"OAX","LABEL":"San Lucas Ojitlan"},
    {"ID":"1241","CODE":"OAX","LABEL":"San Lucas Quiavini"},
    {"ID":"1242","CODE":"OAX","LABEL":"San Lucas Zoquiapam"},
    {"ID":"1243","CODE":"OAX","LABEL":"San Luis Amatlan"},
    {"ID":"1244","CODE":"OAX","LABEL":"San Marcial Ozolotepec"},
    {"ID":"1245","CODE":"OAX","LABEL":"San Marcos Arteaga"},
    {"ID":"1246","CODE":"OAX","LABEL":"San Martin Huamelulpam"},
    {"ID":"1247","CODE":"OAX","LABEL":"San Martin Itunyoso"},
    {"ID":"1248","CODE":"OAX","LABEL":"San Martin Lachila"},
    {"ID":"1249","CODE":"OAX","LABEL":"San Martin Peras"},
    {"ID":"1250","CODE":"OAX","LABEL":"San Martin Tilcajete"},
    {"ID":"1251","CODE":"OAX","LABEL":"San Martin Toxpalan"},
    {"ID":"1252","CODE":"OAX","LABEL":"San Martin Zacatepec"},
    {"ID":"1253","CODE":"OAX","LABEL":"San Martin de los Cansecos"},
    {"ID":"1254","CODE":"OAX","LABEL":"San Mateo Cajonos"},
    {"ID":"1255","CODE":"OAX","LABEL":"San Mateo Etlatongo"},
    {"ID":"1256","CODE":"OAX","LABEL":"San Mateo Nejapam"},
    {"ID":"1257","CODE":"OAX","LABEL":"San Mateo Penasco"},
    {"ID":"1258","CODE":"OAX","LABEL":"San Mateo Pinas"},
    {"ID":"1259","CODE":"OAX","LABEL":"San Mateo Rio Hondo"},
    {"ID":"1260","CODE":"OAX","LABEL":"San Mateo Sindihui"},
    {"ID":"1261","CODE":"OAX","LABEL":"San Mateo Tlapiltepec"},
    {"ID":"1262","CODE":"OAX","LABEL":"San Mateo Yoloxochitlan"},
    {"ID":"1263","CODE":"OAX","LABEL":"San Mateo Yucutindoo"},
    {"ID":"1264","CODE":"OAX","LABEL":"San Mateo del Mar"},
    {"ID":"1265","CODE":"OAX","LABEL":"San Melchor Betaza"},
    {"ID":"1266","CODE":"OAX","LABEL":"San Miguel Achiutla"},
    {"ID":"1267","CODE":"OAX","LABEL":"San Miguel Ahuehuetitlan"},
    {"ID":"1268","CODE":"OAX","LABEL":"San Miguel Aloapam"},
    {"ID":"1269","CODE":"OAX","LABEL":"San Miguel Amatitlan"},
    {"ID":"1270","CODE":"OAX","LABEL":"San Miguel Amatlan"},
    {"ID":"1271","CODE":"OAX","LABEL":"San Miguel Chicahua"},
    {"ID":"1272","CODE":"OAX","LABEL":"San Miguel Chimalapa"},
    {"ID":"1273","CODE":"OAX","LABEL":"San Miguel Coatlan"},
    {"ID":"1274","CODE":"OAX","LABEL":"San Miguel Ejutla"},
    {"ID":"1275","CODE":"OAX","LABEL":"San Miguel Huautla"},
    {"ID":"1276","CODE":"OAX","LABEL":"San Miguel Mixtepec"},
    {"ID":"1277","CODE":"OAX","LABEL":"San Miguel Panixtlahuaca"},
    {"ID":"1278","CODE":"OAX","LABEL":"San Miguel Peras"},
    {"ID":"1279","CODE":"OAX","LABEL":"San Miguel Piedras"},
    {"ID":"1280","CODE":"OAX","LABEL":"San Miguel Quetzaltepec"},
    {"ID":"1281","CODE":"OAX","LABEL":"San Miguel Santa Flor"},
    {"ID":"1282","CODE":"OAX","LABEL":"San Miguel Soyaltepec"},
    {"ID":"1283","CODE":"OAX","LABEL":"San Miguel Suchixtepec"},
    {"ID":"1284","CODE":"OAX","LABEL":"San Miguel Tecomatlan"},
    {"ID":"1285","CODE":"OAX","LABEL":"San Miguel Tenango"},
    {"ID":"1286","CODE":"OAX","LABEL":"San Miguel Tequixtepec"},
    {"ID":"1287","CODE":"OAX","LABEL":"San Miguel Tilquiapam"},
    {"ID":"1288","CODE":"OAX","LABEL":"San Miguel Tlacamama"},
    {"ID":"1289","CODE":"OAX","LABEL":"San Miguel Tlacotepec"},
    {"ID":"1290","CODE":"OAX","LABEL":"San Miguel Tulancingo"},
    {"ID":"1291","CODE":"OAX","LABEL":"San Miguel Yotao"},
    {"ID":"1292","CODE":"OAX","LABEL":"San Miguel del Puerto"},
    {"ID":"1293","CODE":"OAX","LABEL":"San Miguel del Rio"},
    {"ID":"1294","CODE":"OAX","LABEL":"San Miguel el Grande"},
    {"ID":"1295","CODE":"OAX","LABEL":"San Nicolas Hidalgo"},
    {"ID":"1296","CODE":"OAX","LABEL":"San Nicolas"},
    {"ID":"1297","CODE":"OAX","LABEL":"San Pablo Coatlan"},
    {"ID":"1298","CODE":"OAX","LABEL":"San Pablo Cuatro Venados"},
    {"ID":"1299","CODE":"OAX","LABEL":"San Pablo Etla"},
    {"ID":"1300","CODE":"OAX","LABEL":"San Pablo Huitzo"},
    {"ID":"1301","CODE":"OAX","LABEL":"San Pablo Huixtepec"},
    {"ID":"1302","CODE":"OAX","LABEL":"San Pablo Macuiltianguis"},
    {"ID":"1303","CODE":"OAX","LABEL":"San Pablo Tijaltepec"},
    {"ID":"1304","CODE":"OAX","LABEL":"San Pablo Villa de Mitla"},
    {"ID":"1305","CODE":"OAX","LABEL":"San Pablo Yaganiza"},
    {"ID":"1306","CODE":"OAX","LABEL":"San Pedro Amuzgos"},
    {"ID":"1307","CODE":"OAX","LABEL":"San Pedro Apostol"},
    {"ID":"1308","CODE":"OAX","LABEL":"San Pedro Atoyac"},
    {"ID":"1309","CODE":"OAX","LABEL":"San Pedro Cajonos"},
    {"ID":"1310","CODE":"OAX","LABEL":"San Pedro Comitancillo"},
    {"ID":"1311","CODE":"OAX","LABEL":"San Pedro Coxcaltepec Cantaros"},
    {"ID":"1312","CODE":"OAX","LABEL":"San Pedro Huamelula"},
    {"ID":"1313","CODE":"OAX","LABEL":"San Pedro Huilotepec"},
    {"ID":"1314","CODE":"OAX","LABEL":"San Pedro Ixcatlan"},
    {"ID":"1315","CODE":"OAX","LABEL":"San Pedro Ixtlahuaca"},
    {"ID":"1316","CODE":"OAX","LABEL":"San Pedro Jaltepetongo"},
    {"ID":"1317","CODE":"OAX","LABEL":"San Pedro Jicayan"},
    {"ID":"1318","CODE":"OAX","LABEL":"San Pedro Jocotipac"},
    {"ID":"1319","CODE":"OAX","LABEL":"San Pedro Juchatengo"},
    {"ID":"1320","CODE":"OAX","LABEL":"San Pedro Martir Quiechapa"},
    {"ID":"1321","CODE":"OAX","LABEL":"San Pedro Martir Yucuxaco"},
    {"ID":"1322","CODE":"OAX","LABEL":"San Pedro Martir"},
    {"ID":"1323","CODE":"OAX","LABEL":"San Pedro Mixtepec"},
    {"ID":"1324","CODE":"OAX","LABEL":"San Pedro Mixtepec"},
    {"ID":"1325","CODE":"OAX","LABEL":"San Pedro Molinos"},
    {"ID":"1326","CODE":"OAX","LABEL":"San Pedro Nopala"},
    {"ID":"1327","CODE":"OAX","LABEL":"San Pedro Ocopetatillo"},
    {"ID":"1328","CODE":"OAX","LABEL":"San Pedro Ocotepec"},
    {"ID":"1329","CODE":"OAX","LABEL":"San Pedro Pochutla"},
    {"ID":"1330","CODE":"OAX","LABEL":"San Pedro Quiatoni"},
    {"ID":"1331","CODE":"OAX","LABEL":"San Pedro Sochiapam"},
    {"ID":"1332","CODE":"OAX","LABEL":"San Pedro Tapanatepec"},
    {"ID":"1333","CODE":"OAX","LABEL":"San Pedro Taviche"},
    {"ID":"1334","CODE":"OAX","LABEL":"San Pedro Teozacoalco"},
    {"ID":"1335","CODE":"OAX","LABEL":"San Pedro Teutila"},
    {"ID":"1336","CODE":"OAX","LABEL":"San Pedro Tidaa"},
    {"ID":"1337","CODE":"OAX","LABEL":"San Pedro Topiltepec"},
    {"ID":"1338","CODE":"OAX","LABEL":"San Pedro Totolapam"},
    {"ID":"1339","CODE":"OAX","LABEL":"San Pedro Yaneri"},
    {"ID":"1340","CODE":"OAX","LABEL":"San Pedro Yolox"},
    {"ID":"1341","CODE":"OAX","LABEL":"San Pedro Yucunama"},
    {"ID":"1342","CODE":"OAX","LABEL":"San Pedro el Alto"},
    {"ID":"1343","CODE":"OAX","LABEL":"San Pedro y San Pablo Ayutla"},
    {"ID":"1344","CODE":"OAX","LABEL":"San Pedro y San Pablo Teposcolula"},
    {"ID":"1345","CODE":"OAX","LABEL":"San Pedro y San Pablo Tequixtepec"},
    {"ID":"1346","CODE":"OAX","LABEL":"San Raymundo Jalpan"},
    {"ID":"1347","CODE":"OAX","LABEL":"San Sebastian Abasolo"},
    {"ID":"1348","CODE":"OAX","LABEL":"San Sebastian Coatlan"},
    {"ID":"1349","CODE":"OAX","LABEL":"San Sebastian Ixcapa"},
    {"ID":"1350","CODE":"OAX","LABEL":"San Sebastian Nicananduta"},
    {"ID":"1351","CODE":"OAX","LABEL":"San Sebastian Rio Hondo"},
    {"ID":"1352","CODE":"OAX","LABEL":"San Sebastian Tecomaxtlahuaca"},
    {"ID":"1353","CODE":"OAX","LABEL":"San Sebastian Teitipac"},
    {"ID":"1354","CODE":"OAX","LABEL":"San Sebastian Tutla"},
    {"ID":"1355","CODE":"OAX","LABEL":"San Simon Almolongas"},
    {"ID":"1356","CODE":"OAX","LABEL":"San Simon Zahuatlan"},
    {"ID":"1357","CODE":"OAX","LABEL":"San Vicente Coatlan"},
    {"ID":"1358","CODE":"OAX","LABEL":"San Vicente Lachixio"},
    {"ID":"1359","CODE":"OAX","LABEL":"San Vicente Nunu"},
    {"ID":"1360","CODE":"OAX","LABEL":"Santa Ana Ateixtlahuaca"},
    {"ID":"1361","CODE":"OAX","LABEL":"Santa Ana Cuauhtemoc"},
    {"ID":"1362","CODE":"OAX","LABEL":"Santa Ana Tavela"},
    {"ID":"1363","CODE":"OAX","LABEL":"Santa Ana Tlapacoyan"},
    {"ID":"1364","CODE":"OAX","LABEL":"Santa Ana Yareni"},
    {"ID":"1365","CODE":"OAX","LABEL":"Santa Ana Zegache"},
    {"ID":"1366","CODE":"OAX","LABEL":"Santa Ana del Valle"},
    {"ID":"1367","CODE":"OAX","LABEL":"Santa Ana"},
    {"ID":"1368","CODE":"OAX","LABEL":"Santa Catalina Quieri"},
    {"ID":"1369","CODE":"OAX","LABEL":"Santa Catarina Cuixtla"},
    {"ID":"1370","CODE":"OAX","LABEL":"Santa Catarina Ixtepeji"},
    {"ID":"1371","CODE":"OAX","LABEL":"Santa Catarina Juquila"},
    {"ID":"1372","CODE":"OAX","LABEL":"Santa Catarina Lachatao"},
    {"ID":"1373","CODE":"OAX","LABEL":"Santa Catarina Loxicha"},
    {"ID":"1374","CODE":"OAX","LABEL":"Santa Catarina Mechoacan"},
    {"ID":"1375","CODE":"OAX","LABEL":"Santa Catarina Minas"},
    {"ID":"1376","CODE":"OAX","LABEL":"Santa Catarina Quiane"},
    {"ID":"1377","CODE":"OAX","LABEL":"Santa Catarina Quioquitani"},
    {"ID":"1378","CODE":"OAX","LABEL":"Santa Catarina Tayata"},
    {"ID":"1379","CODE":"OAX","LABEL":"Santa Catarina Ticua"},
    {"ID":"1380","CODE":"OAX","LABEL":"Santa Catarina Yosonotu"},
    {"ID":"1381","CODE":"OAX","LABEL":"Santa Catarina Zapoquila"},
    {"ID":"1382","CODE":"OAX","LABEL":"Santa Cruz Acatepec"},
    {"ID":"1383","CODE":"OAX","LABEL":"Santa Cruz Amilpas"},
    {"ID":"1384","CODE":"OAX","LABEL":"Santa Cruz Itundujia"},
    {"ID":"1385","CODE":"OAX","LABEL":"Santa Cruz Mixtepec"},
    {"ID":"1386","CODE":"OAX","LABEL":"Santa Cruz Nundaco"},
    {"ID":"1387","CODE":"OAX","LABEL":"Santa Cruz Papalutla"},
    {"ID":"1388","CODE":"OAX","LABEL":"Santa Cruz Tacache de Mina"},
    {"ID":"1389","CODE":"OAX","LABEL":"Santa Cruz Tacahua"},
    {"ID":"1390","CODE":"OAX","LABEL":"Santa Cruz Tayata"},
    {"ID":"1391","CODE":"OAX","LABEL":"Santa Cruz Xitla"},
    {"ID":"1392","CODE":"OAX","LABEL":"Santa Cruz Xoxocotlan"},
    {"ID":"1393","CODE":"OAX","LABEL":"Santa Cruz Zenzontepec"},
    {"ID":"1394","CODE":"OAX","LABEL":"Santa Cruz de Bravo"},
    {"ID":"1395","CODE":"OAX","LABEL":"Santa Gertrudis"},
    {"ID":"1396","CODE":"OAX","LABEL":"Santa Ines Yatzeche"},
    {"ID":"1397","CODE":"OAX","LABEL":"Santa Ines de Zaragoza"},
    {"ID":"1398","CODE":"OAX","LABEL":"Santa Ines del Monte"},
    {"ID":"1399","CODE":"OAX","LABEL":"Santa Lucia Miahuatlan"},
    {"ID":"1400","CODE":"OAX","LABEL":"Santa Lucia Monteverde"},
    {"ID":"1401","CODE":"OAX","LABEL":"Santa Lucia Ocotlan"},
    {"ID":"1402","CODE":"OAX","LABEL":"Santa Lucia del Camino"},
    {"ID":"1403","CODE":"OAX","LABEL":"Santa Magdalena Jicotlan"},
    {"ID":"1404","CODE":"OAX","LABEL":"Santa Maria Alotepec"},
    {"ID":"1405","CODE":"OAX","LABEL":"Santa Maria Apazco"},
    {"ID":"1406","CODE":"OAX","LABEL":"Santa Maria Atzompa"},
    {"ID":"1407","CODE":"OAX","LABEL":"Santa Maria Camotlan"},
    {"ID":"1408","CODE":"OAX","LABEL":"Santa Maria Chachoapam"},
    {"ID":"1409","CODE":"OAX","LABEL":"Santa Maria Chilchotla"},
    {"ID":"1410","CODE":"OAX","LABEL":"Santa Maria Chimalapa"},
    {"ID":"1411","CODE":"OAX","LABEL":"Santa Maria Colotepec"},
    {"ID":"1412","CODE":"OAX","LABEL":"Santa Maria Cortijo"},
    {"ID":"1413","CODE":"OAX","LABEL":"Santa Maria Coyotepec"},
    {"ID":"1414","CODE":"OAX","LABEL":"Santa Maria Ecatepec"},
    {"ID":"1415","CODE":"OAX","LABEL":"Santa Maria Guelace"},
    {"ID":"1416","CODE":"OAX","LABEL":"Santa Maria Guienagati"},
    {"ID":"1417","CODE":"OAX","LABEL":"Santa Maria Huatulco"},
    {"ID":"1418","CODE":"OAX","LABEL":"Santa Maria Huazolotitlan"},
    {"ID":"1419","CODE":"OAX","LABEL":"Santa Maria Ipalapa"},
    {"ID":"1420","CODE":"OAX","LABEL":"Santa Maria Ixcatlan"},
    {"ID":"1421","CODE":"OAX","LABEL":"Santa Maria Jacatepec"},
    {"ID":"1422","CODE":"OAX","LABEL":"Santa Maria Jalapa del Marques"},
    {"ID":"1423","CODE":"OAX","LABEL":"Santa Maria Jaltianguis"},
    {"ID":"1424","CODE":"OAX","LABEL":"Santa Maria Lachixio"},
    {"ID":"1425","CODE":"OAX","LABEL":"Santa Maria Mixtequilla"},
    {"ID":"1426","CODE":"OAX","LABEL":"Santa Maria Nativitas"},
    {"ID":"1427","CODE":"OAX","LABEL":"Santa Maria Nduayaco"},
    {"ID":"1428","CODE":"OAX","LABEL":"Santa Maria Ozolotepec"},
    {"ID":"1429","CODE":"OAX","LABEL":"Santa Maria Papalo"},
    {"ID":"1430","CODE":"OAX","LABEL":"Santa Maria Penoles"},
    {"ID":"1431","CODE":"OAX","LABEL":"Santa Maria Petapa"},
    {"ID":"1432","CODE":"OAX","LABEL":"Santa Maria Quiegolani"},
    {"ID":"1433","CODE":"OAX","LABEL":"Santa Maria Sola"},
    {"ID":"1434","CODE":"OAX","LABEL":"Santa Maria Tataltepec"},
    {"ID":"1435","CODE":"OAX","LABEL":"Santa Maria Tecomavaca"},
    {"ID":"1436","CODE":"OAX","LABEL":"Santa Maria Temaxcalapa"},
    {"ID":"1437","CODE":"OAX","LABEL":"Santa Maria Temaxcaltepec"},
    {"ID":"1438","CODE":"OAX","LABEL":"Santa Maria Teopoxco"},
    {"ID":"1439","CODE":"OAX","LABEL":"Santa Maria Tepantlali"},
    {"ID":"1440","CODE":"OAX","LABEL":"Santa Maria Texcatitlan"},
    {"ID":"1441","CODE":"OAX","LABEL":"Santa Maria Tlahuitoltepec"},
    {"ID":"1442","CODE":"OAX","LABEL":"Santa Maria Tlalixtac"},
    {"ID":"1443","CODE":"OAX","LABEL":"Santa Maria Tonameca"},
    {"ID":"1444","CODE":"OAX","LABEL":"Santa Maria Totolapilla"},
    {"ID":"1445","CODE":"OAX","LABEL":"Santa Maria Xadani"},
    {"ID":"1446","CODE":"OAX","LABEL":"Santa Maria Yalina"},
    {"ID":"1447","CODE":"OAX","LABEL":"Santa Maria Yavesia"},
    {"ID":"1448","CODE":"OAX","LABEL":"Santa Maria Yolotepec"},
    {"ID":"1449","CODE":"OAX","LABEL":"Santa Maria Yosoyua"},
    {"ID":"1450","CODE":"OAX","LABEL":"Santa Maria Yucuhiti"},
    {"ID":"1451","CODE":"OAX","LABEL":"Santa Maria Zacatepec"},
    {"ID":"1452","CODE":"OAX","LABEL":"Santa Maria Zaniza"},
    {"ID":"1453","CODE":"OAX","LABEL":"Santa Maria Zoquitlan"},
    {"ID":"1454","CODE":"OAX","LABEL":"Santa Maria del Rosario"},
    {"ID":"1455","CODE":"OAX","LABEL":"Santa Maria del Tule"},
    {"ID":"1456","CODE":"OAX","LABEL":"Santa Maria la Asuncion"},
    {"ID":"1457","CODE":"OAX","LABEL":"Santiago Amoltepec"},
    {"ID":"1458","CODE":"OAX","LABEL":"Santiago Apoala"},
    {"ID":"1459","CODE":"OAX","LABEL":"Santiago Apostol"},
    {"ID":"1460","CODE":"OAX","LABEL":"Santiago Astata"},
    {"ID":"1461","CODE":"OAX","LABEL":"Santiago Atitlan"},
    {"ID":"1462","CODE":"OAX","LABEL":"Santiago Ayuquililla"},
    {"ID":"1463","CODE":"OAX","LABEL":"Santiago Cacaloxtepec"},
    {"ID":"1464","CODE":"OAX","LABEL":"Santiago Camotlan"},
    {"ID":"1465","CODE":"OAX","LABEL":"Santiago Chazumba"},
    {"ID":"1466","CODE":"OAX","LABEL":"Santiago Choapam"},
    {"ID":"1467","CODE":"OAX","LABEL":"Santiago Comaltepec"},
    {"ID":"1468","CODE":"OAX","LABEL":"Santiago Huajolotitlan"},
    {"ID":"1469","CODE":"OAX","LABEL":"Santiago Huauclilla"},
    {"ID":"1470","CODE":"OAX","LABEL":"Santiago Ihuitlan Plumas"},
    {"ID":"1471","CODE":"OAX","LABEL":"Santiago Ixcuintepec"},
    {"ID":"1472","CODE":"OAX","LABEL":"Santiago Ixtayutla"},
    {"ID":"1473","CODE":"OAX","LABEL":"Santiago Jamiltepec"},
    {"ID":"1474","CODE":"OAX","LABEL":"Santiago Jocotepec"},
    {"ID":"1475","CODE":"OAX","LABEL":"Santiago Juxtlahuaca"},
    {"ID":"1476","CODE":"OAX","LABEL":"Santiago Lachiguiri"},
    {"ID":"1477","CODE":"OAX","LABEL":"Santiago Lalopa"},
    {"ID":"1478","CODE":"OAX","LABEL":"Santiago Laollaga"},
    {"ID":"1479","CODE":"OAX","LABEL":"Santiago Laxopa"},
    {"ID":"1480","CODE":"OAX","LABEL":"Santiago Llano Grande"},
    {"ID":"1481","CODE":"OAX","LABEL":"Santiago Matatlan"},
    {"ID":"1482","CODE":"OAX","LABEL":"Santiago Miltepec"},
    {"ID":"1483","CODE":"OAX","LABEL":"Santiago Minas"},
    {"ID":"1484","CODE":"OAX","LABEL":"Santiago Nacaltepec"},
    {"ID":"1485","CODE":"OAX","LABEL":"Santiago Nejapilla"},
    {"ID":"1486","CODE":"OAX","LABEL":"Santiago Niltepec"},
    {"ID":"1487","CODE":"OAX","LABEL":"Santiago Nundiche"},
    {"ID":"1488","CODE":"OAX","LABEL":"Santiago Nuyoo"},
    {"ID":"1489","CODE":"OAX","LABEL":"Santiago Pinotepa Nacional"},
    {"ID":"1490","CODE":"OAX","LABEL":"Santiago Suchilquitongo"},
    {"ID":"1491","CODE":"OAX","LABEL":"Santiago Tamazola"},
    {"ID":"1492","CODE":"OAX","LABEL":"Santiago Tapextla"},
    {"ID":"1493","CODE":"OAX","LABEL":"Santiago Tenango"},
    {"ID":"1494","CODE":"OAX","LABEL":"Santiago Tepetlapa"},
    {"ID":"1495","CODE":"OAX","LABEL":"Santiago Tetepec"},
    {"ID":"1496","CODE":"OAX","LABEL":"Santiago Texcalcingo"},
    {"ID":"1497","CODE":"OAX","LABEL":"Santiago Textitlan"},
    {"ID":"1498","CODE":"OAX","LABEL":"Santiago Tilantongo"},
    {"ID":"1499","CODE":"OAX","LABEL":"Santiago Tillo"},
    {"ID":"1500","CODE":"OAX","LABEL":"Santiago Tlazoyaltepec"},
    {"ID":"1501","CODE":"OAX","LABEL":"Santiago Xanica"},
    {"ID":"1502","CODE":"OAX","LABEL":"Santiago Xiacui"},
    {"ID":"1503","CODE":"OAX","LABEL":"Santiago Yaitepec"},
    {"ID":"1504","CODE":"OAX","LABEL":"Santiago Yaveo"},
    {"ID":"1505","CODE":"OAX","LABEL":"Santiago Yolomecatl"},
    {"ID":"1506","CODE":"OAX","LABEL":"Santiago Yosondua"},
    {"ID":"1507","CODE":"OAX","LABEL":"Santiago Yucuyachi"},
    {"ID":"1508","CODE":"OAX","LABEL":"Santiago Zacatepec"},
    {"ID":"1509","CODE":"OAX","LABEL":"Santiago Zoochila"},
    {"ID":"1510","CODE":"OAX","LABEL":"Santiago del Rio"},
    {"ID":"1511","CODE":"OAX","LABEL":"Santo Domingo Albarradas"},
    {"ID":"1512","CODE":"OAX","LABEL":"Santo Domingo Armenta"},
    {"ID":"1513","CODE":"OAX","LABEL":"Santo Domingo Chihuitan"},
    {"ID":"1514","CODE":"OAX","LABEL":"Santo Domingo Ingenio"},
    {"ID":"1515","CODE":"OAX","LABEL":"Santo Domingo Ixcatlan"},
    {"ID":"1516","CODE":"OAX","LABEL":"Santo Domingo Nuxaa"},
    {"ID":"1517","CODE":"OAX","LABEL":"Santo Domingo Ozolotepec"},
    {"ID":"1518","CODE":"OAX","LABEL":"Santo Domingo Petapa"},
    {"ID":"1519","CODE":"OAX","LABEL":"Santo Domingo Roayaga"},
    {"ID":"1520","CODE":"OAX","LABEL":"Santo Domingo Tehuantepec"},
    {"ID":"1521","CODE":"OAX","LABEL":"Santo Domingo Teojomulco"},
    {"ID":"1522","CODE":"OAX","LABEL":"Santo Domingo Tepuxtepec"},
    {"ID":"1523","CODE":"OAX","LABEL":"Santo Domingo Tlatayapam"},
    {"ID":"1524","CODE":"OAX","LABEL":"Santo Domingo Tomaltepec"},
    {"ID":"1525","CODE":"OAX","LABEL":"Santo Domingo Tonala"},
    {"ID":"1526","CODE":"OAX","LABEL":"Santo Domingo Tonaltepec"},
    {"ID":"1527","CODE":"OAX","LABEL":"Santo Domingo Xagacia"},
    {"ID":"1528","CODE":"OAX","LABEL":"Santo Domingo Yanhuitlan"},
    {"ID":"1529","CODE":"OAX","LABEL":"Santo Domingo Yodohino"},
    {"ID":"1530","CODE":"OAX","LABEL":"Santo Domingo Zanatepec"},
    {"ID":"1531","CODE":"OAX","LABEL":"Santo Domingo de Morelos"},
    {"ID":"1532","CODE":"OAX","LABEL":"Santo Tomas Jalieza"},
    {"ID":"1533","CODE":"OAX","LABEL":"Santo Tomas Mazaltepec"},
    {"ID":"1534","CODE":"OAX","LABEL":"Santo Tomas Ocotepec"},
    {"ID":"1535","CODE":"OAX","LABEL":"Santo Tomas Tamazulapan"},
    {"ID":"1536","CODE":"OAX","LABEL":"Santos Reyes Nopala"},
    {"ID":"1537","CODE":"OAX","LABEL":"Santos Reyes Papalo"},
    {"ID":"1538","CODE":"OAX","LABEL":"Santos Reyes Tepejillo"},
    {"ID":"1539","CODE":"OAX","LABEL":"Santos Reyes Yucuna"},
    {"ID":"1540","CODE":"OAX","LABEL":"Silacayoapam"},
    {"ID":"1541","CODE":"OAX","LABEL":"Sitio de Xitlapehua"},
    {"ID":"1542","CODE":"OAX","LABEL":"Soledad Etla"},
    {"ID":"1543","CODE":"OAX","LABEL":"Tamazulapam del Espiritu Santo"},
    {"ID":"1544","CODE":"OAX","LABEL":"Tanetze de Zaragoza"},
    {"ID":"1545","CODE":"OAX","LABEL":"Taniche"},
    {"ID":"1546","CODE":"OAX","LABEL":"Tataltepec de Valdes"},
    {"ID":"1547","CODE":"OAX","LABEL":"Teococuilco de Marcos Perez"},
    {"ID":"1548","CODE":"OAX","LABEL":"Teotitlan de Flores Magon"},
    {"ID":"1549","CODE":"OAX","LABEL":"Teotitlan del Valle"},
    {"ID":"1550","CODE":"OAX","LABEL":"Teotongo"},
    {"ID":"1551","CODE":"OAX","LABEL":"Tepelmeme Villa de Morelos"},
    {"ID":"1552","CODE":"OAX","LABEL":"Tlacolula de Matamoros"},
    {"ID":"1553","CODE":"OAX","LABEL":"Tlacotepec Plumas"},
    {"ID":"1554","CODE":"OAX","LABEL":"Tlalixtac de Cabrera"},
    {"ID":"1555","CODE":"OAX","LABEL":"Totontepec Villa de Morelos"},
    {"ID":"1556","CODE":"OAX","LABEL":"Trinidad Zaachila"},
    {"ID":"1557","CODE":"OAX","LABEL":"Union Hidalgo"},
    {"ID":"1558","CODE":"OAX","LABEL":"Valerio Trujano"},
    {"ID":"1559","CODE":"OAX","LABEL":"Villa Diaz Ordaz"},
    {"ID":"1560","CODE":"OAX","LABEL":"Villa Hidalgo"},
    {"ID":"1561","CODE":"OAX","LABEL":"Villa Sola de Vega"},
    {"ID":"1562","CODE":"OAX","LABEL":"Villa Talea de Castro"},
    {"ID":"1563","CODE":"OAX","LABEL":"Villa Tejupam de la Union"},
    {"ID":"1564","CODE":"OAX","LABEL":"Villa de Chilapa de Diaz"},
    {"ID":"1565","CODE":"OAX","LABEL":"Villa de Etla"},
    {"ID":"1566","CODE":"OAX","LABEL":"Villa de Tamazulapam del Progreso"},
    {"ID":"1567","CODE":"OAX","LABEL":"Villa de Tututepec"},
    {"ID":"1568","CODE":"OAX","LABEL":"Villa de Zaachila"},
    {"ID":"1569","CODE":"OAX","LABEL":"Yaxe"},
    {"ID":"1570","CODE":"OAX","LABEL":"Yogana"},
    {"ID":"1571","CODE":"OAX","LABEL":"Yutanduchi de Guerrero"},
    {"ID":"1572","CODE":"OAX","LABEL":"Zapotitlan Lagunas"},
    {"ID":"1573","CODE":"OAX","LABEL":"Zapotitlan Palmas"},
    {"ID":"1574","CODE":"OAX","LABEL":"Zimatlan de Alvarez"},
    {"ID":"1575","CODE":"PUE","LABEL":"Acajete"},
    {"ID":"1576","CODE":"PUE","LABEL":"Acateno"},
    {"ID":"1577","CODE":"PUE","LABEL":"Acatlan"},
    {"ID":"1578","CODE":"PUE","LABEL":"Acatzingo"},
    {"ID":"1579","CODE":"PUE","LABEL":"Acteopan"},
    {"ID":"1580","CODE":"PUE","LABEL":"Ahuacatlan"},
    {"ID":"1581","CODE":"PUE","LABEL":"Ahuatlan"},
    {"ID":"1582","CODE":"PUE","LABEL":"Ahuazotepec"},
    {"ID":"1583","CODE":"PUE","LABEL":"Ahuehuetitla"},
    {"ID":"1584","CODE":"PUE","LABEL":"Ajalpan"},
    {"ID":"1585","CODE":"PUE","LABEL":"Albino Zertuche"},
    {"ID":"1586","CODE":"PUE","LABEL":"Aljojuca"},
    {"ID":"1587","CODE":"PUE","LABEL":"Altepexi"},
    {"ID":"1588","CODE":"PUE","LABEL":"Amixtlan"},
    {"ID":"1589","CODE":"PUE","LABEL":"Amozoc"},
    {"ID":"1590","CODE":"PUE","LABEL":"Aquixtla"},
    {"ID":"1591","CODE":"PUE","LABEL":"Atempan"},
    {"ID":"1592","CODE":"PUE","LABEL":"Atexcal"},
    {"ID":"1593","CODE":"PUE","LABEL":"Atlequizayan"},
    {"ID":"1594","CODE":"PUE","LABEL":"Atlixco"},
    {"ID":"1595","CODE":"PUE","LABEL":"Atoyatempan"},
    {"ID":"1596","CODE":"PUE","LABEL":"Atzala"},
    {"ID":"1597","CODE":"PUE","LABEL":"Atzitzihuacan"},
    {"ID":"1598","CODE":"PUE","LABEL":"Atzitzintla"},
    {"ID":"1599","CODE":"PUE","LABEL":"Axutla"},
    {"ID":"1600","CODE":"PUE","LABEL":"Ayotoxco de Guerrero"},
    {"ID":"1601","CODE":"PUE","LABEL":"Calpan"},
    {"ID":"1602","CODE":"PUE","LABEL":"Caltepec"},
    {"ID":"1603","CODE":"PUE","LABEL":"Camocuautla"},
    {"ID":"1604","CODE":"PUE","LABEL":"Canada Morelos"},
    {"ID":"1605","CODE":"PUE","LABEL":"Caxhuacan"},
    {"ID":"1606","CODE":"PUE","LABEL":"Chalchicomula de Sesma"},
    {"ID":"1607","CODE":"PUE","LABEL":"Chapulco"},
    {"ID":"1608","CODE":"PUE","LABEL":"Chiautla"},
    {"ID":"1609","CODE":"PUE","LABEL":"Chiautzingo"},
    {"ID":"1610","CODE":"PUE","LABEL":"Chichiquila"},
    {"ID":"1611","CODE":"PUE","LABEL":"Chiconcuautla"},
    {"ID":"1612","CODE":"PUE","LABEL":"Chietla"},
    {"ID":"1613","CODE":"PUE","LABEL":"Chigmecatitlan"},
    {"ID":"1614","CODE":"PUE","LABEL":"Chignahuapan"},
    {"ID":"1615","CODE":"PUE","LABEL":"Chignautla"},
    {"ID":"1616","CODE":"PUE","LABEL":"Chila de la Sal"},
    {"ID":"1617","CODE":"PUE","LABEL":"Chila"},
    {"ID":"1618","CODE":"PUE","LABEL":"Chilchotla"},
    {"ID":"1619","CODE":"PUE","LABEL":"Chinantla"},
    {"ID":"1620","CODE":"PUE","LABEL":"Coatepec"},
    {"ID":"1621","CODE":"PUE","LABEL":"Coatzingo"},
    {"ID":"1622","CODE":"PUE","LABEL":"Cohetzala"},
    {"ID":"1623","CODE":"PUE","LABEL":"Cohuecan"},
    {"ID":"1624","CODE":"PUE","LABEL":"Coronango"},
    {"ID":"1625","CODE":"PUE","LABEL":"Coxcatlan"},
    {"ID":"1626","CODE":"PUE","LABEL":"Coyomeapan"},
    {"ID":"1627","CODE":"PUE","LABEL":"Coyotepec"},
    {"ID":"1628","CODE":"PUE","LABEL":"Cuapiaxtla de Madero"},
    {"ID":"1629","CODE":"PUE","LABEL":"Cuautempan"},
    {"ID":"1630","CODE":"PUE","LABEL":"Cuautinchan"},
    {"ID":"1631","CODE":"PUE","LABEL":"Cuautlancingo"},
    {"ID":"1632","CODE":"PUE","LABEL":"Cuayuca de Andrade"},
    {"ID":"1633","CODE":"PUE","LABEL":"Cuetzalan del Progreso"},
    {"ID":"1634","CODE":"PUE","LABEL":"Cuyoaco"},
    {"ID":"1635","CODE":"PUE","LABEL":"Domingo Arenas"},
    {"ID":"1636","CODE":"PUE","LABEL":"Eloxochitlan"},
    {"ID":"1637","CODE":"PUE","LABEL":"Epatlan"},
    {"ID":"1638","CODE":"PUE","LABEL":"Esperanza"},
    {"ID":"1639","CODE":"PUE","LABEL":"Francisco Z. Mena"},
    {"ID":"1640","CODE":"PUE","LABEL":"General Felipe Angeles"},
    {"ID":"1641","CODE":"PUE","LABEL":"Guadalupe Victoria"},
    {"ID":"1642","CODE":"PUE","LABEL":"Guadalupe"},
    {"ID":"1643","CODE":"PUE","LABEL":"Hermenegildo Galeana"},
    {"ID":"1644","CODE":"PUE","LABEL":"Honey"},
    {"ID":"1645","CODE":"PUE","LABEL":"Huaquechula"},
    {"ID":"1646","CODE":"PUE","LABEL":"Huatlatlauca"},
    {"ID":"1647","CODE":"PUE","LABEL":"Huauchinango"},
    {"ID":"1648","CODE":"PUE","LABEL":"Huehuetla"},
    {"ID":"1649","CODE":"PUE","LABEL":"Huehuetlan el Chico"},
    {"ID":"1650","CODE":"PUE","LABEL":"Huehuetlan el Grande"},
    {"ID":"1651","CODE":"PUE","LABEL":"Huejotzingo"},
    {"ID":"1652","CODE":"PUE","LABEL":"Hueyapan"},
    {"ID":"1653","CODE":"PUE","LABEL":"Hueytamalco"},
    {"ID":"1654","CODE":"PUE","LABEL":"Hueytlalpan"},
    {"ID":"1655","CODE":"PUE","LABEL":"Huitzilan de Serdan"},
    {"ID":"1656","CODE":"PUE","LABEL":"Huitziltepec"},
    {"ID":"1657","CODE":"PUE","LABEL":"Ixcamilpa de Guerrero"},
    {"ID":"1658","CODE":"PUE","LABEL":"Ixcaquixtla"},
    {"ID":"1659","CODE":"PUE","LABEL":"Ixtacamaxtitlan"},
    {"ID":"1660","CODE":"PUE","LABEL":"Ixtepec"},
    {"ID":"1661","CODE":"PUE","LABEL":"Izucar de Matamoros"},
    {"ID":"1662","CODE":"PUE","LABEL":"Jalpan"},
    {"ID":"1663","CODE":"PUE","LABEL":"Jolalpan"},
    {"ID":"1664","CODE":"PUE","LABEL":"Jonotla"},
    {"ID":"1665","CODE":"PUE","LABEL":"Jopala"},
    {"ID":"1666","CODE":"PUE","LABEL":"Juan C. Bonilla"},
    {"ID":"1667","CODE":"PUE","LABEL":"Juan Galindo"},
    {"ID":"1668","CODE":"PUE","LABEL":"Juan N. Mendez"},
    {"ID":"1669","CODE":"PUE","LABEL":"La Magdalena Tlatlauquitepec"},
    {"ID":"1670","CODE":"PUE","LABEL":"Lafragua"},
    {"ID":"1671","CODE":"PUE","LABEL":"Libres"},
    {"ID":"1672","CODE":"PUE","LABEL":"Los Reyes de Juarez"},
    {"ID":"1673","CODE":"PUE","LABEL":"Mazapiltepec de Juarez"},
    {"ID":"1674","CODE":"PUE","LABEL":"Mixtla"},
    {"ID":"1675","CODE":"PUE","LABEL":"Molcaxac"},
    {"ID":"1676","CODE":"PUE","LABEL":"Naupan"},
    {"ID":"1677","CODE":"PUE","LABEL":"Nauzontla"},
    {"ID":"1678","CODE":"PUE","LABEL":"Nealtican"},
    {"ID":"1679","CODE":"PUE","LABEL":"Nicolas Bravo"},
    {"ID":"1680","CODE":"PUE","LABEL":"Nopalucan"},
    {"ID":"1681","CODE":"PUE","LABEL":"Ocotepec"},
    {"ID":"1682","CODE":"PUE","LABEL":"Ocoyucan"},
    {"ID":"1683","CODE":"PUE","LABEL":"Olintla"},
    {"ID":"1684","CODE":"PUE","LABEL":"Oriental"},
    {"ID":"1685","CODE":"PUE","LABEL":"Pahuatlan"},
    {"ID":"1686","CODE":"PUE","LABEL":"Palmar de Bravo"},
    {"ID":"1687","CODE":"PUE","LABEL":"Pantepec"},
    {"ID":"1688","CODE":"PUE","LABEL":"Petlalcingo"},
    {"ID":"1689","CODE":"PUE","LABEL":"Piaxtla"},
    {"ID":"1690","CODE":"PUE","LABEL":"Puebla"},
    {"ID":"1691","CODE":"PUE","LABEL":"Quecholac"},
    {"ID":"1692","CODE":"PUE","LABEL":"Quimixtlan"},
    {"ID":"1693","CODE":"PUE","LABEL":"Rafael Lara Grajales"},
    {"ID":"1694","CODE":"PUE","LABEL":"San Andres Cholula"},
    {"ID":"1695","CODE":"PUE","LABEL":"San Antonio Canada"},
    {"ID":"1696","CODE":"PUE","LABEL":"San Diego la Mesa Tochimiltzingo"},
    {"ID":"1697","CODE":"PUE","LABEL":"San Felipe Teotlalcingo"},
    {"ID":"1698","CODE":"PUE","LABEL":"San Felipe Tepatlan"},
    {"ID":"1699","CODE":"PUE","LABEL":"San Gabriel Chilac"},
    {"ID":"1700","CODE":"PUE","LABEL":"San Gregorio Atzompa"},
    {"ID":"1701","CODE":"PUE","LABEL":"San Jeronimo Tecuanipan"},
    {"ID":"1702","CODE":"PUE","LABEL":"San Jeronimo Xayacatlan"},
    {"ID":"1703","CODE":"PUE","LABEL":"San Jose Chiapa"},
    {"ID":"1704","CODE":"PUE","LABEL":"San Jose Miahuatlan"},
    {"ID":"1705","CODE":"PUE","LABEL":"San Juan Atenco"},
    {"ID":"1706","CODE":"PUE","LABEL":"San Juan Atzompa"},
    {"ID":"1707","CODE":"PUE","LABEL":"San Martin Texmelucan"},
    {"ID":"1708","CODE":"PUE","LABEL":"San Martin Totoltepec"},
    {"ID":"1709","CODE":"PUE","LABEL":"San Matias Tlalancaleca"},
    {"ID":"1710","CODE":"PUE","LABEL":"San Miguel Ixitlan"},
    {"ID":"1711","CODE":"PUE","LABEL":"San Miguel Xoxtla"},
    {"ID":"1712","CODE":"PUE","LABEL":"San Nicolas Buenos Aires"},
    {"ID":"1713","CODE":"PUE","LABEL":"San Nicolas de los Ranchos"},
    {"ID":"1714","CODE":"PUE","LABEL":"San Pablo Anicano"},
    {"ID":"1715","CODE":"PUE","LABEL":"San Pedro Cholula"},
    {"ID":"1716","CODE":"PUE","LABEL":"San Pedro Yeloixtlahuaca"},
    {"ID":"1717","CODE":"PUE","LABEL":"San Salvador Huixcolotla"},
    {"ID":"1718","CODE":"PUE","LABEL":"San Salvador el Seco"},
    {"ID":"1719","CODE":"PUE","LABEL":"San Salvador el Verde"},
    {"ID":"1720","CODE":"PUE","LABEL":"San Sebastian Tlacotepec"},
    {"ID":"1721","CODE":"PUE","LABEL":"Santa Catarina Tlaltempan"},
    {"ID":"1722","CODE":"PUE","LABEL":"Santa Ines Ahuatempan"},
    {"ID":"1723","CODE":"PUE","LABEL":"Santa Isabel Cholula"},
    {"ID":"1724","CODE":"PUE","LABEL":"Santiago Miahuatlan"},
    {"ID":"1725","CODE":"PUE","LABEL":"Santo Tomas Hueyotlipan"},
    {"ID":"1726","CODE":"PUE","LABEL":"Soltepec"},
    {"ID":"1727","CODE":"PUE","LABEL":"Tecali de Herrera"},
    {"ID":"1728","CODE":"PUE","LABEL":"Tecamachalco"},
    {"ID":"1729","CODE":"PUE","LABEL":"Tecomatlan"},
    {"ID":"1730","CODE":"PUE","LABEL":"Tehuacan"},
    {"ID":"1731","CODE":"PUE","LABEL":"Tehuitzingo"},
    {"ID":"1732","CODE":"PUE","LABEL":"Tenampulco"},
    {"ID":"1733","CODE":"PUE","LABEL":"Teopantlan"},
    {"ID":"1734","CODE":"PUE","LABEL":"Teotlalco"},
    {"ID":"1735","CODE":"PUE","LABEL":"Tepanco de Lopez"},
    {"ID":"1736","CODE":"PUE","LABEL":"Tepango de Rodriguez"},
    {"ID":"1737","CODE":"PUE","LABEL":"Tepatlaxco de Hidalgo"},
    {"ID":"1738","CODE":"PUE","LABEL":"Tepeaca"},
    {"ID":"1739","CODE":"PUE","LABEL":"Tepemaxalco"},
    {"ID":"1740","CODE":"PUE","LABEL":"Tepeojuma"},
    {"ID":"1741","CODE":"PUE","LABEL":"Tepetzintla"},
    {"ID":"1742","CODE":"PUE","LABEL":"Tepexco"},
    {"ID":"1743","CODE":"PUE","LABEL":"Tepexi de Rodriguez"},
    {"ID":"1744","CODE":"PUE","LABEL":"Tepeyahualco de Cuauhtemoc"},
    {"ID":"1745","CODE":"PUE","LABEL":"Tepeyahualco"},
    {"ID":"1746","CODE":"PUE","LABEL":"Tetela de Ocampo"},
    {"ID":"1747","CODE":"PUE","LABEL":"Teteles de Avila Castillo"},
    {"ID":"1748","CODE":"PUE","LABEL":"Teziutlan"},
    {"ID":"1749","CODE":"PUE","LABEL":"Tianguismanalco"},
    {"ID":"1750","CODE":"PUE","LABEL":"Tilapa"},
    {"ID":"1751","CODE":"PUE","LABEL":"Tlachichuca"},
    {"ID":"1752","CODE":"PUE","LABEL":"Tlacotepec de Benito Juarez"},
    {"ID":"1753","CODE":"PUE","LABEL":"Tlacuilotepec"},
    {"ID":"1754","CODE":"PUE","LABEL":"Tlahuapan"},
    {"ID":"1755","CODE":"PUE","LABEL":"Tlaltenango"},
    {"ID":"1756","CODE":"PUE","LABEL":"Tlanepantla"},
    {"ID":"1757","CODE":"PUE","LABEL":"Tlaola"},
    {"ID":"1758","CODE":"PUE","LABEL":"Tlapacoya"},
    {"ID":"1759","CODE":"PUE","LABEL":"Tlapanala"},
    {"ID":"1760","CODE":"PUE","LABEL":"Tlatlauquitepec"},
    {"ID":"1761","CODE":"PUE","LABEL":"Tlaxco"},
    {"ID":"1762","CODE":"PUE","LABEL":"Tochimilco"},
    {"ID":"1763","CODE":"PUE","LABEL":"Tochtepec"},
    {"ID":"1764","CODE":"PUE","LABEL":"Totoltepec de Guerrero"},
    {"ID":"1765","CODE":"PUE","LABEL":"Tulcingo"},
    {"ID":"1766","CODE":"PUE","LABEL":"Tuzamapan de Galeana"},
    {"ID":"1767","CODE":"PUE","LABEL":"Tzicatlacoyan"},
    {"ID":"1768","CODE":"PUE","LABEL":"Venustiano Carranza"},
    {"ID":"1769","CODE":"PUE","LABEL":"Vicente Guerrero"},
    {"ID":"1770","CODE":"PUE","LABEL":"Xayacatlan de Bravo"},
    {"ID":"1771","CODE":"PUE","LABEL":"Xicotepec"},
    {"ID":"1772","CODE":"PUE","LABEL":"Xicotlan"},
    {"ID":"1773","CODE":"PUE","LABEL":"Xiutetelco"},
    {"ID":"1774","CODE":"PUE","LABEL":"Xochiapulco"},
    {"ID":"1775","CODE":"PUE","LABEL":"Xochiltepec"},
    {"ID":"1776","CODE":"PUE","LABEL":"Xochitlan Todos Santos"},
    {"ID":"1777","CODE":"PUE","LABEL":"Xochitlan de Vicente Suarez"},
    {"ID":"1778","CODE":"PUE","LABEL":"Yaonahuac"},
    {"ID":"1779","CODE":"PUE","LABEL":"Yehualtepec"},
    {"ID":"1780","CODE":"PUE","LABEL":"Zacapala"},
    {"ID":"1781","CODE":"PUE","LABEL":"Zacapoaxtla"},
    {"ID":"1782","CODE":"PUE","LABEL":"Zacatlan"},
    {"ID":"1783","CODE":"PUE","LABEL":"Zapotitlan de Mendez"},
    {"ID":"1784","CODE":"PUE","LABEL":"Zapotitlan"},
    {"ID":"1785","CODE":"PUE","LABEL":"Zaragoza"},
    {"ID":"1786","CODE":"PUE","LABEL":"Zautla"},
    {"ID":"1787","CODE":"PUE","LABEL":"Zihuateutla"},
    {"ID":"1788","CODE":"PUE","LABEL":"Zinacatepec"},
    {"ID":"1789","CODE":"PUE","LABEL":"Zongozotla"},
    {"ID":"1790","CODE":"PUE","LABEL":"Zoquiapan"},
    {"ID":"1791","CODE":"PUE","LABEL":"Zoquitlan"},
    {"ID":"1792","CODE":"QRO","LABEL":"Amealco de Bonfil"},
    {"ID":"1793","CODE":"QRO","LABEL":"Arroyo Seco"},
    {"ID":"1794","CODE":"QRO","LABEL":"Cadereyta de Montes"},
    {"ID":"1795","CODE":"QRO","LABEL":"Colon"},
    {"ID":"1796","CODE":"QRO","LABEL":"Corregidora"},
    {"ID":"1797","CODE":"QRO","LABEL":"El Marques"},
    {"ID":"1798","CODE":"QRO","LABEL":"Ezequiel Montes"},
    {"ID":"1799","CODE":"QRO","LABEL":"Huimilpan"},
    {"ID":"1800","CODE":"QRO","LABEL":"Jalpan de Serra"},
    {"ID":"1801","CODE":"QRO","LABEL":"Landa de Matamoros"},
    {"ID":"1802","CODE":"QRO","LABEL":"Pedro Escobedo"},
    {"ID":"1803","CODE":"QRO","LABEL":"Penamiller"},
    {"ID":"1804","CODE":"QRO","LABEL":"Pinal de Amoles"},
    {"ID":"1805","CODE":"QRO","LABEL":"Queretaro"},
    {"ID":"1806","CODE":"QRO","LABEL":"San Joaquin"},
    {"ID":"1807","CODE":"QRO","LABEL":"San Juan del Rio"},
    {"ID":"1808","CODE":"QRO","LABEL":"Tequisquiapan"},
    {"ID":"1809","CODE":"QRO","LABEL":"Toliman"},
    {"ID":"1810","CODE":"QR","LABEL":"Bacalar"},
    {"ID":"1811","CODE":"QR","LABEL":"Benito Juarez"},
    {"ID":"1812","CODE":"QR","LABEL":"Cozumel"},
    {"ID":"1813","CODE":"QR","LABEL":"Felipe Carrillo Puerto"},
    {"ID":"1814","CODE":"QR","LABEL":"Isla Mujeres"},
    {"ID":"1815","CODE":"QR","LABEL":"Jose Maria Morelos"},
    {"ID":"1816","CODE":"QR","LABEL":"Lazaro Cardenas"},
    {"ID":"1817","CODE":"QR","LABEL":"Othon P. Blanco"},
    {"ID":"1818","CODE":"QR","LABEL":"Puerto Morelos"},
    {"ID":"1819","CODE":"QR","LABEL":"Solidaridad"},
    {"ID":"1820","CODE":"QR","LABEL":"Tulum"},
    {"ID":"1821","CODE":"SLP","LABEL":"Ahualulco"},
    {"ID":"1822","CODE":"SLP","LABEL":"Alaquines"},
    {"ID":"1823","CODE":"SLP","LABEL":"Aquismon"},
    {"ID":"1824","CODE":"SLP","LABEL":"Armadillo de los Infante"},
    {"ID":"1825","CODE":"SLP","LABEL":"Axtla de Terrazas"},
    {"ID":"1826","CODE":"SLP","LABEL":"Cardenas"},
    {"ID":"1827","CODE":"SLP","LABEL":"Catorce"},
    {"ID":"1828","CODE":"SLP","LABEL":"Cedral"},
    {"ID":"1829","CODE":"SLP","LABEL":"Cerritos"},
    {"ID":"1830","CODE":"SLP","LABEL":"Cerro de San Pedro"},
    {"ID":"1831","CODE":"SLP","LABEL":"Charcas"},
    {"ID":"1832","CODE":"SLP","LABEL":"Ciudad Fernandez"},
    {"ID":"1833","CODE":"SLP","LABEL":"Ciudad Valles"},
    {"ID":"1834","CODE":"SLP","LABEL":"Ciudad del Maiz"},
    {"ID":"1835","CODE":"SLP","LABEL":"Coxcatlan"},
    {"ID":"1836","CODE":"SLP","LABEL":"Ebano"},
    {"ID":"1837","CODE":"SLP","LABEL":"El Naranjo"},
    {"ID":"1838","CODE":"SLP","LABEL":"Guadalcazar"},
    {"ID":"1839","CODE":"SLP","LABEL":"Huehuetlan"},
    {"ID":"1840","CODE":"SLP","LABEL":"Lagunillas"},
    {"ID":"1841","CODE":"SLP","LABEL":"Matehuala"},
    {"ID":"1842","CODE":"SLP","LABEL":"Matlapa"},
    {"ID":"1843","CODE":"SLP","LABEL":"Mexquitic de Carmona"},
    {"ID":"1844","CODE":"SLP","LABEL":"Moctezuma"},
    {"ID":"1845","CODE":"SLP","LABEL":"Rayon"},
    {"ID":"1846","CODE":"SLP","LABEL":"Rioverde"},
    {"ID":"1847","CODE":"SLP","LABEL":"Salinas"},
    {"ID":"1848","CODE":"SLP","LABEL":"San Antonio"},
    {"ID":"1849","CODE":"SLP","LABEL":"San Ciro de Acosta"},
    {"ID":"1850","CODE":"SLP","LABEL":"San Luis Potosi"},
    {"ID":"1851","CODE":"SLP","LABEL":"San Martin Chalchicuautla"},
    {"ID":"1852","CODE":"SLP","LABEL":"San Nicolas Tolentino"},
    {"ID":"1853","CODE":"SLP","LABEL":"San Vicente Tancuayalab"},
    {"ID":"1854","CODE":"SLP","LABEL":"Santa Catarina"},
    {"ID":"1855","CODE":"SLP","LABEL":"Santa Maria del Rio"},
    {"ID":"1856","CODE":"SLP","LABEL":"Santo Domingo"},
    {"ID":"1857","CODE":"SLP","LABEL":"Soledad de Graciano Sanchez"},
    {"ID":"1858","CODE":"SLP","LABEL":"Tamasopo"},
    {"ID":"1859","CODE":"SLP","LABEL":"Tamazunchale"},
    {"ID":"1860","CODE":"SLP","LABEL":"Tampacan"},
    {"ID":"1861","CODE":"SLP","LABEL":"Tampamolon Corona"},
    {"ID":"1862","CODE":"SLP","LABEL":"Tamuin"},
    {"ID":"1863","CODE":"SLP","LABEL":"Tancanhuitz"},
    {"ID":"1864","CODE":"SLP","LABEL":"Tanlajas"},
    {"ID":"1865","CODE":"SLP","LABEL":"Tanquian de Escobedo"},
    {"ID":"1866","CODE":"SLP","LABEL":"Tierra Nueva"},
    {"ID":"1867","CODE":"SLP","LABEL":"Vanegas"},
    {"ID":"1868","CODE":"SLP","LABEL":"Venado"},
    {"ID":"1869","CODE":"SLP","LABEL":"Villa Hidalgo"},
    {"ID":"1870","CODE":"SLP","LABEL":"Villa Juarez"},
    {"ID":"1871","CODE":"SLP","LABEL":"Villa de Arista"},
    {"ID":"1872","CODE":"SLP","LABEL":"Villa de Arriaga"},
    {"ID":"1873","CODE":"SLP","LABEL":"Villa de Guadalupe"},
    {"ID":"1874","CODE":"SLP","LABEL":"Villa de Ramos"},
    {"ID":"1875","CODE":"SLP","LABEL":"Villa de Reyes"},
    {"ID":"1876","CODE":"SLP","LABEL":"Villa de la Paz"},
    {"ID":"1877","CODE":"SLP","LABEL":"Xilitla"},
    {"ID":"1878","CODE":"SLP","LABEL":"Zaragoza"},
    {"ID":"1879","CODE":"SIN","LABEL":"Ahome"},
    {"ID":"1880","CODE":"SIN","LABEL":"Angostura"},
    {"ID":"1881","CODE":"SIN","LABEL":"Badiraguato"},
    {"ID":"1882","CODE":"SIN","LABEL":"Choix"},
    {"ID":"1883","CODE":"SIN","LABEL":"Concordia"},
    {"ID":"1884","CODE":"SIN","LABEL":"Cosala"},
    {"ID":"1885","CODE":"SIN","LABEL":"Culiacan"},
    {"ID":"1886","CODE":"SIN","LABEL":"El Fuerte"},
    {"ID":"1887","CODE":"SIN","LABEL":"Elota"},
    {"ID":"1888","CODE":"SIN","LABEL":"Escuinapa"},
    {"ID":"1889","CODE":"SIN","LABEL":"Guasave"},
    {"ID":"1890","CODE":"SIN","LABEL":"Mazatlan"},
    {"ID":"1891","CODE":"SIN","LABEL":"Mocorito"},
    {"ID":"1892","CODE":"SIN","LABEL":"Navolato"},
    {"ID":"1893","CODE":"SIN","LABEL":"Rosario"},
    {"ID":"1894","CODE":"SIN","LABEL":"Salvador Alvarado"},
    {"ID":"1895","CODE":"SIN","LABEL":"San Ignacio"},
    {"ID":"1896","CODE":"SIN","LABEL":"Sinaloa"},
    {"ID":"1897","CODE":"SON","LABEL":"Aconchi"},
    {"ID":"1898","CODE":"SON","LABEL":"Agua Prieta"},
    {"ID":"1899","CODE":"SON","LABEL":"Alamos"},
    {"ID":"1900","CODE":"SON","LABEL":"Altar"},
    {"ID":"1901","CODE":"SON","LABEL":"Arivechi"},
    {"ID":"1902","CODE":"SON","LABEL":"Arizpe"},
    {"ID":"1903","CODE":"SON","LABEL":"Atil"},
    {"ID":"1904","CODE":"SON","LABEL":"Bacadehuachi"},
    {"ID":"1905","CODE":"SON","LABEL":"Bacanora"},
    {"ID":"1906","CODE":"SON","LABEL":"Bacerac"},
    {"ID":"1907","CODE":"SON","LABEL":"Bacoachi"},
    {"ID":"1908","CODE":"SON","LABEL":"Bacum"},
    {"ID":"1909","CODE":"SON","LABEL":"Banamichi"},
    {"ID":"1910","CODE":"SON","LABEL":"Baviacora"},
    {"ID":"1911","CODE":"SON","LABEL":"Bavispe"},
    {"ID":"1912","CODE":"SON","LABEL":"Benito Juarez"},
    {"ID":"1913","CODE":"SON","LABEL":"Benjamin Hill"},
    {"ID":"1914","CODE":"SON","LABEL":"Caborca"},
    {"ID":"1915","CODE":"SON","LABEL":"Cajeme"},
    {"ID":"1916","CODE":"SON","LABEL":"Cananea"},
    {"ID":"1917","CODE":"SON","LABEL":"Carbo"},
    {"ID":"1918","CODE":"SON","LABEL":"Cucurpe"},
    {"ID":"1919","CODE":"SON","LABEL":"Cumpas"},
    {"ID":"1920","CODE":"SON","LABEL":"Divisaderos"},
    {"ID":"1921","CODE":"SON","LABEL":"Empalme"},
    {"ID":"1922","CODE":"SON","LABEL":"Etchojoa"},
    {"ID":"1923","CODE":"SON","LABEL":"Fronteras"},
    {"ID":"1924","CODE":"SON","LABEL":"General Plutarco Elias Calles"},
    {"ID":"1925","CODE":"SON","LABEL":"Granados"},
    {"ID":"1926","CODE":"SON","LABEL":"Guaymas"},
    {"ID":"1927","CODE":"SON","LABEL":"Hermosillo"},
    {"ID":"1928","CODE":"SON","LABEL":"Huachinera"},
    {"ID":"1929","CODE":"SON","LABEL":"Huasabas"},
    {"ID":"1930","CODE":"SON","LABEL":"Huatabampo"},
    {"ID":"1931","CODE":"SON","LABEL":"Huepac"},
    {"ID":"1932","CODE":"SON","LABEL":"Imuris"},
    {"ID":"1933","CODE":"SON","LABEL":"La Colorada"},
    {"ID":"1934","CODE":"SON","LABEL":"Magdalena"},
    {"ID":"1935","CODE":"SON","LABEL":"Mazatan"},
    {"ID":"1936","CODE":"SON","LABEL":"Moctezuma"},
    {"ID":"1937","CODE":"SON","LABEL":"Naco"},
    {"ID":"1938","CODE":"SON","LABEL":"Nacori Chico"},
    {"ID":"1939","CODE":"SON","LABEL":"Nacozari de Garcia"},
    {"ID":"1940","CODE":"SON","LABEL":"Navojoa"},
    {"ID":"1941","CODE":"SON","LABEL":"Nogales"},
    {"ID":"1942","CODE":"SON","LABEL":"Onavas"},
    {"ID":"1943","CODE":"SON","LABEL":"Opodepe"},
    {"ID":"1944","CODE":"SON","LABEL":"Oquitoa"},
    {"ID":"1945","CODE":"SON","LABEL":"Pitiquito"},
    {"ID":"1946","CODE":"SON","LABEL":"Puerto Penasco"},
    {"ID":"1947","CODE":"SON","LABEL":"Quiriego"},
    {"ID":"1948","CODE":"SON","LABEL":"Rayon"},
    {"ID":"1949","CODE":"SON","LABEL":"Rosario"},
    {"ID":"1950","CODE":"SON","LABEL":"Sahuaripa"},
    {"ID":"1951","CODE":"SON","LABEL":"San Felipe de Jesus"},
    {"ID":"1952","CODE":"SON","LABEL":"San Ignacio Rio Muerto"},
    {"ID":"1953","CODE":"SON","LABEL":"San Javier"},
    {"ID":"1954","CODE":"SON","LABEL":"San Luis Rio Colorado"},
    {"ID":"1955","CODE":"SON","LABEL":"San Miguel de Horcasitas"},
    {"ID":"1956","CODE":"SON","LABEL":"San Pedro de la Cueva"},
    {"ID":"1957","CODE":"SON","LABEL":"Santa Ana"},
    {"ID":"1958","CODE":"SON","LABEL":"Santa Cruz"},
    {"ID":"1959","CODE":"SON","LABEL":"Saric"},
    {"ID":"1960","CODE":"SON","LABEL":"Soyopa"},
    {"ID":"1961","CODE":"SON","LABEL":"Suaqui Grande"},
    {"ID":"1962","CODE":"SON","LABEL":"Tepache"},
    {"ID":"1963","CODE":"SON","LABEL":"Trincheras"},
    {"ID":"1964","CODE":"SON","LABEL":"Tubutama"},
    {"ID":"1965","CODE":"SON","LABEL":"Ures"},
    {"ID":"1966","CODE":"SON","LABEL":"Villa Hidalgo"},
    {"ID":"1967","CODE":"SON","LABEL":"Villa Pesqueira"},
    {"ID":"1968","CODE":"SON","LABEL":"Yecora"},
    {"ID":"1969","CODE":"TAB","LABEL":"Balancan"},
    {"ID":"1970","CODE":"TAB","LABEL":"Cardenas"},
    {"ID":"1971","CODE":"TAB","LABEL":"Centla"},
    {"ID":"1972","CODE":"TAB","LABEL":"Centro"},
    {"ID":"1973","CODE":"TAB","LABEL":"Comalcalco"},
    {"ID":"1974","CODE":"TAB","LABEL":"Cunduacan"},
    {"ID":"1975","CODE":"TAB","LABEL":"Emiliano Zapata"},
    {"ID":"1976","CODE":"TAB","LABEL":"Huimanguillo"},
    {"ID":"1977","CODE":"TAB","LABEL":"Jalapa"},
    {"ID":"1978","CODE":"TAB","LABEL":"Jalpa de Mendez"},
    {"ID":"1979","CODE":"TAB","LABEL":"Jonuta"},
    {"ID":"1980","CODE":"TAB","LABEL":"Macuspana"},
    {"ID":"1981","CODE":"TAB","LABEL":"Nacajuca"},
    {"ID":"1982","CODE":"TAB","LABEL":"Paraiso"},
    {"ID":"1983","CODE":"TAB","LABEL":"Tacotalpa"},
    {"ID":"1984","CODE":"TAB","LABEL":"Teapa"},
    {"ID":"1985","CODE":"TAB","LABEL":"Tenosique"},
    {"ID":"1986","CODE":"TMS","LABEL":"Abasolo"},
    {"ID":"1987","CODE":"TMS","LABEL":"Aldama"},
    {"ID":"1988","CODE":"TMS","LABEL":"Altamira"},
    {"ID":"1989","CODE":"TMS","LABEL":"Antiguo Morelos"},
    {"ID":"1990","CODE":"TMS","LABEL":"Burgos"},
    {"ID":"1991","CODE":"TMS","LABEL":"Bustamante"},
    {"ID":"1992","CODE":"TMS","LABEL":"Camargo"},
    {"ID":"1993","CODE":"TMS","LABEL":"Casas"},
    {"ID":"1994","CODE":"TMS","LABEL":"Ciudad Madero"},
    {"ID":"1995","CODE":"TMS","LABEL":"Cruillas"},
    {"ID":"1996","CODE":"TMS","LABEL":"El Mante"},
    {"ID":"1997","CODE":"TMS","LABEL":"Gomez Farias"},
    {"ID":"1998","CODE":"TMS","LABEL":"Gonzalez"},
    {"ID":"1999","CODE":"TMS","LABEL":"Guemez"},
    {"ID":"2000","CODE":"TMS","LABEL":"Guerrero"},
    {"ID":"2001","CODE":"TMS","LABEL":"Gustavo Diaz Ordaz"},
    {"ID":"2002","CODE":"TMS","LABEL":"Hidalgo"},
    {"ID":"2003","CODE":"TMS","LABEL":"Jaumave"},
    {"ID":"2004","CODE":"TMS","LABEL":"Jimenez"},
    {"ID":"2005","CODE":"TMS","LABEL":"Llera"},
    {"ID":"2006","CODE":"TMS","LABEL":"Mainero"},
    {"ID":"2007","CODE":"TMS","LABEL":"Matamoros"},
    {"ID":"2008","CODE":"TMS","LABEL":"Mendez"},
    {"ID":"2009","CODE":"TMS","LABEL":"Mier"},
    {"ID":"2010","CODE":"TMS","LABEL":"Miguel Aleman"},
    {"ID":"2011","CODE":"TMS","LABEL":"Miquihuana"},
    {"ID":"2012","CODE":"TMS","LABEL":"Nuevo Laredo"},
    {"ID":"2013","CODE":"TMS","LABEL":"Nuevo Morelos"},
    {"ID":"2014","CODE":"TMS","LABEL":"Ocampo"},
    {"ID":"2015","CODE":"TMS","LABEL":"Padilla"},
    {"ID":"2016","CODE":"TMS","LABEL":"Palmillas"},
    {"ID":"2017","CODE":"TMS","LABEL":"Reynosa"},
    {"ID":"2018","CODE":"TMS","LABEL":"Rio Bravo"},
    {"ID":"2019","CODE":"TMS","LABEL":"San Carlos"},
    {"ID":"2020","CODE":"TMS","LABEL":"San Fernando"},
    {"ID":"2021","CODE":"TMS","LABEL":"San Nicolas"},
    {"ID":"2022","CODE":"TMS","LABEL":"Soto la Marina"},
    {"ID":"2023","CODE":"TMS","LABEL":"Tampico"},
    {"ID":"2024","CODE":"TMS","LABEL":"Tula"},
    {"ID":"2025","CODE":"TMS","LABEL":"Valle Hermoso"},
    {"ID":"2026","CODE":"TMS","LABEL":"Victoria"},
    {"ID":"2027","CODE":"TMS","LABEL":"Villagran"},
    {"ID":"2028","CODE":"TMS","LABEL":"Xicotencatl"},
    {"ID":"2029","CODE":"TLX","LABEL":"Acuamanala de Miguel Hidalgo"},
    {"ID":"2030","CODE":"TLX","LABEL":"Amaxac de Guerrero"},
    {"ID":"2031","CODE":"TLX","LABEL":"Apetatitlan de Antonio Carvajal"},
    {"ID":"2032","CODE":"TLX","LABEL":"Apizaco"},
    {"ID":"2033","CODE":"TLX","LABEL":"Atlangatepec"},
    {"ID":"2034","CODE":"TLX","LABEL":"Atltzayanca"},
    {"ID":"2035","CODE":"TLX","LABEL":"Benito Juarez"},
    {"ID":"2036","CODE":"TLX","LABEL":"Calpulalpan"},
    {"ID":"2037","CODE":"TLX","LABEL":"Chiautempan"},
    {"ID":"2038","CODE":"TLX","LABEL":"Contla de Juan Cuamatzi"},
    {"ID":"2039","CODE":"TLX","LABEL":"Cuapiaxtla"},
    {"ID":"2040","CODE":"TLX","LABEL":"Cuaxomulco"},
    {"ID":"2041","CODE":"TLX","LABEL":"El Carmen Tequexquitla"},
    {"ID":"2042","CODE":"TLX","LABEL":"Emiliano Zapata"},
    {"ID":"2043","CODE":"TLX","LABEL":"Espanita"},
    {"ID":"2044","CODE":"TLX","LABEL":"Huamantla"},
    {"ID":"2045","CODE":"TLX","LABEL":"Hueyotlipan"},
    {"ID":"2046","CODE":"TLX","LABEL":"Ixtacuixtla de Mariano Matamoros"},
    {"ID":"2047","CODE":"TLX","LABEL":"Ixtenco"},
    {"ID":"2048","CODE":"TLX","LABEL":"La Magdalena Tlaltelulco"},
    {"ID":"2049","CODE":"TLX","LABEL":"Lazaro Cardenas"},
    {"ID":"2050","CODE":"TLX","LABEL":"Mazatecochco de Jose Maria Morelos"},
    {"ID":"2051","CODE":"TLX","LABEL":"Munoz de Domingo Arenas"},
    {"ID":"2052","CODE":"TLX","LABEL":"Nanacamilpa de Mariano Arista"},
    {"ID":"2053","CODE":"TLX","LABEL":"Nativitas"},
    {"ID":"2054","CODE":"TLX","LABEL":"Panotla"},
    {"ID":"2055","CODE":"TLX","LABEL":"Papalotla de Xicohtencatl"},
    {"ID":"2056","CODE":"TLX","LABEL":"San Damian Texoloc"},
    {"ID":"2057","CODE":"TLX","LABEL":"San Francisco Tetlanohcan"},
    {"ID":"2058","CODE":"TLX","LABEL":"San Jeronimo Zacualpan"},
    {"ID":"2059","CODE":"TLX","LABEL":"San Jose Teacalco"},
    {"ID":"2060","CODE":"TLX","LABEL":"San Juan Huactzinco"},
    {"ID":"2061","CODE":"TLX","LABEL":"San Lorenzo Axocomanitla"},
    {"ID":"2062","CODE":"TLX","LABEL":"San Lucas Tecopilco"},
    {"ID":"2063","CODE":"TLX","LABEL":"San Pablo del Monte"},
    {"ID":"2064","CODE":"TLX","LABEL":"Sanctorum de Lazaro Cardenas"},
    {"ID":"2065","CODE":"TLX","LABEL":"Santa Ana Nopalucan"},
    {"ID":"2066","CODE":"TLX","LABEL":"Santa Apolonia Teacalco"},
    {"ID":"2067","CODE":"TLX","LABEL":"Santa Catarina Ayometla"},
    {"ID":"2068","CODE":"TLX","LABEL":"Santa Cruz Quilehtla"},
    {"ID":"2069","CODE":"TLX","LABEL":"Santa Cruz Tlaxcala"},
    {"ID":"2070","CODE":"TLX","LABEL":"Santa Isabel Xiloxoxtla"},
    {"ID":"2071","CODE":"TLX","LABEL":"Tenancingo"},
    {"ID":"2072","CODE":"TLX","LABEL":"Teolocholco"},
    {"ID":"2073","CODE":"TLX","LABEL":"Tepetitla de Lardizabal"},
    {"ID":"2074","CODE":"TLX","LABEL":"Tepeyanco"},
    {"ID":"2075","CODE":"TLX","LABEL":"Terrenate"},
    {"ID":"2076","CODE":"TLX","LABEL":"Tetla de la Solidaridad"},
    {"ID":"2077","CODE":"TLX","LABEL":"Tetlatlahuca"},
    {"ID":"2078","CODE":"TLX","LABEL":"Tlaxcala"},
    {"ID":"2079","CODE":"TLX","LABEL":"Tlaxco"},
    {"ID":"2080","CODE":"TLX","LABEL":"Tocatlan"},
    {"ID":"2081","CODE":"TLX","LABEL":"Totolac"},
    {"ID":"2082","CODE":"TLX","LABEL":"Tzompantepec"},
    {"ID":"2083","CODE":"TLX","LABEL":"Xaloztoc"},
    {"ID":"2084","CODE":"TLX","LABEL":"Xaltocan"},
    {"ID":"2085","CODE":"TLX","LABEL":"Xicohtzinco"},
    {"ID":"2086","CODE":"TLX","LABEL":"Yauhquemehcan"},
    {"ID":"2087","CODE":"TLX","LABEL":"Zacatelco"},
    {"ID":"2088","CODE":"TLX","LABEL":"Ziltlaltepec de Trinidad Sanchez Santos"},
    {"ID":"2089","CODE":"VER","LABEL":"Acajete"},
    {"ID":"2090","CODE":"VER","LABEL":"Acatlan"},
    {"ID":"2091","CODE":"VER","LABEL":"Acayucan"},
    {"ID":"2092","CODE":"VER","LABEL":"Actopan"},
    {"ID":"2093","CODE":"VER","LABEL":"Acula"},
    {"ID":"2094","CODE":"VER","LABEL":"Acultzingo"},
    {"ID":"2095","CODE":"VER","LABEL":"Agua Dulce"},
    {"ID":"2096","CODE":"VER","LABEL":"Alamo Temapache"},
    {"ID":"2097","CODE":"VER","LABEL":"Alpatlahuac"},
    {"ID":"2098","CODE":"VER","LABEL":"Alto Lucero de Gutierrez Barrios"},
    {"ID":"2099","CODE":"VER","LABEL":"Altotonga"},
    {"ID":"2100","CODE":"VER","LABEL":"Alvarado"},
    {"ID":"2101","CODE":"VER","LABEL":"Amatitlan"},
    {"ID":"2102","CODE":"VER","LABEL":"Amatlan de los Reyes"},
    {"ID":"2103","CODE":"VER","LABEL":"Angel R. Cabada"},
    {"ID":"2104","CODE":"VER","LABEL":"Apazapan"},
    {"ID":"2105","CODE":"VER","LABEL":"Aquila"},
    {"ID":"2106","CODE":"VER","LABEL":"Astacinga"},
    {"ID":"2107","CODE":"VER","LABEL":"Atlahuilco"},
    {"ID":"2108","CODE":"VER","LABEL":"Atoyac"},
    {"ID":"2109","CODE":"VER","LABEL":"Atzacan"},
    {"ID":"2110","CODE":"VER","LABEL":"Atzalan"},
    {"ID":"2111","CODE":"VER","LABEL":"Ayahualulco"},
    {"ID":"2112","CODE":"VER","LABEL":"Banderilla"},
    {"ID":"2113","CODE":"VER","LABEL":"Benito Juarez"},
    {"ID":"2114","CODE":"VER","LABEL":"Boca del Rio"},
    {"ID":"2115","CODE":"VER","LABEL":"Calcahualco"},
    {"ID":"2116","CODE":"VER","LABEL":"Camaron de Tejeda"},
    {"ID":"2117","CODE":"VER","LABEL":"Camerino Z. Mendoza"},
    {"ID":"2118","CODE":"VER","LABEL":"Carlos A. Carrillo"},
    {"ID":"2119","CODE":"VER","LABEL":"Carrillo Puerto"},
    {"ID":"2120","CODE":"VER","LABEL":"Castillo de Teayo"},
    {"ID":"2121","CODE":"VER","LABEL":"Catemaco"},
    {"ID":"2122","CODE":"VER","LABEL":"Cazones de Herrera"},
    {"ID":"2123","CODE":"VER","LABEL":"Cerro Azul"},
    {"ID":"2124","CODE":"VER","LABEL":"Chacaltianguis"},
    {"ID":"2125","CODE":"VER","LABEL":"Chalma"},
    {"ID":"2126","CODE":"VER","LABEL":"Chiconamel"},
    {"ID":"2127","CODE":"VER","LABEL":"Chiconquiaco"},
    {"ID":"2128","CODE":"VER","LABEL":"Chicontepec"},
    {"ID":"2129","CODE":"VER","LABEL":"Chinameca"},
    {"ID":"2130","CODE":"VER","LABEL":"Chinampa de Gorostiza"},
    {"ID":"2131","CODE":"VER","LABEL":"Chocaman"},
    {"ID":"2132","CODE":"VER","LABEL":"Chontla"},
    {"ID":"2133","CODE":"VER","LABEL":"Chumatlan"},
    {"ID":"2134","CODE":"VER","LABEL":"Citlaltepetl"},
    {"ID":"2135","CODE":"VER","LABEL":"Coacoatzintla"},
    {"ID":"2136","CODE":"VER","LABEL":"Coahuitlan"},
    {"ID":"2137","CODE":"VER","LABEL":"Coatepec"},
    {"ID":"2138","CODE":"VER","LABEL":"Coatzacoalcos"},
    {"ID":"2139","CODE":"VER","LABEL":"Coatzintla"},
    {"ID":"2140","CODE":"VER","LABEL":"Coetzala"},
    {"ID":"2141","CODE":"VER","LABEL":"Colipa"},
    {"ID":"2142","CODE":"VER","LABEL":"Comapa"},
    {"ID":"2143","CODE":"VER","LABEL":"Cordoba"},
    {"ID":"2144","CODE":"VER","LABEL":"Cosamaloapan de Carpio"},
    {"ID":"2145","CODE":"VER","LABEL":"Cosautlan de Carvajal"},
    {"ID":"2146","CODE":"VER","LABEL":"Coscomatepec"},
    {"ID":"2147","CODE":"VER","LABEL":"Cosoleacaque"},
    {"ID":"2148","CODE":"VER","LABEL":"Cotaxtla"},
    {"ID":"2149","CODE":"VER","LABEL":"Coxquihui"},
    {"ID":"2150","CODE":"VER","LABEL":"Coyutla"},
    {"ID":"2151","CODE":"VER","LABEL":"Cuichapa"},
    {"ID":"2152","CODE":"VER","LABEL":"Cuitlahuac"},
    {"ID":"2153","CODE":"VER","LABEL":"El Higo"},
    {"ID":"2154","CODE":"VER","LABEL":"Emiliano Zapata"},
    {"ID":"2155","CODE":"VER","LABEL":"Espinal"},
    {"ID":"2156","CODE":"VER","LABEL":"Filomeno Mata"},
    {"ID":"2157","CODE":"VER","LABEL":"Fortin"},
    {"ID":"2158","CODE":"VER","LABEL":"Gutierrez Zamora"},
    {"ID":"2159","CODE":"VER","LABEL":"Hidalgotitlan"},
    {"ID":"2160","CODE":"VER","LABEL":"Huatusco"},
    {"ID":"2161","CODE":"VER","LABEL":"Huayacocotla"},
    {"ID":"2162","CODE":"VER","LABEL":"Hueyapan de Ocampo"},
    {"ID":"2163","CODE":"VER","LABEL":"Huiloapan de Cuauhtemoc"},
    {"ID":"2164","CODE":"VER","LABEL":"Ignacio de la Llave"},
    {"ID":"2165","CODE":"VER","LABEL":"Ilamatlan"},
    {"ID":"2166","CODE":"VER","LABEL":"Isla"},
    {"ID":"2167","CODE":"VER","LABEL":"Ixcatepec"},
    {"ID":"2168","CODE":"VER","LABEL":"Ixhuacan de los Reyes"},
    {"ID":"2169","CODE":"VER","LABEL":"Ixhuatlan de Madero"},
    {"ID":"2170","CODE":"VER","LABEL":"Ixhuatlan del Cafe"},
    {"ID":"2171","CODE":"VER","LABEL":"Ixhuatlan del Sureste"},
    {"ID":"2172","CODE":"VER","LABEL":"Ixhuatlancillo"},
    {"ID":"2173","CODE":"VER","LABEL":"Ixmatlahuacan"},
    {"ID":"2174","CODE":"VER","LABEL":"Ixtaczoquitlan"},
    {"ID":"2175","CODE":"VER","LABEL":"Jalacingo"},
    {"ID":"2176","CODE":"VER","LABEL":"Jalcomulco"},
    {"ID":"2177","CODE":"VER","LABEL":"Jaltipan"},
    {"ID":"2178","CODE":"VER","LABEL":"Jamapa"},
    {"ID":"2179","CODE":"VER","LABEL":"Jesus Carranza"},
    {"ID":"2180","CODE":"VER","LABEL":"Jilotepec"},
    {"ID":"2181","CODE":"VER","LABEL":"Jose Azueta"},
    {"ID":"2182","CODE":"VER","LABEL":"Juan Rodriguez Clara"},
    {"ID":"2183","CODE":"VER","LABEL":"Juchique de Ferrer"},
    {"ID":"2184","CODE":"VER","LABEL":"La Antigua"},
    {"ID":"2185","CODE":"VER","LABEL":"La Perla"},
    {"ID":"2186","CODE":"VER","LABEL":"Landero y Coss"},
    {"ID":"2187","CODE":"VER","LABEL":"Las Choapas"},
    {"ID":"2188","CODE":"VER","LABEL":"Las Minas"},
    {"ID":"2189","CODE":"VER","LABEL":"Las Vigas de Ramirez"},
    {"ID":"2190","CODE":"VER","LABEL":"Lerdo de Tejada"},
    {"ID":"2191","CODE":"VER","LABEL":"Los Reyes"},
    {"ID":"2192","CODE":"VER","LABEL":"Magdalena"},
    {"ID":"2193","CODE":"VER","LABEL":"Maltrata"},
    {"ID":"2194","CODE":"VER","LABEL":"Manlio Fabio Altamirano"},
    {"ID":"2195","CODE":"VER","LABEL":"Mariano Escobedo"},
    {"ID":"2196","CODE":"VER","LABEL":"Martinez de la Torre"},
    {"ID":"2197","CODE":"VER","LABEL":"Mecatlan"},
    {"ID":"2198","CODE":"VER","LABEL":"Mecayapan"},
    {"ID":"2199","CODE":"VER","LABEL":"Medellin de Bravo"},
    {"ID":"2200","CODE":"VER","LABEL":"Miahuatlan"},
    {"ID":"2201","CODE":"VER","LABEL":"Minatitlan"},
    {"ID":"2202","CODE":"VER","LABEL":"Misantla"},
    {"ID":"2203","CODE":"VER","LABEL":"Mixtla de Altamirano"},
    {"ID":"2204","CODE":"VER","LABEL":"Moloacan"},
    {"ID":"2205","CODE":"VER","LABEL":"Nanchital de Lazaro Cardenas del Rio"},
    {"ID":"2206","CODE":"VER","LABEL":"Naolinco"},
    {"ID":"2207","CODE":"VER","LABEL":"Naranjal"},
    {"ID":"2208","CODE":"VER","LABEL":"Naranjos Amatlan"},
    {"ID":"2209","CODE":"VER","LABEL":"Nautla"},
    {"ID":"2210","CODE":"VER","LABEL":"Nogales"},
    {"ID":"2211","CODE":"VER","LABEL":"Oluta"},
    {"ID":"2212","CODE":"VER","LABEL":"Omealca"},
    {"ID":"2213","CODE":"VER","LABEL":"Orizaba"},
    {"ID":"2214","CODE":"VER","LABEL":"Otatitlan"},
    {"ID":"2215","CODE":"VER","LABEL":"Oteapan"},
    {"ID":"2216","CODE":"VER","LABEL":"Ozuluama de Mascarenas"},
    {"ID":"2217","CODE":"VER","LABEL":"Pajapan"},
    {"ID":"2218","CODE":"VER","LABEL":"Panuco"},
    {"ID":"2219","CODE":"VER","LABEL":"Papantla"},
    {"ID":"2220","CODE":"VER","LABEL":"Paso de Ovejas"},
    {"ID":"2221","CODE":"VER","LABEL":"Paso del Macho"},
    {"ID":"2222","CODE":"VER","LABEL":"Perote"},
    {"ID":"2223","CODE":"VER","LABEL":"Platon Sanchez"},
    {"ID":"2224","CODE":"VER","LABEL":"Playa Vicente"},
    {"ID":"2225","CODE":"VER","LABEL":"Poza Rica de Hidalgo"},
    {"ID":"2226","CODE":"VER","LABEL":"Pueblo Viejo"},
    {"ID":"2227","CODE":"VER","LABEL":"Puente Nacional"},
    {"ID":"2228","CODE":"VER","LABEL":"Rafael Delgado"},
    {"ID":"2229","CODE":"VER","LABEL":"Rafael Lucio"},
    {"ID":"2230","CODE":"VER","LABEL":"Rio Blanco"},
    {"ID":"2231","CODE":"VER","LABEL":"Saltabarranca"},
    {"ID":"2232","CODE":"VER","LABEL":"San Andres Tenejapan"},
    {"ID":"2233","CODE":"VER","LABEL":"San Andres Tuxtla"},
    {"ID":"2234","CODE":"VER","LABEL":"San Juan Evangelista"},
    {"ID":"2235","CODE":"VER","LABEL":"San Rafael"},
    {"ID":"2236","CODE":"VER","LABEL":"Santiago Sochiapan"},
    {"ID":"2237","CODE":"VER","LABEL":"Santiago Tuxtla"},
    {"ID":"2238","CODE":"VER","LABEL":"Sayula de Aleman"},
    {"ID":"2239","CODE":"VER","LABEL":"Sochiapa"},
    {"ID":"2240","CODE":"VER","LABEL":"Soconusco"},
    {"ID":"2241","CODE":"VER","LABEL":"Soledad Atzompa"},
    {"ID":"2242","CODE":"VER","LABEL":"Soledad de Doblado"},
    {"ID":"2243","CODE":"VER","LABEL":"Soteapan"},
    {"ID":"2244","CODE":"VER","LABEL":"Tamalin"},
    {"ID":"2245","CODE":"VER","LABEL":"Tamiahua"},
    {"ID":"2246","CODE":"VER","LABEL":"Tampico Alto"},
    {"ID":"2247","CODE":"VER","LABEL":"Tancoco"},
    {"ID":"2248","CODE":"VER","LABEL":"Tantima"},
    {"ID":"2249","CODE":"VER","LABEL":"Tantoyuca"},
    {"ID":"2250","CODE":"VER","LABEL":"Tatahuicapan de Juarez"},
    {"ID":"2251","CODE":"VER","LABEL":"Tatatila"},
    {"ID":"2252","CODE":"VER","LABEL":"Tecolutla"},
    {"ID":"2253","CODE":"VER","LABEL":"Tehuipango"},
    {"ID":"2254","CODE":"VER","LABEL":"Tempoal"},
    {"ID":"2255","CODE":"VER","LABEL":"Tenampa"},
    {"ID":"2256","CODE":"VER","LABEL":"Tenochtitlan"},
    {"ID":"2257","CODE":"VER","LABEL":"Teocelo"},
    {"ID":"2258","CODE":"VER","LABEL":"Tepatlaxco"},
    {"ID":"2259","CODE":"VER","LABEL":"Tepetlan"},
    {"ID":"2260","CODE":"VER","LABEL":"Tepetzintla"},
    {"ID":"2261","CODE":"VER","LABEL":"Tequila"},
    {"ID":"2262","CODE":"VER","LABEL":"Texcatepec"},
    {"ID":"2263","CODE":"VER","LABEL":"Texhuacan"},
    {"ID":"2264","CODE":"VER","LABEL":"Texistepec"},
    {"ID":"2265","CODE":"VER","LABEL":"Tezonapa"},
    {"ID":"2266","CODE":"VER","LABEL":"Tierra Blanca"},
    {"ID":"2267","CODE":"VER","LABEL":"Tihuatlan"},
    {"ID":"2268","CODE":"VER","LABEL":"Tlachichilco"},
    {"ID":"2269","CODE":"VER","LABEL":"Tlacojalpan"},
    {"ID":"2270","CODE":"VER","LABEL":"Tlacolulan"},
    {"ID":"2271","CODE":"VER","LABEL":"Tlacotalpan"},
    {"ID":"2272","CODE":"VER","LABEL":"Tlacotepec de Mejia"},
    {"ID":"2273","CODE":"VER","LABEL":"Tlalixcoyan"},
    {"ID":"2274","CODE":"VER","LABEL":"Tlalnelhuayocan"},
    {"ID":"2275","CODE":"VER","LABEL":"Tlaltetela"},
    {"ID":"2276","CODE":"VER","LABEL":"Tlapacoyan"},
    {"ID":"2277","CODE":"VER","LABEL":"Tlaquilpa"},
    {"ID":"2278","CODE":"VER","LABEL":"Tlilapan"},
    {"ID":"2279","CODE":"VER","LABEL":"Tomatlan"},
    {"ID":"2280","CODE":"VER","LABEL":"Tonayan"},
    {"ID":"2281","CODE":"VER","LABEL":"Totutla"},
    {"ID":"2282","CODE":"VER","LABEL":"Tres Valles"},
    {"ID":"2283","CODE":"VER","LABEL":"Tuxpan"},
    {"ID":"2284","CODE":"VER","LABEL":"Tuxtilla"},
    {"ID":"2285","CODE":"VER","LABEL":"Ursulo Galvan"},
    {"ID":"2286","CODE":"VER","LABEL":"Uxpanapa"},
    {"ID":"2287","CODE":"VER","LABEL":"Vega de Alatorre"},
    {"ID":"2288","CODE":"VER","LABEL":"Veracruz"},
    {"ID":"2289","CODE":"VER","LABEL":"Villa Aldama"},
    {"ID":"2290","CODE":"VER","LABEL":"Xalapa"},
    {"ID":"2291","CODE":"VER","LABEL":"Xico"},
    {"ID":"2292","CODE":"VER","LABEL":"Xoxocotla"},
    {"ID":"2293","CODE":"VER","LABEL":"Yanga"},
    {"ID":"2294","CODE":"VER","LABEL":"Yecuatla"},
    {"ID":"2295","CODE":"VER","LABEL":"Zacualpan"},
    {"ID":"2296","CODE":"VER","LABEL":"Zaragoza"},
    {"ID":"2297","CODE":"VER","LABEL":"Zentla"},
    {"ID":"2298","CODE":"VER","LABEL":"Zongolica"},
    {"ID":"2299","CODE":"VER","LABEL":"Zontecomatlan de Lopez y Fuentes"},
    {"ID":"2300","CODE":"VER","LABEL":"Zozocolco de Hidalgo"},
    {"ID":"2301","CODE":"YUC","LABEL":"Abala"},
    {"ID":"2302","CODE":"YUC","LABEL":"Acanceh"},
    {"ID":"2303","CODE":"YUC","LABEL":"Akil"},
    {"ID":"2304","CODE":"YUC","LABEL":"Baca"},
    {"ID":"2305","CODE":"YUC","LABEL":"Bokoba"},
    {"ID":"2306","CODE":"YUC","LABEL":"Buctzotz"},
    {"ID":"2307","CODE":"YUC","LABEL":"Cacalchen"},
    {"ID":"2308","CODE":"YUC","LABEL":"Calotmul"},
    {"ID":"2309","CODE":"YUC","LABEL":"Cansahcab"},
    {"ID":"2310","CODE":"YUC","LABEL":"Cantamayec"},
    {"ID":"2311","CODE":"YUC","LABEL":"Celestun"},
    {"ID":"2312","CODE":"YUC","LABEL":"Cenotillo"},
    {"ID":"2313","CODE":"YUC","LABEL":"Chacsinkin"},
    {"ID":"2314","CODE":"YUC","LABEL":"Chankom"},
    {"ID":"2315","CODE":"YUC","LABEL":"Chapab"},
    {"ID":"2316","CODE":"YUC","LABEL":"Chemax"},
    {"ID":"2317","CODE":"YUC","LABEL":"Chichimila"},
    {"ID":"2318","CODE":"YUC","LABEL":"Chicxulub Pueblo"},
    {"ID":"2319","CODE":"YUC","LABEL":"Chikindzonot"},
    {"ID":"2320","CODE":"YUC","LABEL":"Chochola"},
    {"ID":"2321","CODE":"YUC","LABEL":"Chumayel"},
    {"ID":"2322","CODE":"YUC","LABEL":"Conkal"},
    {"ID":"2323","CODE":"YUC","LABEL":"Cuncunul"},
    {"ID":"2324","CODE":"YUC","LABEL":"Cuzama"},
    {"ID":"2325","CODE":"YUC","LABEL":"Dzan"},
    {"ID":"2326","CODE":"YUC","LABEL":"Dzemul"},
    {"ID":"2327","CODE":"YUC","LABEL":"Dzidzantun"},
    {"ID":"2328","CODE":"YUC","LABEL":"Dzilam Gonzalez"},
    {"ID":"2329","CODE":"YUC","LABEL":"Dzilam de Bravo"},
    {"ID":"2330","CODE":"YUC","LABEL":"Dzitas"},
    {"ID":"2331","CODE":"YUC","LABEL":"Dzoncauich"},
    {"ID":"2332","CODE":"YUC","LABEL":"Espita"},
    {"ID":"2333","CODE":"YUC","LABEL":"Halacho"},
    {"ID":"2334","CODE":"YUC","LABEL":"Hocaba"},
    {"ID":"2335","CODE":"YUC","LABEL":"Hoctun"},
    {"ID":"2336","CODE":"YUC","LABEL":"Homun"},
    {"ID":"2337","CODE":"YUC","LABEL":"Huhi"},
    {"ID":"2338","CODE":"YUC","LABEL":"Hunucma"},
    {"ID":"2339","CODE":"YUC","LABEL":"Ixil"},
    {"ID":"2340","CODE":"YUC","LABEL":"Izamal"},
    {"ID":"2341","CODE":"YUC","LABEL":"Kanasin"},
    {"ID":"2342","CODE":"YUC","LABEL":"Kantunil"},
    {"ID":"2343","CODE":"YUC","LABEL":"Kaua"},
    {"ID":"2344","CODE":"YUC","LABEL":"Kinchil"},
    {"ID":"2345","CODE":"YUC","LABEL":"Kopoma"},
    {"ID":"2346","CODE":"YUC","LABEL":"Mama"},
    {"ID":"2347","CODE":"YUC","LABEL":"Mani"},
    {"ID":"2348","CODE":"YUC","LABEL":"Maxcanu"},
    {"ID":"2349","CODE":"YUC","LABEL":"Mayapan"},
    {"ID":"2350","CODE":"YUC","LABEL":"Merida"},
    {"ID":"2351","CODE":"YUC","LABEL":"Mococha"},
    {"ID":"2352","CODE":"YUC","LABEL":"Motul"},
    {"ID":"2353","CODE":"YUC","LABEL":"Muna"},
    {"ID":"2354","CODE":"YUC","LABEL":"Muxupip"},
    {"ID":"2355","CODE":"YUC","LABEL":"Opichen"},
    {"ID":"2356","CODE":"YUC","LABEL":"Oxkutzcab"},
    {"ID":"2357","CODE":"YUC","LABEL":"Panaba"},
    {"ID":"2358","CODE":"YUC","LABEL":"Peto"},
    {"ID":"2359","CODE":"YUC","LABEL":"Progreso"},
    {"ID":"2360","CODE":"YUC","LABEL":"Quintana Roo"},
    {"ID":"2361","CODE":"YUC","LABEL":"Rio Lagartos"},
    {"ID":"2362","CODE":"YUC","LABEL":"Sacalum"},
    {"ID":"2363","CODE":"YUC","LABEL":"Samahil"},
    {"ID":"2364","CODE":"YUC","LABEL":"San Felipe"},
    {"ID":"2365","CODE":"YUC","LABEL":"Sanahcat"},
    {"ID":"2366","CODE":"YUC","LABEL":"Santa Elena"},
    {"ID":"2367","CODE":"YUC","LABEL":"Seye"},
    {"ID":"2368","CODE":"YUC","LABEL":"Sinanche"},
    {"ID":"2369","CODE":"YUC","LABEL":"Sotuta"},
    {"ID":"2370","CODE":"YUC","LABEL":"Sucila"},
    {"ID":"2371","CODE":"YUC","LABEL":"Sudzal"},
    {"ID":"2372","CODE":"YUC","LABEL":"Suma"},
    {"ID":"2373","CODE":"YUC","LABEL":"Tahdziu"},
    {"ID":"2374","CODE":"YUC","LABEL":"Tahmek"},
    {"ID":"2375","CODE":"YUC","LABEL":"Teabo"},
    {"ID":"2376","CODE":"YUC","LABEL":"Tecoh"},
    {"ID":"2377","CODE":"YUC","LABEL":"Tekal de Venegas"},
    {"ID":"2378","CODE":"YUC","LABEL":"Tekanto"},
    {"ID":"2379","CODE":"YUC","LABEL":"Tekax"},
    {"ID":"2380","CODE":"YUC","LABEL":"Tekit"},
    {"ID":"2381","CODE":"YUC","LABEL":"Tekom"},
    {"ID":"2382","CODE":"YUC","LABEL":"Telchac Pueblo"},
    {"ID":"2383","CODE":"YUC","LABEL":"Telchac Puerto"},
    {"ID":"2384","CODE":"YUC","LABEL":"Temax"},
    {"ID":"2385","CODE":"YUC","LABEL":"Temozon"},
    {"ID":"2386","CODE":"YUC","LABEL":"Tepakan"},
    {"ID":"2387","CODE":"YUC","LABEL":"Tetiz"},
    {"ID":"2388","CODE":"YUC","LABEL":"Teya"},
    {"ID":"2389","CODE":"YUC","LABEL":"Ticul"},
    {"ID":"2390","CODE":"YUC","LABEL":"Timucuy"},
    {"ID":"2391","CODE":"YUC","LABEL":"Tinum"},
    {"ID":"2392","CODE":"YUC","LABEL":"Tixcacalcupul"},
    {"ID":"2393","CODE":"YUC","LABEL":"Tixkokob"},
    {"ID":"2394","CODE":"YUC","LABEL":"Tixmehuac"},
    {"ID":"2395","CODE":"YUC","LABEL":"Tixpehual"},
    {"ID":"2396","CODE":"YUC","LABEL":"Tizimin"},
    {"ID":"2397","CODE":"YUC","LABEL":"Tunkas"},
    {"ID":"2398","CODE":"YUC","LABEL":"Tzucacab"},
    {"ID":"2399","CODE":"YUC","LABEL":"Uayma"},
    {"ID":"2400","CODE":"YUC","LABEL":"Ucu"},
    {"ID":"2401","CODE":"YUC","LABEL":"Uman"},
    {"ID":"2402","CODE":"YUC","LABEL":"Valladolid"},
    {"ID":"2403","CODE":"YUC","LABEL":"Xocchel"},
    {"ID":"2404","CODE":"YUC","LABEL":"Yaxcaba"},
    {"ID":"2405","CODE":"YUC","LABEL":"Yaxkukul"},
    {"ID":"2406","CODE":"YUC","LABEL":"Yobain"},
    {"ID":"2407","CODE":"ZAC","LABEL":"Apozol"},
    {"ID":"2408","CODE":"ZAC","LABEL":"Apulco"},
    {"ID":"2409","CODE":"ZAC","LABEL":"Atolinga"},
    {"ID":"2410","CODE":"ZAC","LABEL":"Benito Juarez"},
    {"ID":"2411","CODE":"ZAC","LABEL":"Calera"},
    {"ID":"2412","CODE":"ZAC","LABEL":"Canitas de Felipe Pescador"},
    {"ID":"2413","CODE":"ZAC","LABEL":"Chalchihuites"},
    {"ID":"2414","CODE":"ZAC","LABEL":"Concepcion del Oro"},
    {"ID":"2415","CODE":"ZAC","LABEL":"Cuauhtemoc"},
    {"ID":"2416","CODE":"ZAC","LABEL":"El Plateado de Joaquin Amaro"},
    {"ID":"2417","CODE":"ZAC","LABEL":"El Salvador"},
    {"ID":"2418","CODE":"ZAC","LABEL":"Fresnillo"},
    {"ID":"2419","CODE":"ZAC","LABEL":"Genaro Codina"},
    {"ID":"2420","CODE":"ZAC","LABEL":"General Enrique Estrada"},
    {"ID":"2421","CODE":"ZAC","LABEL":"General Francisco R. Murguia"},
    {"ID":"2422","CODE":"ZAC","LABEL":"General Panfilo Natera"},
    {"ID":"2423","CODE":"ZAC","LABEL":"Guadalupe"},
    {"ID":"2424","CODE":"ZAC","LABEL":"Huanusco"},
    {"ID":"2425","CODE":"ZAC","LABEL":"Jalpa"},
    {"ID":"2426","CODE":"ZAC","LABEL":"Jerez"},
    {"ID":"2427","CODE":"ZAC","LABEL":"Jimenez del Teul"},
    {"ID":"2428","CODE":"ZAC","LABEL":"Juan Aldama"},
    {"ID":"2429","CODE":"ZAC","LABEL":"Juchipila"},
    {"ID":"2430","CODE":"ZAC","LABEL":"Loreto"},
    {"ID":"2431","CODE":"ZAC","LABEL":"Luis Moya"},
    {"ID":"2432","CODE":"ZAC","LABEL":"Mazapil"},
    {"ID":"2433","CODE":"ZAC","LABEL":"Melchor Ocampo"},
    {"ID":"2434","CODE":"ZAC","LABEL":"Mezquital del Oro"},
    {"ID":"2435","CODE":"ZAC","LABEL":"Miguel Auza"},
    {"ID":"2436","CODE":"ZAC","LABEL":"Momax"},
    {"ID":"2437","CODE":"ZAC","LABEL":"Monte Escobedo"},
    {"ID":"2438","CODE":"ZAC","LABEL":"Morelos"},
    {"ID":"2439","CODE":"ZAC","LABEL":"Moyahua de Estrada"},
    {"ID":"2440","CODE":"ZAC","LABEL":"Nochistlan de Mejia"},
    {"ID":"2441","CODE":"ZAC","LABEL":"Noria de Angeles"},
    {"ID":"2442","CODE":"ZAC","LABEL":"Ojocaliente"},
    {"ID":"2443","CODE":"ZAC","LABEL":"Panuco"},
    {"ID":"2444","CODE":"ZAC","LABEL":"Pinos"},
    {"ID":"2445","CODE":"ZAC","LABEL":"Rio Grande"},
    {"ID":"2446","CODE":"ZAC","LABEL":"Sain Alto"},
    {"ID":"2447","CODE":"ZAC","LABEL":"Santa Maria de la Paz"},
    {"ID":"2448","CODE":"ZAC","LABEL":"Sombrerete"},
    {"ID":"2449","CODE":"ZAC","LABEL":"Susticacan"},
    {"ID":"2450","CODE":"ZAC","LABEL":"Tabasco"},
    {"ID":"2451","CODE":"ZAC","LABEL":"Tepechitlan"},
    {"ID":"2452","CODE":"ZAC","LABEL":"Tepetongo"},
    {"ID":"2453","CODE":"ZAC","LABEL":"Teul de Gonzalez Ortega"},
    {"ID":"2454","CODE":"ZAC","LABEL":"Tlaltenango de Sanchez Roman"},
    {"ID":"2455","CODE":"ZAC","LABEL":"Trancoso"},
    {"ID":"2456","CODE":"ZAC","LABEL":"Trinidad Garcia de la Cadena"},
    {"ID":"2457","CODE":"ZAC","LABEL":"Valparaiso"},
    {"ID":"2458","CODE":"ZAC","LABEL":"Vetagrande"},
    {"ID":"2459","CODE":"ZAC","LABEL":"Villa Garcia"},
    {"ID":"2460","CODE":"ZAC","LABEL":"Villa Gonzalez Ortega"},
    {"ID":"2461","CODE":"ZAC","LABEL":"Villa Hidalgo"},
    {"ID":"2462","CODE":"ZAC","LABEL":"Villa de Cos"},
    {"ID":"2463","CODE":"ZAC","LABEL":"Villanueva"},
    {"ID":"2464","CODE":"ZAC","LABEL":"Zacatecas"}
  ];
  public result: any = [];

  get estado() { return this.form.get('estado') }
  set estado(value: any) { this.form.get('estado')?.setValue(value) }
  get municipio() { return this.form.get('municipio') }
  set municipio(value: any) { this.form.get('municipio')?.setValue(value) }
  get documento() { return this.form.get('documento') }
  set documento(value: any) { this.form.get('documento')?.setValue(value) }

  async ngOnInit() {
    this.init()
  }

  init = async() => {
    this.isLoading = true;
    await this.cambiarEmisionService.list()
    .then((response: any) => this.list = response)
      .catch(() => this.toast.error('Error al obtener la búsqueda.'))
    this.isLoading = false;
    console.log(this.list)
    this.estado = 'AUTO';
    this.municipio = '0';
  }

  onFileChanged = async (event: any) =>
  {
    if (event.target.files && event.target.files.length)
    {
      this.documento = event.target.files[0];
      await this.request();
    }
  }

  public setMunicipios(event: Event)
  {
    let estado = String(event);
    let municipios: any = [];

    for (let value of this.baseDeMunicipios) {
      if (value.CODE == estado) {
        municipios.push(value);
      }
    }

    this.municipios = municipios;
    this.municipio = municipios[0].ID;
  }

  request = async () =>
  {
    this.isLoading = true;
    const formData = new FormData();
    let estado = '';
    let municipio = '';

    if (this.estado.value == 'AUTO') {
      estado = 'AUTO';
      municipio = 'AUTO';
    } else {
      for (let value of this.estados) {
        if (value.CODE == this.estado.value) {
          estado = value.LABEL;
        }
      }
      for (let value of this.municipios) {
        if (value.ID == this.municipio.value) {
          municipio = value.LABEL;
        }
      }
    }

    formData.append('estado', estado);
    formData.append('municipio', municipio);
    formData.append('documento', this.documento.value);

    await this.cambiarEmisionService.request(formData)
      .then((response: any) => {
        this.result = response.content;
      })
      .catch(() => this.toast.error('Error al obtener el resultado.'));

    await this.download();

    this.isLoading = false;
  }

  download = async () =>
  {
    if (this.result.pdf)
    {
      let response = await fetch(this.result.pdf.url);
      console.log(this.result.pdf)
      let data = await response.blob();
      let metadata = { type: 'application/pdf' };
      let file = new File([data], this.result.pdf.name, metadata);
      let url = window.URL.createObjectURL(data);
      let a = document.createElement('a');

      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');

      a.href = url;
      a.download = this.result.pdf.name;
      
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      window.location.reload();
    }
  }
}
