import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageModule } from './login/login.module';
import { LockScreenPageModule } from './lock-screen/lock-screen.module';
import { HomePageModule } from './home/home.module';
import { UserPageModule } from './user/user.module';
import { RfcPageModule } from './rfc/rfc.module';
import { HistorialPageModule } from './historial/historial.module';
import { BusquedaMasivaPageModule } from './busqueda-masiva/busqueda-masiva.module';
import { CambiarEmisionPageModule } from './cambiar-emision/cambiar-emision.module';
import { ReversosPageModule } from './reversos/reversos.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LoginPageModule,
    LockScreenPageModule,
    HomePageModule,
    UserPageModule,
    RfcPageModule,
    HistorialPageModule,
    BusquedaMasivaPageModule,
    CambiarEmisionPageModule,
    ReversosPageModule
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
