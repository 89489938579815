import { Component } from '@angular/core';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';

@Component({
  selector: 'page-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginPage {
  constructor(
    private mainMenuService: MainMenuService
  ) {
    this.mainMenuService.closeMainMenu()
  }
}
