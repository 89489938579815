import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockScreenPageRoutingModule } from './lock-screen-routing.module';
import { LockScreenPage } from './lock-screen.component';
import { LockScreenComponent } from '@components/lock-screen/lock-screen.component';
import { FormLockScreenComponent } from '@components/forms/lock-screen/lock-screen.component';

@NgModule({
  declarations: [
    LockScreenPage
  ],
  imports: [
    CommonModule,
    LockScreenPageRoutingModule,
    LockScreenComponent,
    FormLockScreenComponent
  ]
})
export class LockScreenPageModule { }
