import { Component } from '@angular/core';
import { MainMenuService } from '@components/sidebars/main-menu/main-menu.service';
import { SessionService } from '@utils/session.service';

@Component({
  selector: 'page-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrl: './lock-screen.component.scss'
})
export class LockScreenPage {
  constructor(
    private session: SessionService,
    private mainMenuService: MainMenuService
  ) {
    this.mainMenuService.closeMainMenu()
  }

  public user = this.session.getUser()
}
