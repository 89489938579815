<table class="table table-bordered mb-0">
  <thead>
    <tr>
      <th class="placeholder-glow" scope="col">
        <span class="placeholder col-6"></span>
      </th>
      <th class="placeholder-glow" scope="col">
        <span class="placeholder col-6"></span>
      </th>
      <th class="placeholder-glow" scope="col">
        <span class="placeholder col-6"></span>
      </th>
      <th class="placeholder-glow" scope="col">
        <span class="placeholder col-6"></span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-75">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-75">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-50">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-50">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-25">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
    <tr class="opacity-25">
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
      <td class="placeholder-glow py-3">
        <span class="placeholder placeholder-lg col-12"></span>
      </td>
    </tr>
  </tbody>
</table>